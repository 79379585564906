import { PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import type { CallEffect, PutEffect } from 'redux-saga/effects';

import api from 'src/services/graphicsApi';

import { NOTIFY } from '../reducers/notification';
import {
  actions,
  REQUEST_CREATE_RUNDOWN_TEMPLATE,
  REQUEST_DELETE_RUNDOWN_TEMPLATE,
  REQUEST_UPDATE_RUNDOWN_TEMPLATE,
  REQUEST_GET_RUNDOWN_TEMPLATES,
  REQUEST_GET_RUNDOWN_TEMPLATE,
  RundownTemplateOverviewResponseType,
  RundownTemplateDetailResponseType,
} from '../reducers/rundownTemplate';

function* createRundownTemplate(
  action: PayloadAction<{ data: Partial<Rundown>; navigate: NavigateFunction }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { data, navigate } = action.payload;
    yield delay(500);
    yield call(api.post, `/rundowntemplates`, data);
    yield put(actions.receiveCreateRundownTemplate());
    navigate(`/settings/templates`);

    yield put({
      type: NOTIFY,
      payload: {
        variant: 'success',
        title: 'Your rundown template has been created',
      },
    });
  } catch (error) {
    yield put(
      actions.failedCreateRundownTemplate('Something went wrong. Could not create rundown')
    );
  }
}

function* deleteRundownTemplate(
  action: PayloadAction<{ id: string }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { id } = action.payload;
    yield delay(500);

    const response = yield call(api.delete, `/rundowntemplates/${id}`);
    const apiResponse = response as RundownTemplateApiResponse;
    if (apiResponse?.data) {
      yield put(actions.receiveDeleteRundownTemplate(id));

      yield put({
        type: NOTIFY,
        payload: {
          variant: 'info',
          title: 'Your rundown template has been deleted',
        },
      });
    }
  } catch (error) {
    yield put(actions.failedDeleteRundownTemplate());
  }
}

function* updateRundownTemplate(
  action: PayloadAction<{ data: RundownTemplate }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { id, ...data } = action.payload.data;
    yield delay(500);
    yield call(api.patch, `/rundowntemplates/${id}`, data);
    yield put(actions.receiveUpdateRundownTemplate());
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'success',
        title: 'Your rundown template has been updated',
      },
    });
  } catch (error) {
    yield put(
      actions.failedUpdateRundownTemplate('Something went wrong. Could not create rundown')
    );
  }
}

type RundownTemplatesApiResponse = void | {
  data: RundownTemplateOverviewResponseType;
  status: number;
};
function* getRundownTemplates(): Generator<
  PutEffect | CallEffect<RundownTemplatesApiResponse>,
  void,
  RundownTemplatesApiResponse
> {
  try {
    const response = yield call(api.get, `/rundowntemplates`);
    const apiResponse = response as RundownTemplatesApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveGetRundownTemplates(apiResponse.data));
    }
  } catch (error) {
    yield put(
      actions.failedGetRundownTemplates((error as Error)?.message as string) ||
        'Something went wrong'
    );
  }
}

type RundownTemplateApiResponse = void | {
  data: RundownTemplateDetailResponseType;
  status: number;
};
function* getRundownTemplate(
  action: PayloadAction<{ id?: string }>
): Generator<PutEffect | CallEffect<RundownTemplateApiResponse>, void, RundownTemplateApiResponse> {
  try {
    const { id } = action.payload;
    if (!id) {
      yield put(actions.receiveGetRundownTemplate(null));
      return;
    }
    const response = yield call(api.get, `/rundowntemplates/${id}`);
    const apiResponse = response as RundownTemplateApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveGetRundownTemplate(apiResponse.data));
    }
  } catch (error) {
    yield put(
      actions.failedGetRundownTemplate((error as Error)?.message as string) ||
        'Something went wrong'
    );
  }
}

export default function* root() {
  yield takeLatest(REQUEST_CREATE_RUNDOWN_TEMPLATE, createRundownTemplate);
  yield takeLatest(REQUEST_DELETE_RUNDOWN_TEMPLATE, deleteRundownTemplate);
  yield takeLatest(REQUEST_UPDATE_RUNDOWN_TEMPLATE, updateRundownTemplate);
  yield takeLatest(REQUEST_GET_RUNDOWN_TEMPLATE, getRundownTemplate);
  yield takeLatest(REQUEST_GET_RUNDOWN_TEMPLATES, getRundownTemplates);
}
