import { Button, Input, Text } from '@southfields-digital/mpxlive-components';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import {
  ShootOutAttempt as ShootOutAttemptType,
  ShootOut as ShootOutType,
} from 'src/types/matchControl/shootOut';

import MatchControlButton from './MatchControlButton';
import MatchControlIcon from './MatchControlIcon';

const VerticalDivider = () => <div className="h-full border-r border-elevated-2" />;

const ShootOutAttempt = ({
  attempt,
  handleChangeAttempt,
}: {
  attempt: ShootOutAttemptType;
  handleChangeAttempt: (attempt: ShootOutAttemptType) => void;
}) => {
  const [isEntering, setIsEntering] = useState(false);

  useEffect(() => {
    setIsEntering(true);
  }, []);

  const handleClickAttempt = () => {
    const newAttempt =
      attempt === 'attempt' ? 'scored' : attempt === 'missed' ? 'attempt' : 'missed';
    handleChangeAttempt(newAttempt);
  };

  return (
    <button
      onClick={handleClickAttempt}
      className={classNames({
        'opacity-0 transition-opacity duration-500': true,
        'opacity-100': isEntering,
      })}
    >
      <div
        className={classNames({
          'rounded-xl w-6 h-6': true,
          'bg-danger': attempt === 'missed',
          'bg-success': attempt === 'scored',
          'bg-elevated-2': attempt === 'attempt',
        })}
      />
    </button>
  );
};

const ShootOutContestant = ({
  shortName,
  attemptsContestant,
  handleChangeAttempt,
}: {
  shortName: string;
  attemptsContestant: ShootOutType['home'] | ShootOutType['away'];
  handleChangeAttempt: (index: number, attempt: ShootOutAttemptType) => void;
}) => {
  const [show, setShow] = useState(false);
  return (
    <div className="flex items-center flex-1 p-2">
      <div className="flex items-center justify-center min-w-[50px]">
        <Text className={classNames({ '!text-xl': true })}>{shortName}</Text>
      </div>
      <div className="flex gap-2 justify-evenly flex-1">
        {attemptsContestant.map((attempt, index) => (
          <React.Fragment key={index}>
            {attemptsContestant.length - 5 > index ? null : (
              <ShootOutAttempt
                attempt={attempt}
                handleChangeAttempt={(attempt) => {
                  handleChangeAttempt(index, attempt);
                  setShow(!show);
                }}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

type ShootOutProps = {
  isLive: boolean;
  hasShootOut: boolean;
  shootOutTaker: ShootOutType['taker'];
  handleClickLive: () => void;
  handleToggleShootOut: () => void;
  handleUpdateShootOut: (shootOut: ShootOutType) => void;
  handleClickUpdate: () => void;
  home: {
    shortName: string;
    attempts: ShootOutType['home'];
  };
  away: {
    shortName: string;
    attempts: ShootOutType['away'];
  };
};
const ShootOut = ({
  isLive,
  hasShootOut,
  shootOutTaker,
  home,
  away,
  handleClickLive,
  handleToggleShootOut,
  handleUpdateShootOut,
  handleClickUpdate,
}: ShootOutProps) => {
  const [taker, setTaker] = useState<'home' | 'away'>(shootOutTaker);
  const attemptsHome = [...home.attempts];
  const attemptsAway = [...away.attempts];

  useEffect(() => {
    setTaker(shootOutTaker);
  }, [shootOutTaker]);

  const handleClickDelete = () => {
    const newAttemptsHome = attemptsHome.slice(0, -1);
    const newAttemptsAway = attemptsAway.slice(0, -1);

    handleUpdateShootOut({
      taker,
      home: newAttemptsHome,
      away: newAttemptsAway,
    });
  };

  const handleChangeAttempt = (
    team: 'home' | 'away',
    index: number,
    attempt: ShootOutAttemptType
  ) => {
    if (team === 'home') {
      const newAttempts = [...attemptsHome];
      newAttempts[index] = attempt;

      handleUpdateShootOut({
        taker,
        home: newAttempts,
        away: attemptsAway,
      });
    } else {
      const newAttempts = [...attemptsAway];
      newAttempts[index] = attempt;

      handleUpdateShootOut({
        taker,
        home: attemptsHome,
        away: newAttempts,
      });
    }
  };

  const handleClickAddAttempts = () => {
    attemptsHome.push('attempt');
    attemptsAway.push('attempt');

    handleUpdateShootOut({
      taker,
      home: attemptsHome,
      away: attemptsAway,
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between">
        <div className="flex items-center">
          <Input
            type="checkbox"
            checked={hasShootOut}
            onChange={handleToggleShootOut}
            label="SHOOT-OUT"
          />
        </div>
        {hasShootOut && (
          <div className="flex gap-2">
            <MatchControlButton isRunning={isLive} onClick={handleClickLive} className="!w-20">
              {isLive ? 'Out' : 'In'}
            </MatchControlButton>
            <MatchControlButton onClick={handleClickUpdate}>Update</MatchControlButton>
          </div>
        )}
      </div>

      {hasShootOut && (
        <>
          <div className="flex flex-row">
            <div className="flex flex-col p-1 bg-gray-900 flex-1 rounded-l-md">
              <div className="flex items-center">
                <div className="p-2">
                  <MatchControlButton
                    variant="icon"
                    className={classNames({
                      '!bg-primary': taker === 'home',
                    })}
                    onClick={() => setTaker('home')}
                  >
                    <MatchControlIcon iconProps={{ icon: 'soccer-ball', weight: 'fill' }} />
                  </MatchControlButton>
                </div>
                <VerticalDivider />
                <ShootOutContestant
                  shortName={home.shortName}
                  attemptsContestant={attemptsHome}
                  handleChangeAttempt={(index, attempt) =>
                    handleChangeAttempt('home', index, attempt)
                  }
                />
              </div>
              <div className="flex flex-row">
                <div className="flex items-center p-2">
                  <MatchControlButton
                    variant="icon"
                    className={classNames({
                      '!bg-primary': taker === 'away',
                    })}
                    onClick={() => setTaker('away')}
                  >
                    <MatchControlIcon iconProps={{ icon: 'soccer-ball', weight: 'fill' }} />
                  </MatchControlButton>
                </div>
                <VerticalDivider />
                <ShootOutContestant
                  shortName={away.shortName}
                  attemptsContestant={attemptsAway}
                  handleChangeAttempt={(index, attempt) =>
                    handleChangeAttempt('away', index, attempt)
                  }
                />
              </div>
            </div>
            <div className="flex">
              <MatchControlButton
                variant="icon"
                className={classNames({
                  '!bg-primary !h-full rounded-r-md rounded-l-none': true,
                })}
                onClick={handleClickAddAttempts}
              >
                <MatchControlIcon iconProps={{ icon: 'plus-circle', weight: 'bold' }} />
              </MatchControlButton>
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              onClick={handleClickDelete}
              variant="tertiary"
              className="!border-none !px-0 !ring-0"
            >
              Delete
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ShootOut;
