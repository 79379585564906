import { Button } from '@southfields-digital/mpxlive-components';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getRepeaterItems } from 'src/components/GraphicPropertiesForm';
import Repeater from 'src/components/Repeater';
import { Option, Value } from 'src/components/Select';
import { StateType } from 'src/redux/reducers';
import { REQUEST_GET_SPORT_DATA_LINEUP } from 'src/redux/reducers/sportData';
import { Player } from 'src/types/statsperform/lineup';

import SportDataInput from '../SportDataInput';
import SportDataSelect from '../SportDataSelect';
import { getPropertyValues } from '../utils';

import styles from './Lineup.module.scss';

type Props = {
  handleImportData: (values: PropertyValue[]) => void;
  selectedGraphic: PlaylistGraphic;
  selectedProperty: RepeaterProperties;
};

const ContestantOptions: Option[] = [
  { value: 0, label: 'Home' },
  { value: 1, label: 'Away' },
];

const filterPlayers = (player: Player, graphicRef: string): boolean => {
  switch (graphicRef) {
    case 'LineupFull':
      return !['Goalkeeper', 'Substitute'].includes(player.position);
    case 'LineupTicker':
      return player.position !== 'Substitute';
    default:
      return true;
  }
};

export default function Lineup({ handleImportData, selectedGraphic, selectedProperty }: Props) {
  const dispatch = useDispatch();

  const { lineup } = useSelector((state: StateType) => state.sportData);

  const [fixtureId, setFixtureId] = useState<string>('');
  const [contestant, setContestant] = useState<Value>(ContestantOptions[0].value);

  const propertyValues = useMemo(() => {
    const contestantIndex = contestant as number;
    const players = lineup.data?.match?.[0]?.liveData.lineUp?.[contestantIndex]?.player ?? [];
    const lineUp = players.filter((player) => filterPlayers(player, selectedGraphic.graphic.ref));

    return getPropertyValues(selectedGraphic, lineUp, []);
  }, [selectedGraphic, lineup.data?.match, contestant]);

  const repeaterValues = useMemo(
    () => (propertyValues.length ? getRepeaterItems(selectedProperty, propertyValues) : []),
    [propertyValues, selectedProperty]
  );

  useEffect(() => {
    if (!fixtureId) return;
    dispatch({
      type: REQUEST_GET_SPORT_DATA_LINEUP,
      payload: { fx: fixtureId },
    });
  }, [fixtureId, contestant]);

  const handleClickImport = () => handleImportData(propertyValues);

  return (
    <div className={styles.Container}>
      <SportDataInput fixtureId={fixtureId} onChange={(value) => setFixtureId(value)} />
      {fixtureId && repeaterValues.length > 0 && (
        <div className={styles.Content}>
          <div className={styles.Header}>
            <div className={styles.InputWrapper}>
              <SportDataSelect
                label="Contestant"
                value={contestant}
                options={ContestantOptions}
                onChange={(e) => setContestant(e.currentTarget.value)}
              />
            </div>
            <Button className={styles.ImportButton} onClick={handleClickImport} disabled={false}>
              Import
            </Button>
          </div>
          <Repeater
            id=""
            onChangeRows={() => {}}
            items={repeaterValues}
            isDisabled
            excludedIds={['divider', 'highlight']}
          />
        </div>
      )}
    </div>
  );
}
