import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { AddButton } from './buttons';
import HorizontalRepeaterRow from './HorizontalRepeaterRow';
import { Id, Items, RepeaterSortProps } from './types';

type HorizontalRepeaterProps = {
  items: Items[];
  parentRowIndex?: number;
  handleChangeRepeater: (id: Id, rowIndex: number, rows: Items[]) => void;
  handleChangeValue: (id: Id, rowIndex: number, value: string) => void;
  handleClickAdd: () => void;
  handleClickRemove: (index: number) => void;
  handleChangeSort: (newItems: Items[]) => void;
  buttonClassNames?: string;
  excludedIds?: Id[];
  isDisabled?: boolean;
  handleSelectMedia?: (
    properties: ImageProperties,
    handleSelectValue?: (value: string) => void
  ) => void;
};

const HorizontalRepeater = ({
  items,
  handleChangeRepeater,
  handleChangeValue,
  handleClickAdd,
  handleClickRemove,
  handleChangeSort,
  buttonClassNames,
  excludedIds,
  isDisabled,
  handleSelectMedia,
}: HorizontalRepeaterProps) => {
  const [sortedItems, setSortedItems] = useState<RepeaterSortProps[]>([]);

  useEffect(() => {
    setSortedItems(
      items.map<RepeaterSortProps>((item, key) => ({
        id: `HorizontalRepeater-${key}`,
        item,
      }))
    );
  }, [items]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!sortedItems || !over) {
        return;
      }

      if (active.id !== over.id) {
        const oldIndex = sortedItems.findIndex(({ id }) => id === active.id);
        const newIndex = sortedItems.findIndex(({ id }) => id === over.id);

        const localSortItems = arrayMove(sortedItems, oldIndex, newIndex);
        const apiSortItems = localSortItems.map<Items>(({ item }) => ({ ...item }));

        setSortedItems(localSortItems);
        handleChangeSort(apiSortItems);
      }
    },
    [sortedItems]
  );

  const sortedIndices = useMemo(() => sortedItems.map(({ id }) => id), [sortedItems]);

  if (!sortedIndices.length) {
    return null;
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
    >
      <SortableContext items={sortedIndices} strategy={verticalListSortingStrategy}>
        <table>
          <tbody>
            {items.map((item, rowIndex) => (
              <HorizontalRepeaterRow
                key={rowIndex}
                item={item}
                parentRowIndex={rowIndex}
                handleChangeRepeater={handleChangeRepeater}
                handleChangeValue={handleChangeValue}
                handleClickRemove={() => handleClickRemove(rowIndex)}
                handleSelectMedia={handleSelectMedia}
                buttonClassNames={buttonClassNames}
                excludedIds={excludedIds}
                isDisabled={isDisabled}
              />
            ))}
            {!isDisabled && (
              <tr>
                <td />
                <td />
                <td className={buttonClassNames}>
                  <AddButton onClick={handleClickAdd} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </SortableContext>
    </DndContext>
  );
};

export default HorizontalRepeater;
