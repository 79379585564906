import { useEffect } from 'react';

import { Clock } from 'src/types/matchControl/clock';

import { useTimer } from '../hooks';
import { getFormattedTime } from '../utils';

import MatchControlButton from './MatchControlButton';
import MatchControlIcon from './MatchControlIcon';

type Props = {
  clock: Clock;
  handleToggleClock: (clock: Clock) => void;
};

const ChildClock = ({ clock, handleToggleClock }: Props) => {
  const { time: clockTime, setTimer: setClockTime } = useTimer(
    100,
    clock.clockTime,
    clock.clockCountDirection
  );

  useEffect(() => {
    const serverTimeOffset = clock.serverTimeUpdate
      ? new Date(clock.serverTimeUpdate).getTime() - Date.now()
      : 0;

    const startedAt = clock.clockTimeUpdatedAt
      ? new Date(clock.clockTimeUpdatedAt).getTime() - serverTimeOffset
      : null;

    setClockTime(clock.clockTime || 0, startedAt ? new Date(startedAt) : null);
  }, [clock, clock.clockTimeUpdatedAt, clock.clockTime]);

  return (
    <div className="flex flex-row gap-2 items-center">
      {getFormattedTime(clockTime, 'seconds', false)} sec
      <MatchControlButton
        variant="icon"
        isRunning={Boolean(clock.clockTimeUpdatedAt)}
        onClick={() => handleToggleClock(clock)}
      >
        <MatchControlIcon iconProps={{ icon: 'play' }} />
      </MatchControlButton>
    </div>
  );
};

export default ChildClock;
