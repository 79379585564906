import { useState, useEffect, useCallback } from 'react';

import { ClockCountDirection } from 'src/types/matchControl/clock';

const useTimer = (interval: number, initialTime: number, initialDirection: ClockCountDirection) => {
  if (interval < 10) {
    throw new Error('Interval must be at least 10 milliseconds.');
  }

  const [time, setTime] = useState<number>(initialTime);
  const [startedAt, setStartedAt] = useState<number | null>(null);
  const [direction] = useState<ClockCountDirection>(initialDirection);

  const calculateTime = useCallback(() => {
    if (startedAt === null) return initialTime;

    const now = Date.now();
    const elapsed = now - startedAt;
    return direction === 'up' ? initialTime + elapsed : initialTime - elapsed;
  }, [startedAt, direction, initialTime]);

  useEffect(() => {
    if (startedAt === null) return;

    setTime(calculateTime);
    const timer = setInterval(() => setTime(calculateTime), interval);

    return () => clearInterval(timer);
  }, [interval, startedAt, calculateTime]);

  const setTimer = (time: number, dateTime: Date | null) => {
    setTime(time);
    setStartedAt(dateTime ? dateTime.getTime() : null);
  };

  return { time, setTimer };
};

export { useTimer };
