import type { FallbackComponentProps } from 'src/components/ErrorBoundary';
import DefaultBoundary from 'src/components/ErrorBoundary/DefaultBoundary';
import BaseLayout from 'src/layouts/BaseLayout';

const InternalServerError = (props: FallbackComponentProps) => (
  <BaseLayout>
    <DefaultBoundary {...props} />
  </BaseLayout>
);

export default InternalServerError;
