export type DataProviderTranslationsType = DataProviderTranslation[];
export type DataProviderTranslationType = DataProviderTranslation;

export type DataProviderTranslationsResponseType = DataProviderTranslationsType;
export type DataProviderTranslationResponseType = DataProviderTranslationType;

export type DataProviderTranslationStateType = {
  data: DataProviderTranslation[] | null;
  loading: boolean;
  error: string | null;
};

export const DATA_PROVIDER_TRANSLATION = 'dataProviderTranslation';
export type DATA_PROVIDER_TRANSLATION = typeof DATA_PROVIDER_TRANSLATION;

export const REQUEST_GET_DATA_PROVIDER_TRANSLATIONS = `${DATA_PROVIDER_TRANSLATION}/requestGetDataProviderTranslations`;
export type REQUEST_GET_DATA_PROVIDER_TRANSLATIONS = typeof REQUEST_GET_DATA_PROVIDER_TRANSLATIONS;
