import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';

import OutputForm from 'src/pages/Settings/Output/Form';
import { StateType } from 'src/redux/reducers';
import { REQUEST_CREATE_OUTPUT } from 'src/redux/reducers/output';

const mapStateToProps = (state: StateType) => ({
  submitting: state.output.detail.submitting,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createOutput: (name: string, isProtected: boolean, navigate?: NavigateFunction) =>
    dispatch({
      type: REQUEST_CREATE_OUTPUT,
      payload: { data: { name, protected: isProtected }, navigate },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OutputForm);
