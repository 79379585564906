export type RundownsType = Rundown[];
export type RundownType = Rundown;

export type RundownsResponseType = RundownsType;
export type RundownResponseType = RundownType;

export type RundownCollectionStateType = {
  data: Rundown[] | null;
  loading: boolean;
  error: string | null;
};

export type RundownCreateStateType = {
  data: null;
  submitting: boolean;
  error: string | null;
};

export type RundownEditStateType = {
  data: null;
  submitting: boolean;
  error: string | null;
};

export type RundownDetailStateType = {
  data: Rundown | null;
  deleting: boolean;
  loading: boolean;
  error: string | null;
  outputs: RundownOutput[] | null;
};

export type RundownStateType = {
  calendar: RundownCollectionStateType;
  create: RundownCreateStateType;
  edit: RundownEditStateType;
  detail: RundownDetailStateType;
  live: RundownCollectionStateType;
};

export const RUNDOWN = 'rundown';
export type RUNDOWN = typeof RUNDOWN;

export const REQUEST_CREATE_RUNDOWN = `${RUNDOWN}/requestCreateRundown`;
export type REQUEST_CREATE_RUNDOWN = typeof REQUEST_CREATE_RUNDOWN;

export const REQUEST_DELETE_RUNDOWN = `${RUNDOWN}/requestDeleteRundown`;
export type REQUEST_DELETE_RUNDOWN = typeof REQUEST_DELETE_RUNDOWN;

export const REQUEST_UPDATE_RUNDOWN = `${RUNDOWN}/requestUpdateRundown`;
export type REQUEST_UPDATE_RUNDOWN = typeof REQUEST_UPDATE_RUNDOWN;

export const REQUEST_GET_RUNDOWNS = `${RUNDOWN}/requestGetRundowns`;
export type REQUEST_GET_RUNDOWNS = typeof REQUEST_GET_RUNDOWNS;

export const REQUEST_GET_RUNDOWN = `${RUNDOWN}/requestGetRundown`;
export type REQUEST_GET_RUNDOWN = typeof REQUEST_GET_RUNDOWN;

export const REQUEST_GET_LIVE_RUNDOWNS = `${RUNDOWN}/requestGetLiveRundowns`;
export type REQUEST_GET_LIVE_RUNDOWNS = typeof REQUEST_GET_LIVE_RUNDOWNS;

export const REQUEST_GO_LIVE_RUNDOWN = `${RUNDOWN}/requestGoLiveRundown`;
export type REQUEST_GO_LIVE_RUNDOWN = typeof REQUEST_GO_LIVE_RUNDOWN;

export const REQUEST_GO_OFFLINE_RUNDOWN = `${RUNDOWN}/requestGoOfflineRundown`;
export type REQUEST_GO_OFFLINE_RUNDOWN = typeof REQUEST_GO_OFFLINE_RUNDOWN;

export const REQUEST_UPDATE_RUNDOWN_OPERATOR = `${RUNDOWN}/requestUpdateRundownOperator`;
export type REQUEST_UPDATE_RUNDOWN_OPERATOR = typeof REQUEST_UPDATE_RUNDOWN_OPERATOR;

export const REQUEST_GET_OUTPUTS_RUNDOWN = `${RUNDOWN}/requestOutputsRundown`;
export type REQUEST_GET_OUTPUTS_RUNDOWN = typeof REQUEST_GET_OUTPUTS_RUNDOWN;

export const UPDATE_CURRENT_RUNDOWN = `${RUNDOWN}/updateCurrentRundown`;
export type UPDATE_CURRENT_RUNDOWN = typeof UPDATE_CURRENT_RUNDOWN;
