import { IconNames } from '@southfields-digital/mpxlive-components/build/components/Icon';
import classNames from 'classnames';
import React from 'react';

import PanelHeader from '../PanelHeader';

import styles from './PanelContent.module.scss';

interface IPanelContent {
  onClick?: () => void;
  header?: React.ReactNode;
  overflow?: boolean;
  icon?: IconNames;
  canEdit?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export default function PanelContent({
  header,
  icon,
  canEdit,
  onClick,
  children,
  overflow = true,
  className,
}: IPanelContent) {
  return (
    <div className={classNames(styles.Container, className)}>
      {header && <PanelHeader header={header} icon={icon} canEdit={canEdit} onClick={onClick} />}
      <div className={classNames(styles.Content, !overflow && styles.NoOverflow)}>{children}</div>
    </div>
  );
}
