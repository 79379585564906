import { Button } from '@southfields-digital/mpxlive-components';
import classNames from 'classnames';

import styles from './ToggleButtons.module.scss';

type Props<T> = {
  items: T[];
  selectedItem?: T;
  onClickItem: (item: T) => void;
};

const ToggleButtons = <T extends { id: string; name: string; visible?: boolean }>({
  items,
  selectedItem,
  onClickItem,
}: Props<T>) => (
  <div className={styles.Toggle}>
    {items.map((item) => {
      if (item.visible === false) return null;
      return (
        <Button
          variant="tertiary"
          onClick={() => onClickItem(item)}
          className={classNames(
            styles.Button,
            selectedItem?.id === item.id ? styles.Selected : styles.NotSelected
          )}
          key={item.id}
        >
          {item.name}
        </Button>
      );
    })}
  </div>
);

export default ToggleButtons;
