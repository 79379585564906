import Bugsnag from '@bugsnag/js';
import React from 'react';

import DefaultBoundary from './DefaultBoundary';
import { ErrorBoundaryProps } from './types';

export default function ErrorBoundary({
  FallbackComponent = DefaultBoundary,
  children,
  ...props
}: ErrorBoundaryProps) {
  const BugsnagErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);
  if (!BugsnagErrorBoundary) {
    return children;
  }

  return (
    <BugsnagErrorBoundary FallbackComponent={FallbackComponent} {...props}>
      {children}
    </BugsnagErrorBoundary>
  );
}
