import { Button, Form, PlayButton } from '@southfields-digital/mpxlive-components';
import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import useKeypress from 'src/hooks/useKeypress';
import {
  REQUEST_ANIMATE_IN_LIVE_GRAPHIC,
  REQUEST_ANIMATE_IN_PREVIEW_GRAPHIC,
  REQUEST_ANIMATE_OUT_LIVE_GRAPHIC,
  REQUEST_ANIMATE_OUT_PREVIEW_GRAPHIC,
  REQUEST_UPDATE_LIVE_GRAPHIC,
  REQUEST_UPDATE_PREVIEW_GRAPHIC,
} from 'src/redux/reducers/control';
import { REQUEST_UPDATE_PLAYLIST_GRAPHIC } from 'src/redux/reducers/playlistGraphic';

import styles from './GraphicControlForm.module.scss';
import { isSwappable } from './utils';

interface IGRaphicControlFormProps {
  selectedGraphic: PlaylistGraphic;
  showStatsPerform?: () => void;
}

export default function GraphicControlForm({
  selectedGraphic,
  showStatsPerform,
}: IGRaphicControlFormProps) {
  const dispatch = useDispatch();
  const [name, setName] = useState<string>('');
  const [editable, setEditable] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setName(selectedGraphic?.name || '');
  }, [selectedGraphic]);

  const handleSubmitForm = () => {
    updateGraphicProperties();
    setEditable(false);
  };

  const updateGraphicProperties = () => {
    dispatch({
      type: REQUEST_UPDATE_PLAYLIST_GRAPHIC,
      payload: { graphic: { ...selectedGraphic, name } },
    });
  };

  const previewIn = () => {
    if (!selectedGraphic) return;

    dispatch({
      type: REQUEST_ANIMATE_IN_PREVIEW_GRAPHIC,
      payload: { playlistGraphicId: selectedGraphic.id },
    });
  };

  const previewOut = () => {
    if (!selectedGraphic) return;

    dispatch({
      type: REQUEST_ANIMATE_OUT_PREVIEW_GRAPHIC,
      payload: { playlistGraphicId: selectedGraphic.id },
    });
  };

  const handleTogglePreview = () => {
    if (!selectedGraphic) return;

    if (selectedGraphic.status.preview) previewOut();
    else previewIn();
  };

  useKeypress('F3', {}, () => {
    previewIn();
  });

  useKeypress('F4', {}, () => {
    previewOut();
  });

  const liveIn = () => {
    if (!selectedGraphic) return;

    dispatch({
      type: REQUEST_ANIMATE_IN_LIVE_GRAPHIC,
      payload: { playlistGraphicId: selectedGraphic.id },
    });
  };

  const liveOut = () => {
    if (!selectedGraphic) return;

    dispatch({
      type: REQUEST_ANIMATE_OUT_LIVE_GRAPHIC,
      payload: { playlistGraphicId: selectedGraphic.id },
    });
  };

  const fade = () => {
    dispatch({
      type: REQUEST_UPDATE_LIVE_GRAPHIC,
      payload: { playlistGraphicId: selectedGraphic.id, payload: 'swap:fade' },
    });
  };

  const cut = () => {
    dispatch({
      type: REQUEST_UPDATE_LIVE_GRAPHIC,
      payload: { playlistGraphicId: selectedGraphic.id, payload: 'swap:cut' },
    });
  };

  const tickNext = (preview = false) => {
    if (!selectedGraphic || !selectedGraphic.graphic.config.isTickable) return;
    dispatch({
      type: preview ? REQUEST_UPDATE_PREVIEW_GRAPHIC : REQUEST_UPDATE_LIVE_GRAPHIC,
      payload: { playlistGraphicId: selectedGraphic.id, payload: 'tick:next' },
    });
  };

  const tickPrev = (preview?: boolean) => {
    if (!selectedGraphic || !selectedGraphic.graphic.config.isTickable) return;
    dispatch({
      type: preview ? REQUEST_UPDATE_PREVIEW_GRAPHIC : REQUEST_UPDATE_LIVE_GRAPHIC,
      payload: { playlistGraphicId: selectedGraphic.id, payload: 'tick:previous' },
    });
  };

  useKeypress('F1', {}, () => {
    if (!selectedGraphic || (selectedGraphic.status.live && !isSwappable(selectedGraphic))) return;
    liveIn();
  });

  useKeypress('F2', {}, () => {
    if (!selectedGraphic || !selectedGraphic.status.live) return;
    liveOut();
  });

  const handleToggleLive = () => {
    if (!selectedGraphic) return;

    if (selectedGraphic.status.live) {
      liveOut();
    } else {
      liveIn();
    }
  };

  const handleClickCut = () => {
    if (!selectedGraphic || !isSwappable(selectedGraphic)) return;
    cut();
  };

  const handleClickFade = () => {
    if (!selectedGraphic || !isSwappable(selectedGraphic)) return;
    fade();
  };

  const handleClickEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setEditable(true);
    inputRef.current?.focus();
  };

  const handleClickInput = (e: React.MouseEvent<HTMLInputElement>) => {
    if (e.detail === 2) {
      setEditable(true);
      inputRef.current?.focus();
    }
  };

  const handleBlurInput = () => {
    updateGraphicProperties();
    setEditable(false);
  };

  if (!selectedGraphic) return null;
  return (
    <div className={styles.Container}>
      <Form onSubmit={handleSubmitForm} className={styles.Form}>
        <Button
          as="button"
          variant="tertiary"
          iconProps={{ icon: 'pencil', size: 16, color: '#5B5D70' }}
          onClick={handleClickEdit}
          className={styles.EditButton}
        />
        <input
          name="name"
          ref={inputRef}
          readOnly={!editable}
          className={styles.Input}
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value)}
          onClick={handleClickInput}
          onBlur={handleBlurInput}
        />
      </Form>
      <div className={styles.Controls}>
        {showStatsPerform && (
          <div>
            <PlayButton hasIcon={false} isRunning={false} variant="live" onClick={showStatsPerform}>
              Import Data
            </PlayButton>
          </div>
        )}
        <div className={styles.PreviewControls}>
          <PlayButton
            hasIcon
            variant="preview"
            onClick={handleTogglePreview}
            isRunning={selectedGraphic.status.preview}
            className={styles.PreviewButton}
          >
            {selectedGraphic.status.preview ? 'Preview Out' : 'Preview'}
          </PlayButton>
          {selectedGraphic.graphic.config.isTickable && (
            <>
              <PlayButton
                variant="tickPrev"
                hasIcon
                isRunning={selectedGraphic.status.preview}
                onClick={() => tickPrev(true)}
              />
              <PlayButton
                variant="tickNext"
                hasIcon
                isRunning={selectedGraphic.status.preview}
                onClick={() => tickNext(true)}
              />
            </>
          )}
        </div>
        {selectedGraphic.graphic.config.isSwappable && (
          <div className={styles.SwappableContainer}>
            <PlayButton
              hasIcon
              iconPosition="right"
              isRunning={false}
              variant="fade"
              onClick={handleClickFade}
              className={styles.FadeButton}
            >
              Fade
            </PlayButton>
            <PlayButton
              hasIcon
              iconPosition="right"
              isRunning={false}
              variant="cut"
              className={styles.CutButton}
              onClick={handleClickCut}
            >
              Cut
            </PlayButton>
          </div>
        )}
        <div className={styles.LiveControls}>
          <PlayButton
            hasIcon
            variant="live"
            onClick={handleToggleLive}
            isRunning={selectedGraphic.status.live}
            className={styles.LiveButton}
          >
            {selectedGraphic.status.live ? 'Live Out' : 'Live'}
          </PlayButton>
          {selectedGraphic.graphic.config.isTickable && (
            <>
              <PlayButton
                variant="tickPrev"
                hasIcon
                isRunning={selectedGraphic.status.live}
                onClick={() => tickPrev()}
              />
              <PlayButton
                variant="tickNext"
                hasIcon
                isRunning={selectedGraphic.status.live}
                onClick={() => tickNext()}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
