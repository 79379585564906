import { Text } from '@southfields-digital/mpxlive-components';

import { CardTypes } from 'src/types/matchControl/card';

import styles from '../MatchControl.module.scss';
import { MatchControlVariant } from '../types';

import MatchControlButton from './MatchControlButton';
import MatchControlIcon from './MatchControlIcon';
import ShortName from './ShortName';

interface IProps {
  card: CardTypes;
  amount: number;
  variant?: MatchControlVariant;
  teamName?: string;
  disabled?: boolean;
  inverted?: boolean;
  handleChangeCards?: (card: CardTypes, amount: number) => void;
  handleChangeTeamName?: (name: string) => void;
}

const cardParagraphStyles: Record<CardTypes, string> = {
  [CardTypes.RED_CARD]: styles.RedCardParagraph,
  [CardTypes.GREEN_CARD]: styles.GreenCardParagraph,
  [CardTypes.YELLOW_CARD]: styles.YellowCardParagraph,
};

const Cards = ({
  card,
  amount,
  teamName,
  handleChangeTeamName,
  disabled = false,
  inverted = false,
  handleChangeCards,
  variant = 'default',
}: IProps) => {
  const handleAddCard = () => {
    if (!handleChangeCards) return;

    const newAmount = amount + 1;
    handleChangeCards(card, newAmount);
  };
  const handleSubtractCard = () => {
    if (!handleChangeCards) return;

    // Prevent negative card amounts
    const newAmount = Math.max(amount - 1, 0);
    handleChangeCards(card, newAmount);
  };

  if (variant === 'extended') {
    return (
      <div className="flex flex-col gap-2 items-center">
        <div className={styles.CardParagraphWrapper}>
          <Text as="h3">Red Cards</Text>
          <p className={styles.CardParagraph}>{amount}</p>
        </div>
        <div className="flex gap-4">
          <MatchControlButton
            className={styles.MainButton}
            onClick={handleSubtractCard}
            disabled={disabled}
          >
            REMOVE RED CARD
          </MatchControlButton>
          <MatchControlButton
            className={styles.MainButton}
            onClick={handleAddCard}
            disabled={disabled}
          >
            ADD RED CARD
          </MatchControlButton>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.CardsMainWrapper}>
      {teamName && (
        <ShortName
          shortName={teamName}
          handleChangeShortName={handleChangeTeamName}
          inverted={inverted}
        />
      )}

      <div className={styles.CardWrapper}>
        <MatchControlButton variant="icon" onClick={handleSubtractCard} disabled={disabled}>
          <MatchControlIcon iconProps={{ icon: 'minus' }} />
        </MatchControlButton>

        <div className={styles.CardParagraphWrapper}>
          <p className={cardParagraphStyles[card]}>{amount}</p>
        </div>

        <MatchControlButton variant="icon" onClick={handleAddCard} disabled={disabled}>
          <MatchControlIcon iconProps={{ icon: 'plus' }} />
        </MatchControlButton>
      </div>
    </div>
  );
};

export default Cards;
