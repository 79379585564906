import { FormGroup } from '@southfields-digital/mpxlive-components';

import Select, { Option, Value } from 'src/components/Select';

type SportDataSelectProps = {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Option[];
  value: Value;
  label: string;
};

const SportDataSelect = ({ onChange, options, value, label }: SportDataSelectProps) => (
  <FormGroup label={label} htmlFor={label}>
    <Select id={label} value={value} options={options} onChange={onChange} />
  </FormGroup>
);

export default SportDataSelect;
