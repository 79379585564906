import {
  Directory,
  DirectoryBrowser,
  MediaLibrary,
  MediaLibrarySearchOption,
  isFile,
} from '@southfields-digital/mpxlive-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ImperativePanelHandle } from 'react-resizable-panels';
import { Socket } from 'socket.io-client';

import {
  PanelGroup,
  Panel,
  PanelResizeHandle as ResizeHandle,
} from 'src/components/ResizeablePanel';
import { MEDIA_LIBRARY_FILES_PAGE_SIZE } from 'src/config/mediaLibrary';
import { InjectedBaseLayoutContextProps, injectBaseLayoutContext } from 'src/layouts/BaseLayout';
import { WebsocketBuddy } from 'src/services/websockets';

import styles from './MediaLibrary.module.scss';
import type { MediaLibraryPageProps } from './MediaLibrary.types';
import { getRawPath } from './MediaLibrary.utils';

const workspaceDirectory: Directory = { id: 'root', name: 'Workspace', preventDeletion: true };

const MediaLibraryPage = ({
  baseLayoutContext: { setFullWidth },
  count,
  createFolderAtPath,
  deleteDirectory,
  deleteFile,
  directories,
  files,
  getDataByDirectory,
  isLoading,
  isLocked,
  searchCount,
  searchDataByDirectory,
  searchResults,
  updateDirectoryName,
  uploadFiles,
  workspaceId,
}: MediaLibraryPageProps & InjectedBaseLayoutContextProps) => {
  const pageSize = MEDIA_LIBRARY_FILES_PAGE_SIZE;

  // State
  const [activeIds, setActiveIds] = useState(['root']);
  const [currentDirectory, setCurrentDirectory] = useState(workspaceDirectory);
  const [currentPage, setCurrentPage] = useState(1);
  const [directoryName, setDirectoryName] = useState(currentDirectory?.name);
  const [hasMore, setHasMore] = useState(false);
  const [searchOption, setSearchOption] = useState<MediaLibrarySearchOption>({
    id: currentDirectory.id,
    label: currentDirectory.name,
  });
  const [currentDirectoryLocked, setCurrentDirectoryLocked] = useState(isLocked);

  const [searchTerm, setSearchTerm] = useState('');

  // Refs
  const directoryBrowserPanel = useRef<ImperativePanelHandle>(null);
  // Current directory is volatile and likely to change during API requests, so define a ref for use in callbacks
  const guaranteedCurrentDirectory = useRef<Directory>(currentDirectory);
  // Current page is volatile and likely to change during API requests, so define a ref for use in callbacks
  const guaranteedCurrentPage = useRef<number>(currentPage);
  const previousSearchTerm = useRef('');
  const socketInstance = useRef<Socket | null>(null);
  const switchingDirectories = useRef(false);

  // Memoized data
  const loadingNextDirectory = useMemo(
    () => isLoading && switchingDirectories.current,
    [isLoading]
  );
  const data = useMemo(
    () => (loadingNextDirectory ? [] : searchTerm ? searchResults : files),
    [files, loadingNextDirectory, searchResults, searchTerm]
  );
  const searchOptions = useMemo(
    () =>
      currentDirectory.id !== 'root'
        ? [workspaceDirectory, { id: currentDirectory.id, name: 'Current folder' }].map(
            ({ id, name }) => ({ id, label: name })
          )
        : [],
    [currentDirectory.id]
  );

  // Handlers
  const deleteDirectoryHandler = useCallback(
    (directoryId: string) => {
      deleteDirectory({
        id: directoryId,
        callback: () => {
          setCurrentDirectory(workspaceDirectory);
          setActiveIds(['root']);
          getDataByDirectory({ id: 'root' });
        },
      });
    },
    [setCurrentDirectory, setActiveIds, getDataByDirectory]
  );

  const getDataHandler = useCallback(
    (directory: Directory) => {
      switchingDirectories.current = true;

      // Update state
      setSearchTerm('');
      setCurrentPage(1);
      setCurrentDirectory(directory || workspaceDirectory);
      setCurrentDirectoryLocked(false);

      // Fetch data
      getDataByDirectory({
        id: directory.id || workspaceDirectory.id,
        pagination: { offset: 0, pageSize },
      });
    },
    [setSearchTerm, setCurrentPage, setCurrentDirectory, setCurrentDirectoryLocked]
  );

  const submitFilesHandler = useCallback(
    (fileList: FileList | null) => {
      const directoryId = currentDirectory.id;

      if (fileList && fileList?.length > 0) {
        uploadFiles({
          directoryId,
          fileList,
          callback: (uploadedFileCount: number) => {
            // Refresh data if current directory is unchanged at the callback
            if (guaranteedCurrentDirectory.current.id === directoryId) {
              // Correct for fixed page size and variable uploaded file count
              const currentFileLength =
                uploadedFileCount + guaranteedCurrentPage.current * pageSize;
              const correctedPageSize = currentFileLength + (currentFileLength % pageSize);
              const currentPageDiff = correctedPageSize / pageSize - guaranteedCurrentPage.current;

              // Correct the current page size to keep infinite scroll working
              if (currentPageDiff > 0) {
                setCurrentPage(guaranteedCurrentPage.current + currentPageDiff);
              }

              getDataByDirectory({
                id: directoryId,
                pagination: { offset: 0, pageSize: correctedPageSize },
                hideLoader: true,
              });
            }
          },
        });
      }
    },
    [currentDirectory]
  );

  const refreshCurrentDirectoryOnReceiveWebsocketMessage = useCallback(
    ({ directoryId }: { directoryId: string }) => {
      // Get the latest data when a directory is updated
      if (directoryId === guaranteedCurrentDirectory.current.id) {
        setTimeout(() => {
          getDataByDirectory({
            id: guaranteedCurrentDirectory.current.id,
            pagination: { offset: 0, pageSize: currentPage * pageSize },
            hideLoader: true,
          });
        }, 10000); // Delay to allow Lamba's to process thumbs / previews
      }
    },
    [currentDirectory, currentPage]
  );

  // useEffect hooks
  useEffect(() => {
    getDataByDirectory({ id: 'root' });
    setFullWidth(true);

    if (workspaceId) {
      const { socket } = WebsocketBuddy({
        channel: btoa(`${workspaceId}:::mediaLibrary`),
        driver: 'SocketIo',
        listeners: {
          directoryLocked: ({ directoryId }) => {
            if (directoryId === guaranteedCurrentDirectory.current.id) {
              setCurrentDirectoryLocked(true);
            }
          },
          directoryLockReleased: ({ directoryId }) => {
            if (directoryId === guaranteedCurrentDirectory.current.id) {
              setCurrentDirectoryLocked(false);
            }
          },
          directoryUpdated: refreshCurrentDirectoryOnReceiveWebsocketMessage,
        },
      });

      socketInstance.current = socket;
    }

    return function unmount() {
      setFullWidth(false);

      if (socketInstance.current) {
        socketInstance?.current.disconnect();
        socketInstance.current = null;
      }
    };
  }, []);

  useEffect(() => {
    setCurrentDirectoryLocked(isLocked);
  }, [isLocked]);

  useEffect(() => {
    guaranteedCurrentDirectory.current = currentDirectory;
    setDirectoryName(currentDirectory.name);
    setSearchOption({ id: currentDirectory.id, label: currentDirectory.name });
  }, [currentDirectory]);

  useEffect(() => {
    guaranteedCurrentPage.current = currentPage;
  }, [currentPage]);

  useEffect(() => {
    const searchCleared = !searchTerm && previousSearchTerm.current.length > searchTerm.length;

    // Update state
    setCurrentPage(1);

    // Search if search term is not empty
    if (searchTerm) {
      // Set directory name
      setDirectoryName(searchOption.label);

      // Hide loader during API request
      switchingDirectories.current = false;

      // Collapse the directory browser panel
      directoryBrowserPanel.current?.collapse();

      searchDataByDirectory({
        id: searchOption.id,
        pagination: { offset: 0, pageSize },
        searchTerm,
      });
    }

    // Get data normally if search term is empty
    if (searchCleared) {
      // Set directory name
      setDirectoryName(currentDirectory.name);

      // Show loader during API request
      switchingDirectories.current = true;

      // Expand the directory browser panel
      directoryBrowserPanel.current?.expand();

      getDataByDirectory({
        id: currentDirectory.id,
        pagination: { offset: 0, pageSize },
      });
    }

    previousSearchTerm.current = searchTerm;
  }, [currentDirectory, searchTerm, searchOption]);

  useEffect(() => {
    if (searchTerm) {
      setHasMore(searchCount > files.length && searchCount > pageSize * currentPage);
    } else {
      setHasMore(count > files.length && count > pageSize * currentPage);
    }
  }, [count, files, currentPage, searchCount, searchTerm]);

  return (
    <div className={styles.Library}>
      <PanelGroup direction="vertical">
        <Panel collapsible collapsedSize={0} defaultSize={25} ref={directoryBrowserPanel}>
          <DirectoryBrowser
            activeIds={activeIds}
            createDirectory={(path) =>
              new Promise((resolve) => {
                createFolderAtPath({
                  path: getRawPath(path),
                  insertIntoDirectoryId: activeIds.slice(-1)[0],
                  callback: resolve,
                });
              })
            }
            data={(directories || []) as Directory[]}
            getData={getDataHandler}
            setActiveIds={setActiveIds}
            onDeleteConfirm={deleteDirectoryHandler}
          />
        </Panel>

        {!searchTerm && <ResizeHandle />}

        <Panel defaultSize={75}>
          <MediaLibrary
            key={`${currentDirectory.id}_${directoryName}`}
            deleteFile={({ id }) => deleteFile(id)}
            directoryName={directoryName}
            hasMore={hasMore}
            itemsLength={pageSize * currentPage}
            isLoading={isLoading}
            isLocked={currentDirectoryLocked}
            isRoot={currentDirectory.id === 'root'}
            libraryData={data}
            loadMore={() => {
              setCurrentPage((prevPage) => {
                switchingDirectories.current = false;

                const nextPage = prevPage + 1;
                const nextPageZeroIndexed = nextPage - 1; // For clarity

                if (searchTerm) {
                  searchDataByDirectory({
                    id: searchOption.id,
                    pagination: {
                      offset: nextPageZeroIndexed * pageSize,
                      pageSize,
                    },
                    searchTerm,
                  });
                } else {
                  getDataByDirectory({
                    id: currentDirectory.id,
                    pagination: {
                      offset: nextPageZeroIndexed * pageSize,
                      pageSize,
                    },
                  });
                }

                return nextPage;
              });
            }}
            onItemClick={(item) => {
              if (!isFile(item)) {
                getDataHandler(item as Directory);
              }
            }}
            searchOption={searchOption}
            searchOptions={searchTerm ? searchOptions : []}
            searchQuery={searchTerm}
            setSearchOption={(searchOption: MediaLibrarySearchOption) => {
              setSearchOption(searchOption);
              setDirectoryName(searchOption?.label);
            }}
            setSearchQuery={setSearchTerm}
            supportedMimeTypes={['image/jpg', 'image/jpeg', 'image/png']}
            updateDirectoryName={({ updatedDirectoryName, onSuccess, onFailure }) => {
              setCurrentDirectoryLocked(true);
              updateDirectoryName({
                id: currentDirectory.id,
                name: updatedDirectoryName,
                onSuccess,
                onFailure,
              });
            }}
            uploadFiles={submitFilesHandler}
            variant="full"
          />
        </Panel>
      </PanelGroup>
    </div>
  );
};

export default injectBaseLayoutContext(MediaLibraryPage);
