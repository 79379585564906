import { Id, Items } from './types';
import VerticalRepeaterRow from './VerticalRepeaterRow';

type VerticalRepeaterProps = {
  items: Items[];
  buttonClassNames?: string;
  excludedIds?: Id[];
  isDisabled?: boolean;
  handleClickRemove: (index: number) => void;
  handleChangeRepeater: (id: Id, rowIndex: number, rows: Items[]) => void;
  handleChangeValue: (id: Id, rowIndex: number, value: string) => void;
  handleSelectMedia?: (
    properties: ImageProperties,
    handleSelectValue?: (value: string) => void
  ) => void;
};

const VerticalRepeaterRows = ({ items, ...props }: VerticalRepeaterProps) => (
  <>
    {items.map((item, i) => (
      <VerticalRepeaterRow key={i} item={item} rowIndex={i} {...props} />
    ))}
  </>
);

export default VerticalRepeaterRows;
