import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RundownsResponseType, RundownStateType, RUNDOWN, RundownResponseType } from './types';

const rundownInitialState: RundownStateType = {
  calendar: {
    data: null,
    loading: true, // start with loading state to avoid flash
    error: null,
  },
  create: {
    data: null,
    submitting: false,
    error: null,
  },
  edit: {
    data: null,
    submitting: false,
    error: null,
  },
  detail: {
    data: null,
    deleting: false,
    loading: true,
    error: null,
    outputs: null,
  },
  live: {
    data: null,
    loading: true, // start with loading state to avoid flash
    error: null,
  },
};

export const rundownSlice = createSlice({
  name: RUNDOWN,
  initialState: rundownInitialState,
  // Apply state changes
  reducers: {
    requestCreateRundown: (rundown: RundownStateType) => {
      rundown.create.submitting = true;
    },
    receiveCreateRundown: (rundown: RundownStateType) => {
      rundown.create.submitting = false;
      rundown.create.error = null;
    },
    failedCreateRundown: (rundown: RundownStateType, { payload: error }: PayloadAction<string>) => {
      rundown.create.submitting = false;
      rundown.create.error = error;
    },
    requestDeleteRundown: (rundown: RundownStateType) => {
      rundown.detail.deleting = true;
      rundown.calendar.loading = true;
    },
    receiveDeleteRundown: (
      rundown: RundownStateType,
      { payload: rundownId }: PayloadAction<string>
    ) => {
      rundown.calendar.data =
        rundown.calendar.data?.filter((rundown) => rundown.id !== rundownId) || null;

      if (rundown.detail.data?.id === rundownId) {
        rundown.detail.data = null;
      }

      rundown.detail.deleting = false;
      rundown.detail.error = null;

      rundown.calendar.loading = false;
      rundown.calendar.error = null;
    },
    failedDeleteRundown: (rundown: RundownStateType) => {
      rundown.detail.deleting = false;
      rundown.calendar.loading = false;
    },
    requestUpdateRundown: (rundown: RundownStateType) => {
      rundown.edit.submitting = true;
      rundown.detail.loading = true;
    },
    receiveUpdateRundown: (
      rundown: RundownStateType,
      { payload: updatedRundown }: PayloadAction<Rundown>
    ) => {
      rundown.edit.submitting = false;
      rundown.edit.error = null;

      rundown.detail.loading = false;
      rundown.detail.error = null;

      if (!updatedRundown || !rundown.detail.data) return;
      rundown.detail.data =
        rundown.detail.data.id === updatedRundown.id ? updatedRundown : rundown.detail.data;
    },
    failedUpdateRundown: (rundown: RundownStateType) => {
      rundown.edit.submitting = false;
      rundown.detail.loading = false;
    },
    requestGetLiveRundowns: (rundown: RundownStateType) => {
      rundown.live.loading = true;
    },
    receiveGetLiveRundowns: (
      rundown: RundownStateType,
      { payload }: PayloadAction<RundownsResponseType>
    ) => {
      rundown.live.loading = false;
      rundown.live.data = payload;
      rundown.live.error = null;
    },
    failedGetLiveRundowns: (
      rundown: RundownStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      rundown.live.loading = false;
      rundown.live.error = error;
    },
    requestGetRundowns: (rundown: RundownStateType) => {
      rundown.calendar.loading = true;
    },
    receiveGetRundowns: (
      rundown: RundownStateType,
      { payload }: PayloadAction<RundownsResponseType>
    ) => {
      rundown.calendar.loading = false;
      rundown.calendar.data = payload;
      rundown.calendar.error = null;
    },
    failedGetRundowns: (rundown: RundownStateType, { payload: error }: PayloadAction<string>) => {
      rundown.calendar.loading = false;
      rundown.calendar.error = error;
    },
    requestGetRundown: (rundown: RundownStateType) => {
      rundown.detail.loading = true;
    },
    receiveGetRundown: (
      rundown: RundownStateType,
      { payload }: PayloadAction<RundownResponseType | null>
    ) => {
      rundown.detail.loading = false;
      rundown.detail.data = payload;
      rundown.detail.error = null;

      if (!payload || !rundown.detail.data) return;

      rundown.detail.data.graphicPackageId = payload.graphicPackage?.id as string;
      rundown.detail.data.rundownTemplateId = payload.rundownTemplate?.id || 'blank';
    },
    failedGetRundown: (rundown: RundownStateType, { payload: error }: PayloadAction<string>) => {
      rundown.detail.loading = false;
      rundown.detail.error = error;
    },
    updateCurrentRundown: (
      rundown: RundownStateType,
      { payload }: PayloadAction<RundownResponseType>
    ) => {
      if (!rundown.detail.data) return;
      Object.assign(rundown.detail.data, payload);
    },
    failedGetActiveGraphicsRundown: () => {},
    requestGoLiveRundown: (rundown: RundownStateType) => {
      if (!rundown.detail.data) return;
      rundown.detail.data.isLive = true;
    },
    receiveGoLiveRundown: (rundown: RundownStateType) => {
      if (!rundown.detail.data) return;
      rundown.detail.data.isLive = true;
    },
    failedGoLiveRundown: (rundown: RundownStateType) => {
      if (!rundown.detail.data) return;
      rundown.detail.data.isLive = false;
    },
    requestGoOfflineRundown: (rundown: RundownStateType) => {
      if (!rundown.detail.data) return;
      rundown.detail.data.isLive = true;
    },
    receiveGoOfflineRundown: (rundown: RundownStateType) => {
      if (!rundown.detail.data) return;
      rundown.detail.data.isLive = true;
    },
    failedGoOfflineRundown: (rundown: RundownStateType) => {
      if (!rundown.detail.data) return;
      rundown.detail.data.isLive = false;
    },
    requestUpdateRundownOperator: () => {},
    receiveUpdateRundownOperator: () => {},
    failedUpdateRundownOperator: () => {},
    requestGetOutputsRundown: () => {},
    receiveGetOutputsRundown: (
      rundown: RundownStateType,
      { payload }: PayloadAction<RundownOutput[]>
    ) => {
      rundown.detail.outputs = payload;
      rundown.detail.error = null;
    },
    failedGetOutputsRundown: () => {},
  },
});

const actions = rundownSlice.actions;
const rundownReducer = rundownSlice.reducer;

export { actions, rundownReducer };
