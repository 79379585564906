import classNames from 'classnames';

import styles from './LivePulse.module.scss';
import { LivePulseProps } from './LivePulse.types';

export default function LivePulse({
  className,
  dotClassName,
  pingClassName,
  wrapperClassName,
}: LivePulseProps) {
  return (
    <div className={classNames(styles.LivePulseWrapper, wrapperClassName)}>
      <span className={classNames(styles.LivePulse, className)}>
        <span className={classNames(styles.Ping, pingClassName)} />
        <span className={classNames(styles.Dot, dotClassName)} />
      </span>
    </div>
  );
}
