import { Contestant as ContestantType } from 'src/types/matchControl/contestant';

import Contestant from './Contestant';
import styles from './RundownMatchControl.module.scss';

type Props = {
  handleChangeContestant: (contestant: ContestantType, index: number) => void;
  handleClickMedia: (contestant: ContestantType, index: number) => void;
  contestants: ContestantType[];
};

const Tennis = ({ contestants, handleChangeContestant, handleClickMedia }: Props) => (
  <div className={styles.SportContainer}>
    <div className={styles.Contestants}>
      {contestants?.map((contestant, idx) => (
        <Contestant
          key={idx}
          sport="tennis"
          contestant={contestant}
          handleChangeContestant={(contestant) => handleChangeContestant(contestant, idx)}
          handleClickMedia={(contestant) => handleClickMedia(contestant, idx)}
          inverted={idx % 2 === 1}
        />
      ))}
    </div>
  </div>
);

export default Tennis;
