import { Directory } from '@southfields-digital/mpxlive-components';
import { useCallback, useEffect, useMemo, useState } from 'react';

type MediaLibraryPath = {
  [key: `medialibrary:initialpath:${string}`]: Directory[];
};
type BrowserStorageSchema = MediaLibraryPath;

export const useWebStorage = <Key extends keyof BrowserStorageSchema>(
  storage: Storage,
  key: Key,
  initial: BrowserStorageSchema[Key]
) => {
  const getItem = useCallback((): BrowserStorageSchema[Key] => {
    const item = storage.getItem(key);
    try {
      return item ? JSON.parse(item) : initial;
    } catch (error) {
      return initial;
    }
  }, [key, initial]);
  const [item, setItem] = useState(getItem());

  useEffect(() => {
    storage.setItem(key, JSON.stringify(item));
  }, [item, key, storage]);

  const memoizedItem = useMemo(() => item, [item]);

  return [memoizedItem, setItem] as const;
};

export const useLocalStorage = <Key extends keyof BrowserStorageSchema>(
  key: Key,
  initial: BrowserStorageSchema[Key]
) => useWebStorage(localStorage, key, initial);

export const useSessionStorage = <Key extends keyof BrowserStorageSchema>(
  key: Key,
  initial: BrowserStorageSchema[Key]
) => useWebStorage(sessionStorage, key, initial);
