import { Button, Dialog, FormGroup, Loader } from '@southfields-digital/mpxlive-components';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CopyInput from 'src/components/CopyInput';
import Select, { Option } from 'src/components/Select';
import { StateType } from 'src/redux/reducers';
import { REQUEST_GET_OUTPUTS } from 'src/redux/reducers/output';
import { REQUEST_UPDATE_RUNDOWN } from 'src/redux/reducers/rundown';
import { getOutputUrl } from 'src/utils';

import styles from './RundownOutputDialog.module.scss';

type Props = {
  show: boolean;
  onClose: () => void;
  message?: React.ReactNode;
};

const EmptyOption: Option = { label: '-', value: '' };

export default function RundownOutputDialog({ show, message, onClose }: Props) {
  const dispatch = useDispatch();
  const [state, setState] = useState<RundownTemplateCanvas[] | undefined>();
  const { data: outputs, loading: outputsLoading } = useSelector(
    (state: StateType) => state.output.collection
  );
  const { data: rundown, loading: rundownLoading } = useSelector(
    (state: StateType) => state.rundown.detail
  );

  const loading = outputsLoading || rundownLoading;
  const disabled = rundown?.isLive;

  const options: Option[] = [
    EmptyOption,
    ...(outputs?.map((output) => ({
      label: output.name,
      value: output.id,
      disabled: output.disabled,
    })) || []),
  ];

  useEffect(() => {
    if (rundown && show) {
      setState(rundown.canvases);
      dispatch({ type: REQUEST_GET_OUTPUTS, payload: { rundownId: rundown.id } });
    }
  }, [rundown, show]);

  const updateState = (update: RundownTemplateCanvas) => {
    setState((prevState) =>
      prevState?.map((canvas) => (canvas.id === update.id ? update : canvas))
    );
  };

  const handleClickConfirm = () => {
    dispatch({
      type: REQUEST_UPDATE_RUNDOWN,
      payload: { data: { ...rundown, canvases: state } },
    });
    onClose();
  };

  return (
    <Dialog backdrop open={show} onClose={onClose}>
      <Dialog.Panel className={styles.Panel}>
        <Dialog.Title className={styles.Title}>Outputs</Dialog.Title>
        <div className={styles.Container}>
          {loading ? <Loader /> : null}
          {!loading && message ? <div className={styles.Message}>{message}</div> : null}
          {state &&
            !loading &&
            state
              .filter((canvas) => canvas.graphics.length)
              .map((canvas) => {
                const { id, name, output } = canvas;
                const value = options.find((option) => option.value === output?.id)?.value;
                const outputUrl = output ? getOutputUrl(output, 'pgm') : undefined;
                return (
                  <div className={styles.Row} key={id}>
                    <FormGroup label={name} htmlFor={id}>
                      <Select
                        id={id}
                        placeholder="Pick an output"
                        value={value || EmptyOption.value}
                        options={options}
                        disabled={disabled}
                        onChange={(e) =>
                          updateState({
                            ...canvas,
                            output: outputs?.find((output) => output.id === e.currentTarget.value),
                          })
                        }
                        className={classNames(styles.InputWrapper, { [styles.Error]: !value })}
                      />
                    </FormGroup>
                    {outputUrl && value ? (
                      <div>
                        <CopyInput value={outputUrl} />
                      </div>
                    ) : (
                      <div className={styles.NoOutput}>Please select an output</div>
                    )}
                  </div>
                );
              })}
        </div>
        <Dialog.Footer className={styles.Footer}>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          {disabled ? null : (
            <Button variant="primary" onClick={handleClickConfirm}>
              Confirm
            </Button>
          )}
        </Dialog.Footer>
      </Dialog.Panel>
    </Dialog>
  );
}
