import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserAuthResponseType, UserStateType, USER } from './types';

const userInitialState: UserStateType = {
  data: null,
  auth: {
    authenticated: false,
    checked: false,
    error: null,
    id: null,
    name: undefined,
    email: undefined,
    roles: undefined,
    workspace: undefined,
  },
};

export const userSlice = createSlice({
  name: USER,
  initialState: userInitialState,
  reducers: {
    requestUserAuthStatus: (user: UserStateType) => {
      user.auth.checked = false;
    },
    requestUserLogout: () => {},
    receiveUserAuthStatus: (
      user: UserStateType,
      { payload }: PayloadAction<UserAuthResponseType>
    ) => {
      user.auth.authenticated = true;
      user.auth.checked = true;
      user.auth.error = null;
      user.auth.id = payload.id;
      user.auth.name = payload.name;
      user.auth.email = payload.email;
      user.auth.roles = payload.roles;
      user.auth.workspace = payload.workspace;
    },
    receiveUserLogout: (user: UserStateType) => {
      user.auth = userInitialState.auth;
    },
    failedUserAuthStatus: (user: UserStateType, { payload: error }: PayloadAction<string>) => {
      user.auth.authenticated = false;
      user.auth.checked = true;
      user.auth.error = error;
    },
    failedUserLogout: () => {},
  },
});

const actions = userSlice.actions;
const userReducer = userSlice.reducer;

export { actions, userReducer };
