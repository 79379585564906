import type { PayloadAction } from '@reduxjs/toolkit';
import { type Directory } from '@southfields-digital/mpxlive-components';
import { Id } from 'react-toastify';
import { call, delay, put, takeEvery, takeLatest, all, select } from 'redux-saga/effects';
import type { AllEffect, CallEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { MEDIA_LIBRARY_FILES_PAGE_SIZE } from 'src/config/mediaLibrary';
import api from 'src/services/graphicsApi';

import { StateType } from '../reducers';
import {
  actions,
  FilesResponseType,
  DirectoriesResponseType,
  NOTIFY_UPLOADS_COMPLETED,
  REQUEST_CREATE_BULK_FILE_UPLOAD,
  REQUEST_CREATE_DIRECTORY,
  REQUEST_DELETE_DIRECTORY,
  REQUEST_DELETE_FILE,
  REQUEST_GET_DIRECTORIES,
  REQUEST_GET_FILES,
  REQUEST_UPLOAD_FILE_CHUNKS,
  REQUEST_UPDATE_DIRECTORY,
  REQUEST_SEARCH_DIRECTORIES,
  REQUEST_SEARCH_FILES,
  MEDIA_LIBRARY,
  BulkUpload,
} from '../reducers/mediaLibrary';
import { NOTIFY, UPDATE_NOTIFY } from '../reducers/notification';

function calculateUploadChunks(fileList: FileList, maxMemoryUsage: number): File[][] {
  const files = Array.from(fileList);
  const uploadChunks: File[][] = [];
  let chunkIndex = 0;

  for (let i = 0; i < files.length; i++) {
    const chunk = uploadChunks[chunkIndex] || [];
    const chunkSize = chunk.reduce((acc: number, file: File) => acc + file.size, 0);
    const file = files[i];

    if (chunkSize + file.size > maxMemoryUsage) {
      uploadChunks.push([file]);
      chunkIndex++;
    } else {
      if (uploadChunks[chunkIndex]) {
        uploadChunks[chunkIndex].push(file);
      } else {
        uploadChunks.push([file]);
      }
    }
  }

  return uploadChunks;
}

function getAsNestedDirectories(flatDirectories: Directory[]): Directory[] {
  const [currentDirectory, ...remainingDirectories] = flatDirectories;

  return remainingDirectories.length === 0
    ? [
        {
          ...currentDirectory,
          directories: [],
        },
      ]
    : [
        {
          ...currentDirectory,
          directories: getAsNestedDirectories(remainingDirectories),
        },
      ];
}

function readAsBinaryString(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => resolve(btoa(e?.target?.result as string));
    reader.onerror = (err) => reject(err);
    reader.readAsBinaryString(blob);
  });
}

function* createBulkFileUpload(
  action: PayloadAction<{
    directoryId: string;
    fileList: FileList;
    callback?: (uploadedFileCount: number) => void;
  }>
): Generator<
  AllEffect<CallEffect<void>> | CallEffect<File[][]> | CallEffect | PutEffect | SelectEffect,
  void,
  any
> {
  const bulkUploadId = uuidv4();
  const { directoryId, fileList, callback } = action.payload;
  const maxMemoryUsage = 1024 * 1024 * 4;
  let toastId;

  yield put({
    type: NOTIFY,
    getToastId: (id: Id) => (toastId = id),
    payload: {
      variant: 'info',
      title: 'Upload started',
      progress: 0,
    },
    toastOptions: {
      autoClose: false,
      closeButton: false,
    },
  });

  // Wait for toastId to be set
  while (!toastId) {
    yield delay(10);
  }

  yield put(
    actions.createBulkUpload({
      bulkUploadId,
      toastId,
      totalNumberOfFiles: action.payload.fileList.length,
    })
  );

  const uploadChunks: File[][] = yield call(calculateUploadChunks, fileList, maxMemoryUsage);

  for (let i = 0; i < uploadChunks.length; i++) {
    const uploadTasks = uploadChunks[i].map((file: any) =>
      call(createFileUpload, {
        bulkUploadId,
        directoryId,
        file,
      })
    );

    yield all(uploadTasks);
  }

  // Polling state to check if all uploads are completed
  let uploadCompleted = false;

  while (!uploadCompleted) {
    const { totalNumberOfFiles, completedUploads } = (yield select(
      (state: StateType) => state[MEDIA_LIBRARY].bulkUploads?.[bulkUploadId]
    )) as BulkUpload;

    // Update toast and notify backend of upload completion
    if (totalNumberOfFiles === completedUploads) {
      uploadCompleted = true;

      yield put({
        type: NOTIFY_UPLOADS_COMPLETED,
        payload: {
          bulkUploadId,
          callback,
        },
      });

      yield call(api.post, `/medialibrary/upload/complete`, {
        directoryId,
      });
    }

    // Wait a second before polling again
    yield delay(1000);
  }
}

function* createDirectory(
  action: PayloadAction<{ path: string; insertIntoDirectoryId: string; callback: () => void }>
) {
  try {
    const { path, insertIntoDirectoryId, callback } = action.payload;
    const { data } = yield call(api.post, `/medialibrary/directory`, { path });

    yield put(actions.receiveCreateDirectory({ data, insertIntoDirectoryId }));
    yield call(callback);
  } catch (error) {
    yield put(actions.failedCreateDirectory());
  }
}

function* createFileUpload({
  bulkUploadId,
  directoryId,
  file,
}: {
  bulkUploadId: string;
  directoryId: string;
  file: File;
}) {
  try {
    const { data } = yield call(api.post, `/medialibrary/upload`, {
      size: file.size,
      name: file.name,
      type: file.type,
      directoryId,
    });
    const { id, mediaObjectId } = data;

    yield put({
      type: REQUEST_UPLOAD_FILE_CHUNKS,
      payload: { id, bulkUploadId, directoryId, file, mediaObjectId },
    });
  } catch (error) {}
}

function* deleteDirectory(action: PayloadAction<{ id: string; callback: () => void }>) {
  try {
    const { id, callback } = action.payload;
    yield call(api.delete, `/medialibrary/directory/${id}`);

    yield put(actions.receiveDeleteDirectory({ id }));
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'success',
        title: 'Directory deleted successfully!',
      },
    });
    yield call(callback);
  } catch (error) {
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: 'Something went wrong while deleting the directory!',
      },
    });
  }
}

function* deleteFile(action: PayloadAction<{ id: string }>) {
  const { id } = action.payload;

  try {
    yield call(api.delete, `/medialibrary/file/${id}`);
    yield put(actions.receiveDeleteFile({ id }));
  } catch (error) {
    yield put(actions.failedDeleteFile({ id }));
  }
}

type MediaLibraryDirectoriesApiResponse = void | { data: DirectoriesResponseType; status: number };
function* getDirectories(
  action: PayloadAction<{
    id: string;
    insertIntoDirectoryId: string;
    includeNested: boolean;
    initialPath: Directory[];
    searchTerm?: string;
  }>
): Generator<PutEffect | CallEffect<any>, void, MediaLibraryDirectoriesApiResponse> {
  try {
    const { id, includeNested, initialPath } = action.payload;
    const queryParams: { [key: string]: string | number | boolean } = {
      ...(includeNested ? { includeNested: true } : {}),
    };
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&');

    const response = yield call(api.get, `/medialibrary/${id}/directories?${queryString}`);
    const apiResponse = response as MediaLibraryDirectoriesApiResponse;

    if (initialPath?.length > 1) {
      yield put(
        actions.setInitialDirectories({
          directories: getAsNestedDirectories(initialPath),
        })
      );
    }

    if (apiResponse?.data) {
      yield delay(500);
      yield put(
        actions.receiveGetDirectories({ data: apiResponse?.data, insertIntoDirectoryId: id })
      );
    }
  } catch (error) {
    yield put(
      actions.failedGetDirectories(
        ((error as Error)?.message as string) || 'Something went wrong loading directories!'
      )
    );
  }
}

type MediaLibraryFilesApiResponse = void | { data: FilesResponseType; status: number };
function* getFiles(
  action: PayloadAction<{
    id: string;
    pagination?: { offset: number; pageSize: number };
  }>
): Generator<PutEffect | CallEffect<any>, void, MediaLibraryFilesApiResponse> {
  try {
    const { id, pagination } = action.payload;
    const queryParams: { [key: string]: string | number | boolean } = {
      offset: pagination?.offset || 0,
      pageSize: pagination?.pageSize || MEDIA_LIBRARY_FILES_PAGE_SIZE,
    };
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&');
    const response = yield call(api.get, `/medialibrary/${id}/files?${queryString}`);
    const apiResponse = response as MediaLibraryFilesApiResponse;

    if (apiResponse?.data) {
      const shouldAppendResults = (pagination?.offset || 0) > 0;
      yield delay(500);
      yield put(actions.receiveGetFiles({ data: apiResponse.data, shouldAppendResults }));
    }
  } catch (error) {
    yield put(
      actions.failedGetFiles(
        ((error as Error)?.message as string) || 'Something went wrong loading media gallery!'
      )
    );
  }
}

function* notifyUploadsCompleted(
  action: PayloadAction<{ bulkUploadId: string; callback?: (uploadedFileCount: number) => void }>
) {
  const { bulkUploadId, callback } = action.payload;
  const { successfulUploads, toastId } = (yield select(
    (state: StateType) => state[MEDIA_LIBRARY].bulkUploads?.[bulkUploadId]
  )) as BulkUpload;

  yield delay(2500);
  yield put({
    type: UPDATE_NOTIFY,
    toastId,
    payload: {
      variant: 'success',
      title: 'Upload completed! Processing may take some time.',
      progress: 100,
    },
    toastOptions: {
      autoClose: 5000,
      closeButton: false,
    },
  });
  yield put(actions.deleteBulkUpload({ bulkUploadId }));

  if (callback) {
    yield call(callback, successfulUploads);
  }
}

type MediaLibrarySearchDirectoriesApiResponse = void | {
  data: DirectoriesResponseType;
  status: number;
};
function* searchDirectories(
  action: PayloadAction<{
    id: string;
    insertIntoDirectoryId: string;
    includeNested: boolean;
    initialPath: Directory[];
    searchTerm?: string;
  }>
): Generator<PutEffect | CallEffect<any>, void, MediaLibrarySearchDirectoriesApiResponse> {
  try {
    yield delay(500);
    const { id, includeNested, searchTerm } = action.payload;
    const queryParams: { [key: string]: string | number | boolean } = {
      ...(searchTerm ? { search: searchTerm } : {}),
      ...(includeNested ? { includeNested: true } : {}),
    };
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&');
    const response = yield call(api.get, `/medialibrary/${id}/directories?${queryString}`);
    const apiResponse = response as MediaLibraryDirectoriesApiResponse;

    if (apiResponse?.data) {
      yield delay(500);
      yield put(actions.receiveSearchDirectories({ data: apiResponse?.data }));
    }
  } catch (error) {
    yield put(
      actions.failedSearchDirectories(
        ((error as Error)?.message as string) || 'Something went wrong loading directories!'
      )
    );
  }
}

type MediaLibrarySearchFilesApiResponse = void | { data: FilesResponseType; status: number };
function* searchFiles(
  action: PayloadAction<{
    id: string;
    includeNested: boolean;
    pagination?: { offset: number; pageSize: number };
    searchTerm?: string;
  }>
): Generator<PutEffect | CallEffect<any>, void, MediaLibrarySearchFilesApiResponse> {
  try {
    yield delay(500);
    const { id, includeNested, pagination, searchTerm } = action.payload;
    const queryParams: { [key: string]: string | number | boolean } = {
      offset: pagination?.offset || 0,
      pageSize: pagination?.pageSize || MEDIA_LIBRARY_FILES_PAGE_SIZE,
      ...(searchTerm ? { search: searchTerm } : {}),
      ...(includeNested ? { includeNested: true } : {}),
    };
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&');
    const response = yield call(api.get, `/medialibrary/${id}/files?${queryString}`);
    const apiResponse = response as MediaLibrarySearchFilesApiResponse;

    if (apiResponse?.data) {
      const shouldAppendResults = (pagination?.offset || 0) > 0;
      yield delay(500);
      yield put(actions.receiveSearchFiles({ data: apiResponse.data, shouldAppendResults }));
    }
  } catch (error) {
    yield put(
      actions.failedSearchFiles(
        ((error as Error)?.message as string) || 'Something went wrong loading media gallery!'
      )
    );
  }
}

function* updateDirectory(
  action: PayloadAction<{ name: string; id: string; onSuccess: () => void; onFailure: () => void }>
) {
  const { name, id, onFailure, onSuccess } = action.payload;

  try {
    const { data } = yield call(api.patch, `/medialibrary/directory/${id}`, {
      name,
    });

    yield put(actions.receiveUpdateDirectory({ data }));

    if ('function' === typeof onSuccess) {
      yield call(onSuccess);
    }

    yield put({
      type: NOTIFY,
      payload: {
        variant: 'success',
        title: 'Directory renamed successfully! Processing may take some time.',
      },
    });
  } catch (error) {
    if ('function' === typeof onFailure) {
      yield call(onFailure);
    }

    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: 'Something went wrong while renaming the directory!',
      },
    });
  }
}

function* uploadFileChunks(
  action: PayloadAction<{
    id: string;
    bulkUploadId: string;
    directoryId: string;
    file: File;
    mediaObjectId: string;
  }>
) {
  const { id, bulkUploadId, file, directoryId, mediaObjectId } = action.payload;
  const chunkSize = 5 * 1024 * 1024;
  const totalSize = file.size;
  const totalChunks = Math.ceil(totalSize / chunkSize);
  const fileName = file.name;
  let attempt = 1;

  for (let chunkNumber = 0; chunkNumber < totalChunks; chunkNumber++) {
    attempt = 0;

    let uploadSuccess = false;

    try {
      const start = chunkNumber * chunkSize;
      const end = start + chunkSize;
      const sendBlob = file.slice(start, end);
      const sendChunk: string = (yield call(readAsBinaryString, sendBlob)) as string;

      yield call(api.patch, `/medialibrary/upload/${id}`, {
        chunk: chunkNumber,
        body: sendChunk,
        chunkNumber: chunkNumber + 1,
        directoryId,
        mediaObjectId,
        totalChunks,
      });

      uploadSuccess = true;
    } catch (error) {
      // // Max 5 retries
      if (attempt === 5) {
        yield put(actions.failedUploadFileChunks());

        yield put({
          type: REQUEST_DELETE_FILE,
          payload: {
            id: mediaObjectId,
          },
        });

        yield put({
          type: NOTIFY,
          payload: {
            variant: 'error',
            title: `Could not upload ${fileName}`,
          },
          toastOptions: {
            closeButton: false,
          },
        });

        // Reset attempts
        attempt = 1;

        return;
      } else {
        // Increment attempt and stay on same chunk
        ++attempt;
        --chunkNumber;

        // Exponential backoff on chunk retry
        yield delay(7 ** attempt);
        continue;
      }
    } finally {
      if (chunkNumber + 1 === totalChunks) {
        yield put(actions.incrementCompletedUploads({ bulkUploadId }));

        if (uploadSuccess) {
          yield put(actions.incrementSuccessfulUploads({ bulkUploadId }));
        }

        const { successfulUploads, toastId, totalNumberOfFiles, uploadProgress } = (yield select(
          (state: StateType) => state[MEDIA_LIBRARY].bulkUploads?.[bulkUploadId]
        )) as BulkUpload;

        // Set toast notification
        yield put({
          type: UPDATE_NOTIFY,
          toastId,
          payload: {
            variant: 'info',
            title: `Uploaded ${successfulUploads} of ${totalNumberOfFiles} files (${Math.round(
              uploadProgress
            )}%)`,
            progress: uploadProgress,
          },
          toastOptions: {
            autoClose: false,
            closeButton: false,
          },
        });

        // Reset attempts
        attempt = 1;
      }
    }
  }
}

export default function* root() {
  yield takeEvery(REQUEST_CREATE_BULK_FILE_UPLOAD, createBulkFileUpload);
  yield takeEvery(REQUEST_GET_DIRECTORIES, getDirectories);
  yield takeEvery(REQUEST_GET_FILES, getFiles);
  yield takeEvery(REQUEST_UPLOAD_FILE_CHUNKS, uploadFileChunks);
  yield takeLatest(NOTIFY_UPLOADS_COMPLETED, notifyUploadsCompleted);
  yield takeLatest(REQUEST_CREATE_DIRECTORY, createDirectory);
  yield takeLatest(REQUEST_DELETE_DIRECTORY, deleteDirectory);
  yield takeLatest(REQUEST_DELETE_FILE, deleteFile);
  yield takeLatest(REQUEST_SEARCH_DIRECTORIES, searchDirectories);
  yield takeLatest(REQUEST_SEARCH_FILES, searchFiles);
  yield takeLatest(REQUEST_UPDATE_DIRECTORY, updateDirectory);
}
