import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GRAPHIC, GraphicStateType, GraphicResponseType, GraphicsResponseType } from './types';

const graphicInitialState: GraphicStateType = {
  create: {
    data: null,
    submitting: false,
    error: null,
  },
  update: {
    data: null,
    submitting: false,
    error: null,
  },
  delete: {
    data: null,
    deleting: false,
    error: null,
  },
  get: {
    data: null,
    loading: true,
    error: null,
  },
  getAll: {
    data: null,
    selectedGraphic: null,
    loading: true,
    error: null,
  },
  allFromPackage: {
    data: null,
    selectedId: null,
    loading: true,
    error: null,
  },
  allFromRundown: {
    data: null,
    selectedId: null,
    loading: true,
    error: null,
  },
};

export const graphicSlice = createSlice({
  name: GRAPHIC,
  initialState: graphicInitialState,
  // Apply state changes
  reducers: {
    // CREATE
    requestCreateGraphic: (graphic: GraphicStateType) => {
      graphic.create.submitting = true;
    },
    receiveCreateGraphic: (graphic: GraphicStateType) => {
      graphic.create.submitting = false;
    },
    failedCreateGraphic: (graphic: GraphicStateType, { payload: error }: PayloadAction<string>) => {
      graphic.create.submitting = false;
      graphic.create.error = error;
    },
    // DELETE
    requestDeleteGraphic: (graphic: GraphicStateType) => {
      graphic.delete.deleting = true;
    },
    receiveDeleteGraphic: (graphic: GraphicStateType) => {
      graphic.delete.deleting = false;
    },
    failedDeleteGraphic: (graphic: GraphicStateType, { payload: error }: PayloadAction<string>) => {
      graphic.delete.deleting = false;
      graphic.delete.error = error;
    },
    // UPDATE
    requestUpdateGraphic: (graphic: GraphicStateType) => {
      graphic.update.submitting = true;
    },
    receiveUpdateGraphic: (graphic: GraphicStateType) => {
      graphic.update.submitting = false;
    },
    failedUpdateGraphic: (graphic: GraphicStateType, { payload: error }: PayloadAction<string>) => {
      graphic.update.submitting = false;
      graphic.update.error = error;
    },
    // GET
    requestGetGraphicById: (graphic: GraphicStateType) => {
      graphic.get.loading = true;
    },
    receiveGetGraphicById: (
      graphic: GraphicStateType,
      { payload }: PayloadAction<GraphicResponseType>
    ) => {
      graphic.get.loading = false;
      graphic.get.data = payload;
    },
    failedGetGraphicById: (
      graphic: GraphicStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      graphic.get.loading = false;
      graphic.get.error = error;
    },
    // GET ALL
    requestGetAllGraphicsByGraphicPackageId: (graphic: GraphicStateType) => {
      graphic.allFromPackage.loading = true;
    },
    receiveGetAllGraphicsByGraphicPackageId: (
      graphic: GraphicStateType,
      { payload }: PayloadAction<GraphicsResponseType>
    ) => {
      graphic.allFromPackage.loading = false;
      graphic.allFromPackage.data = payload;
      graphic.allFromPackage.selectedId = null;
    },
    failedGetAllGraphicsByGraphicPackageId: (
      graphic: GraphicStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      graphic.allFromPackage.loading = false;
      graphic.allFromPackage.error = error;
    },
    requestGetAllGraphicsByRundownId: (graphic: GraphicStateType) => {
      graphic.allFromRundown.loading = true;
    },
    receiveGetAllGraphicsByRundownId: (
      graphic: GraphicStateType,
      { payload }: PayloadAction<GraphicsResponseType>
    ) => {
      graphic.allFromRundown.loading = false;
      graphic.allFromRundown.data = payload;
      graphic.allFromRundown.selectedId = null;
    },
    failedGetAllGraphicsByRundownId: (
      graphic: GraphicStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      graphic.allFromRundown.loading = false;
      graphic.allFromRundown.error = error;
    },
    requestGetAllGraphics: (graphic: GraphicStateType) => {
      graphic.getAll.loading = true;
    },
    receiveGetAllGraphics: (
      graphic: GraphicStateType,
      { payload }: PayloadAction<GraphicsResponseType>
    ) => {
      graphic.getAll.loading = false;
      graphic.getAll.data = payload;
      graphic.getAll.selectedGraphic = null;
    },
    failedGetAllGraphics: (
      graphic: GraphicStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      graphic.getAll.loading = false;
      graphic.getAll.error = error;
    },
    setSelectedGraphic: (
      graphic: GraphicStateType,
      { payload: selectedGraphic }: PayloadAction<GraphicResponseType>
    ) => {
      graphic.getAll.selectedGraphic = selectedGraphic;
    },
  },
});

const actions = graphicSlice.actions;
const graphicReducer = graphicSlice.reducer;

export { actions, graphicReducer };
