import type { FallbackComponentProps } from 'src/components/ErrorBoundary';

const DefaultBoundary = ({ clearError }: FallbackComponentProps) => (
  <div className="w-full h-full flex flex-col justify-center items-center text-center text-white gap-4">
    <strong>Oops! Something Went Wrong</strong>
    <div className="flex flex-col gap-1">
      <p>Unfortunately, something seems to have gone wrong.</p>
      <p>
        We've sent a notification to our support team to investigate. You can try again by{' '}
        <button onClick={clearError} className="font-bold">
          clicking here.
        </button>
      </p>
    </div>
    <p>Thank you for your patience.</p>
  </div>
);

export default DefaultBoundary;
