import { Button, Text } from '@southfields-digital/mpxlive-components';
import { useDispatch } from 'react-redux';

import { REQUEST_HIDE_ALL_GRAPHICS } from 'src/redux/reducers/control';

import styles from './Header.module.scss';

export default function Header() {
  const dispatch = useDispatch();
  const hideAllActiveGraphics = () => {
    dispatch({ type: REQUEST_HIDE_ALL_GRAPHICS });
  };

  return (
    <div className={styles.Container}>
      <Text as="h4">On screen</Text>
      <Button
        className={styles.Button}
        buttonSize="small"
        variant="tertiary"
        onClick={hideAllActiveGraphics}
      >
        Hide all
      </Button>
    </div>
  );
}
