import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { CallEffect, PutEffect } from 'redux-saga/effects';

import api from 'src/services/graphicsApi';

import {
  actions,
  REQUEST_GET_GRAPHIC_PACKAGES,
  GraphicPackagesResponseType,
} from '../reducers/graphicPackage';

type RundownCalendarApiResponse = void | { data: GraphicPackagesResponseType; status: number };
function* getGraphicPackages(
  action: PayloadAction<{ deprecated: boolean }>
): Generator<PutEffect | CallEffect<RundownCalendarApiResponse>, void, RundownCalendarApiResponse> {
  try {
    const { deprecated } = action.payload;

    const endpoint = deprecated ? `/graphicpackages?filter[deprecated]=true` : `/graphicpackages`;
    const response = yield call(api.get, endpoint);
    const apiResponse = response as RundownCalendarApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveGetGraphicPackages(apiResponse.data));
    }
  } catch (error) {
    yield put(
      actions.failedGetGraphicPackages((error as Error)?.message as string) ||
        'Something went wrong'
    );
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_GRAPHIC_PACKAGES, getGraphicPackages);
}
