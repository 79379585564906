import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Graphic from 'src/components/Graphic';
import { StateType } from 'src/redux/reducers';
import { REQUEST_ANIMATE_OUT_LIVE_GRAPHIC } from 'src/redux/reducers/control';

import styles from './ActiveGraphics.module.scss';

export default function ActiveGraphics() {
  const dispatch = useDispatch();
  const { playlistGraphics } = useSelector((state: StateType) => state.playlistGraphic);
  const activeGraphics = useMemo(
    () => playlistGraphics?.filter((graphic) => graphic.status.live) || [],
    [playlistGraphics]
  );

  const hideLiveGraphic = (id: string) => {
    dispatch({ type: REQUEST_ANIMATE_OUT_LIVE_GRAPHIC, payload: { playlistGraphicId: id } });
  };

  if (!activeGraphics) return null;
  return (
    <div className={styles.Container}>
      <div className={styles.Wrapper}>
        {activeGraphics.map((graphic) => (
          <div className={styles.Graphic} key={graphic.id}>
            <Graphic
              variant="static"
              id={graphic.id}
              live
              onLive={() => hideLiveGraphic(graphic.id)}
              text={graphic.name}
              key={graphic.id}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
