import { canvasReducer, CANVAS, type CanvasStateType } from './canvas';
import { controlReducer, CONTROL, type ControlStateType } from './control';
import {
  dataProviderTranslationReducer,
  DATA_PROVIDER_TRANSLATION,
  type DataProviderTranslationStateType,
} from './dataProviderTranslation';
import { graphicReducer, GRAPHIC, type GraphicStateType } from './graphic';
import {
  graphicPackageReducer,
  GRAPHIC_PACKAGE,
  type GraphicPackageStateType,
} from './graphicPackage';
import { MATCH_CONTROLS, MatchControlsStateType, matchControlsReducer } from './matchControls';
import { MEDIA_LIBRARY, MediaLibraryStateType, mediaLibraryReducer } from './mediaLibrary';
import { NOTIFICATION, NotificationStateType, notificationReducer } from './notification';
import { OUTPUT, OutputStateType, outputReducer } from './output';
import { playlistReducer, PLAYLIST, type PlaylistStateType } from './playlist';
import {
  playlistGraphicReducer,
  PLAYLIST_GRAPHIC,
  type PlaylistGraphicStateType,
} from './playlistGraphic';
import { rundownReducer, RUNDOWN, type RundownStateType } from './rundown';
import {
  rundownTemplateReducer,
  RUNDOWN_TEMPLATE,
  type RundownTemplateStateType,
} from './rundownTemplate';
import { sportDataReducer, SPORT_DATA, type SportDataStateType } from './sportData';
import { userReducer, USER, type UserStateType } from './user';

export type StateType = {
  [CANVAS]: CanvasStateType;
  [CONTROL]: ControlStateType;
  [GRAPHIC]: GraphicStateType;
  [GRAPHIC_PACKAGE]: GraphicPackageStateType;
  [OUTPUT]: OutputStateType;
  [PLAYLIST]: PlaylistStateType;
  [PLAYLIST_GRAPHIC]: PlaylistGraphicStateType;
  [RUNDOWN]: RundownStateType;
  [RUNDOWN_TEMPLATE]: RundownTemplateStateType;
  [USER]: UserStateType;
  [MEDIA_LIBRARY]: MediaLibraryStateType;
  [NOTIFICATION]: NotificationStateType;
  [MATCH_CONTROLS]: MatchControlsStateType;
  [SPORT_DATA]: SportDataStateType;
  [DATA_PROVIDER_TRANSLATION]: DataProviderTranslationStateType;
};

export default {
  [CANVAS]: canvasReducer,
  [CONTROL]: controlReducer,
  [GRAPHIC]: graphicReducer,
  [GRAPHIC_PACKAGE]: graphicPackageReducer,
  [OUTPUT]: outputReducer,
  [PLAYLIST]: playlistReducer,
  [PLAYLIST_GRAPHIC]: playlistGraphicReducer,
  [RUNDOWN]: rundownReducer,
  [RUNDOWN_TEMPLATE]: rundownTemplateReducer,
  [USER]: userReducer,
  [MEDIA_LIBRARY]: mediaLibraryReducer,
  [NOTIFICATION]: notificationReducer,
  [MATCH_CONTROLS]: matchControlsReducer,
  [SPORT_DATA]: sportDataReducer,
  [DATA_PROVIDER_TRANSLATION]: dataProviderTranslationReducer,
};
