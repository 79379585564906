import {
  tailwindPreset,
  Button,
  Card,
  Input,
  Loader,
  SettingsForm,
  Text,
  Dialog,
  Icon,
} from '@southfields-digital/mpxlive-components';
import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import CopyInput from 'src/components/CopyInput';
import { BaseLayoutContext, BaseLayoutContextType } from 'src/layouts/BaseLayout';
import menuGroups from 'src/pages/Settings/menuGroups';
import { getOutputUrl } from 'src/utils';

import styles from './OutputOverviewPage.module.scss';

type OutputOverviewPageProps = {
  deleteOutput: (id: string) => void;
  getOutputs: () => void;
  loading: boolean;
  outputsData: Output[];
  submitting: boolean;
  updateOutput: (id: string, name: string, navigate?: NavigateFunction) => void;
};

const outputTypes: OutputType[] = [
  { type: 'pgm', label: 'PGM' },
  { type: 'preview', label: 'Preview' },
  { type: 'sandbox', label: 'Sandbox' },
];

export default function OutputOverviewPage({
  deleteOutput,
  getOutputs,
  loading,
  outputsData,
  submitting,
  updateOutput,
}: OutputOverviewPageProps) {
  const navigate = useNavigate();
  const [deletingOutput, setDeletingOutput] = useState<Output | null>(null);
  const [editingOutput, setEditingOutput] = useState<Output | null>(null);
  const { setWithContainer } = useContext<BaseLayoutContextType | null>(
    BaseLayoutContext
  ) as BaseLayoutContextType;

  useEffect(() => {
    const resetEditingOutputOnEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setEditingOutput(null);
      }
    };

    setWithContainer(true);
    getOutputs();

    document.addEventListener('keydown', resetEditingOutputOnEscape, true);

    return function unmount() {
      setWithContainer(false);

      document.removeEventListener('keydown', resetEditingOutputOnEscape, true);
    };
  }, []);

  const handleClickDelete = (output: Output) => {
    setDeletingOutput(output);
  };

  return (
    <>
      <SettingsForm
        title="Manage outputs"
        formSections={[
          {
            type: 'overview',
            onCreate: () => navigate('/settings/workspace/outputs/create'),
            onCreateLabel: 'Add output',
            overview: (
              <div className={styles.OutputOverview}>
                {(loading || submitting) && (
                  <div className={styles.LoaderWrapper}>
                    <Loader className={styles.Loader} />
                  </div>
                )}

                {outputsData?.map((output, index) => (
                  <Card
                    key={index}
                    className={styles.Output}
                    actionItems={[
                      {
                        as: 'button',
                        onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setEditingOutput(output);
                        },
                        value: 'edit',
                      },
                      {
                        as: 'button',
                        onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleClickDelete(output);
                        },
                        value: 'delete',
                      },
                    ]}
                    headerContent={
                      editingOutput?.id !== output.id ? (
                        <Text className={styles.OutputCardTitle} as="h3">
                          <span onClick={() => setEditingOutput(output)}>
                            {output.name}
                            {output.protected ? (
                              <span title="This is a protected output and will not be selectable by non admins">
                                <Icon icon="shield" />
                              </span>
                            ) : null}
                          </span>
                        </Text>
                      ) : (
                        <Input
                          className={styles.OutputCardTitle}
                          type="text"
                          value={editingOutput?.name}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setEditingOutput((prevOutput) => ({
                              ...(prevOutput as Output),
                              name: e.target.value as string,
                            }));
                          }}
                          placeholder="Pick a recognisable name for your output"
                          suffix={
                            <Button
                              className={styles.UpdateOutputButton}
                              iconProps={{
                                icon: 'check',
                                color: tailwindPreset.theme.extend.colors.primary,
                                weight: 'bold',
                              }}
                              onClick={() => {
                                if (editingOutput.name !== output.name) {
                                  updateOutput(output.id, editingOutput.name);
                                }
                                setEditingOutput(null);
                              }}
                              variant="tertiary"
                            />
                          }
                        />
                      )
                    }
                  >
                    <>
                      {outputTypes.map(({ type, label }, index) => {
                        const outputUrl = getOutputUrl(output, type);

                        return (
                          <div key={index} className={styles.OutputDetails}>
                            <div className={styles.OutputTypeWrapper}>
                              <div
                                className={classNames(styles.OutputType, {
                                  [styles.PGM]: type === 'pgm',
                                  [styles.Preview]: type === 'preview',
                                  [styles.Sandbox]: type === 'sandbox',
                                })}
                              >
                                {label}
                              </div>
                            </div>
                            <CopyInput value={outputUrl ?? ''} />
                          </div>
                        );
                      })}
                    </>
                  </Card>
                ))}
              </div>
            ),
          },
        ]}
        menuGroups={menuGroups}
      />
      <Dialog backdrop open={Boolean(deletingOutput)} onClose={() => setDeletingOutput(null)}>
        <Dialog.Panel className="flex flex-col z-50">
          <Dialog.Title>Deleting Output</Dialog.Title>
          <Text as="span">
            Are you sure you want to delete the output <strong>{deletingOutput?.name}</strong>?
          </Text>
          <div className="flex flex-row w-full gap-2 pt-4">
            <Button onClick={() => setDeletingOutput(null)} variant="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                deleteOutput(deletingOutput!.id);
                setDeletingOutput(null);
              }}
              variant="danger"
            >
              Delete
            </Button>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  );
}
