import { Input, Loader, SettingsForm } from '@southfields-digital/mpxlive-components';
import { useContext, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { BaseLayoutContext, BaseLayoutContextType } from 'src/layouts/BaseLayout';
import menuGroups from 'src/pages/Settings/menuGroups';

import styles from './OutputFormPage.module.scss';

type OutputFormPageProps = {
  createOutput: (name: string, isProtected: boolean, navigate: NavigateFunction) => void;
  submitting: boolean;
};

export default function OutputFormPage({ createOutput, submitting }: OutputFormPageProps) {
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [isProtected, setIsProtected] = useState<boolean>(false);
  const { setWithContainer } = useContext<BaseLayoutContextType | null>(
    BaseLayoutContext
  ) as BaseLayoutContextType;

  useEffect(() => {
    setWithContainer(true);

    return function unmount() {
      setWithContainer(false);
    };
  }, []);

  return (
    <SettingsForm
      title="Create output"
      formSections={[
        {
          actions: [
            {
              children: <>{submitting && <Loader className={styles.Spinner} />} Create output</>,
              className: styles.OutputFormSubmitButton,
              disabled: submitting || !name,
              onClick: () => createOutput(name, isProtected, navigate),
            },
          ],
          type: 'form',
          form: (
            <div className="flex flex-col gap-5">
              <Input
                type="text"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                placeholder="Pick a recognisable name for your output"
              />

              <Input
                type="checkbox"
                checked={isProtected}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setIsProtected(e.target.checked)
                }
                label="Protected"
              />
            </div>
          ),
        },
      ]}
      menuGroups={menuGroups}
    />
  );
}
