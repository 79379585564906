import { Button, Input, tailwindPreset } from '@southfields-digital/mpxlive-components';
import { useEffect, useRef, useState } from 'react';

import { copyToClipboard } from 'src/utils';

import styles from './CopyInput.module.scss';

type Props = {
  value: string;
};

export default function CopyInput({ value }: Props) {
  const [copied, setCopied] = useState(false);

  const copiedTimeout = useRef<number | null>(null);
  useEffect(() => {
    if (copiedTimeout.current) {
      clearTimeout(copiedTimeout.current);
    }

    copiedTimeout.current = window.setTimeout(() => setCopied(false), 2500);
    return () => {
      if (copiedTimeout.current) {
        clearTimeout(copiedTimeout.current);
      }
    };
  }, [copied]);

  return (
    <div className={styles.OutputUrl}>
      <Input
        classNameWrapper={styles.OutputUrlInput}
        type="text"
        readOnly
        suffix={
          <Button
            className={styles.CopyOutputUrlButton}
            disabled={copied}
            iconProps={
              !copied
                ? {
                    icon: 'clipboard',
                    weight: 'bold',
                    color: tailwindPreset.theme.extend.colors.white,
                  }
                : {
                    className: styles.Copied,
                    icon: 'check',
                    weight: 'bold',
                    color: tailwindPreset.theme.extend.colors.success,
                  }
            }
            onClick={() => {
              setCopied(true);
              copyToClipboard(value);
            }}
            variant="tertiary"
          />
        }
        value={value}
      />
    </div>
  );
}
