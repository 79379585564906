import Cell from './Cell';
import { Id, Items, Row } from './types';

import Repeater, { isRepeater } from '.';

type VerticalRepeaterRowItemProps = {
  currentItem: Row;
  rowIndex: number;
  handleChangeRepeater: (id: Id, rowIndex: number, rows: Items[]) => void;
  handleChangeValue: (id: Id, rowIndex: number, value: string) => void;
  handleSelectMedia?: (
    properties: ImageProperties,
    handleSelectValue?: (value: string) => void
  ) => void;
};

const VerticalRepeaterRowItem = ({
  currentItem,
  rowIndex,
  handleChangeRepeater,
  handleChangeValue,
  handleSelectMedia,
}: VerticalRepeaterRowItemProps) => (
  <td>
    {isRepeater(currentItem) ? (
      <Repeater
        parentRowIndex={rowIndex}
        onChangeRows={handleChangeRepeater}
        handleSelectMedia={handleSelectMedia}
        {...currentItem}
      />
    ) : (
      <Cell
        item={currentItem}
        rowIndex={rowIndex}
        handleChangeValue={handleChangeValue}
        handleSelectMedia={handleSelectMedia}
      />
    )}
  </td>
);

export default VerticalRepeaterRowItem;
