import { Button } from '@southfields-digital/mpxlive-components';
import { useNavigate } from 'react-router-dom';

import styles from './RundownLocked.module.scss';

interface IRundownLockedProps {
  data: CurrentUser;
  handle: () => void;
}

export default function RundownLocked({ data, handle }: IRundownLockedProps) {
  const navigate = useNavigate();

  return (
    <div className={styles.Container}>
      <div>
        Rundown is locked by {data.name} ({data.email})
      </div>
      <div className={styles.ActionContainer}>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          Back
        </Button>
        <Button variant="danger" onClick={handle}>
          Take Over
        </Button>
      </div>
    </div>
  );
}
