import { Transition } from '@headlessui/react';

import styles from './SlideOut.module.scss';

type Props = {
  open: boolean;
  handleClickOutsideDrawer: () => void;
  children?: React.ReactNode;
  size?: number;
};

const SlideOut = ({ children, handleClickOutsideDrawer, open, size = 75 }: Props) => (
  <Transition.Root show={open} className={styles.SlideOutContainer}>
    <Transition.Child
      className={styles.Backdrop}
      onClick={handleClickOutsideDrawer}
      as="div"
      leave="transition-opacity"
      leaveFrom="opacity-25"
      leaveTo="opacity-0"
    />

    <Transition.Child
      as="div"
      className={`h-full bg-elevated-1 overflow-auto ${open ? 'z-10' : '-z-10'}`}
      style={{ maxWidth: `${size}%`, width: `${size}%` }}
      enter="transition-all duration-100"
      enterFrom={`transform translate-x-[${size}%]`}
      enterTo="transform translate-x-0"
      leave="transition-all duration-100"
      leaveFrom="transform translate-x-0"
      leaveTo={`transform translate-x-[${size}%]`}
    >
      {children}
    </Transition.Child>
  </Transition.Root>
);

export default SlideOut;
