import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Directory,
  File as MediaLibraryFileType,
  Pagination,
} from '@southfields-digital/mpxlive-components';
import { Id } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import {
  FilesResponseType,
  MEDIA_LIBRARY,
  DirectoriesResponseType,
  MediaLibraryStateType,
  BulkUploadsType,
  BulkUpload,
} from './types';

const mediaLibraryInitialState = {
  directories: {
    data: {
      directories: [
        {
          id: 'root',
          name: 'Workspace',
          preventDeletion: true,
          directories: [],
        },
      ],
      locked: false,
    },
    searchData: {
      directories: [],
    },
    isLoading: true,
    error: null,
  },
  files: {
    data: null,
    searchData: null,
    isLoading: true,
    error: null,
  },
  bulkUploads: null,
};

export const mediaLibrarySlice = createSlice({
  name: MEDIA_LIBRARY,
  initialState: mediaLibraryInitialState,
  // Apply state changes
  reducers: {
    // Create directory
    requestCreateDirectory: () => {},
    receiveCreateDirectory: (
      library: MediaLibraryStateType,
      { payload }: PayloadAction<{ data: DirectoriesResponseType; insertIntoDirectoryId: string }>
    ) => {
      const findNewDirectoryInsertLocation = (data: Directory[]): Directory[] =>
        data?.map((directory: Directory) => ({
          ...directory,
          ...(payload.insertIntoDirectoryId === directory.id
            ? { directories: payload.data.directories }
            : directory.directories
              ? { directories: findNewDirectoryInsertLocation(directory.directories) }
              : {}),
        }));

      library.directories.data = {
        directories: findNewDirectoryInsertLocation(library.directories.data?.directories ?? []),
      };
    },
    failedCreateDirectory: () => {},
    // Update directory
    requestUpdateDirectory: () => {},
    receiveUpdateDirectory: (
      library: MediaLibraryStateType,
      { payload }: PayloadAction<{ data: { name: string; id: string } }>
    ) => {
      const updateDirectoryRecursively = (directories: Directory[]): Directory[] =>
        directories.map((directory: Directory) => {
          if (directory.id === payload.data.id) {
            return {
              ...directory,
              id: payload.data.id,
              name: payload.data.name,
            };
          } else if (directory.directories) {
            return {
              ...directory,
              directories: updateDirectoryRecursively(directory.directories),
            };
          } else {
            return directory;
          }
        });

      if (library.directories.data?.directories) {
        library.directories.data = {
          directories: updateDirectoryRecursively(library.directories.data.directories),
        };
      }
    },
    failedUpdateDirectory: () => {},
    // delete directory
    receiveDeleteDirectory: (
      library: MediaLibraryStateType,
      { payload }: PayloadAction<{ id: string }>
    ) => {
      const deleteDirectoryRecursively = (
        directories: Directory[],
        directoryId: string
      ): Directory[] =>
        directories.reduce((acc: Directory[], directory: Directory) => {
          if (directory.id === directoryId) {
            return acc;
          } else if (directory.directories) {
            return [
              ...acc,
              {
                ...directory,
                directories: deleteDirectoryRecursively(directory.directories, directoryId),
              },
            ];
          } else {
            return [...acc, directory];
          }
        }, []);

      if (library.directories.data?.directories) {
        library.directories.data = {
          directories: deleteDirectoryRecursively(library.directories.data.directories, payload.id),
        };
      }
    },

    // Get directories
    requestGetDirectories: (
      library: MediaLibraryStateType,
      { payload }: PayloadAction<{ hideLoader: boolean }>
    ) => {
      const { hideLoader = false } = payload;
      library.directories.isLoading = !hideLoader;
    },
    receiveGetDirectories: (
      library: MediaLibraryStateType,
      { payload }: PayloadAction<{ data: DirectoriesResponseType; insertIntoDirectoryId: string }>
    ) => {
      const findNewDirectoryInsertLocation = (data: Directory[]): Directory[] =>
        data?.map((directory: Directory) => ({
          ...directory,
          ...(payload.insertIntoDirectoryId === directory.id
            ? { directories: payload.data.directories }
            : directory.directories
              ? { directories: findNewDirectoryInsertLocation(directory.directories) }
              : {}),
        }));

      const directories = findNewDirectoryInsertLocation(
        library.directories.data?.directories ?? []
      );

      library.directories.data = {
        directories: directories,
        locked: payload.data.locked,
      };
      library.directories.isLoading = false;
    },
    failedGetDirectories: (
      library: MediaLibraryStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      library.directories.isLoading = false;
      library.directories.error = error;
    },
    // Search directories
    requestSearchDirectories: (
      library: MediaLibraryStateType,
      {
        payload,
      }: PayloadAction<{
        pagination?: Pagination;
        searchTerm?: string;
        shouldReset?: 'directories' | 'files' | 'both';
      }>
    ) => {
      const shouldReset =
        (payload.searchTerm && !payload.pagination?.offset) ||
        (payload?.shouldReset && ['both', 'directories'].includes(payload.shouldReset));

      if (shouldReset) {
        library.directories = {
          ...library.directories,
          searchData: mediaLibraryInitialState.directories.searchData,
        };
      }

      library.directories.isLoading = true;
    },
    receiveSearchDirectories: (
      library: MediaLibraryStateType,
      { payload }: PayloadAction<{ data: DirectoriesResponseType }>
    ) => {
      library.directories.searchData = {
        directories: payload.data.directories,
      };
      library.directories.isLoading = false;
    },
    failedSearchDirectories: (
      library: MediaLibraryStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      library.directories.isLoading = false;
      library.directories.error = error;
    },

    // Get files
    requestGetFiles: (
      library: MediaLibraryStateType,
      { payload }: PayloadAction<{ hideLoader: boolean }>
    ) => {
      const { hideLoader = false } = payload;
      library.files.isLoading = !hideLoader;
    },
    receiveGetFiles: (
      library: MediaLibraryStateType,
      { payload }: PayloadAction<{ data: FilesResponseType; shouldAppendResults: boolean }>
    ) => {
      const { count, files, pagination } = payload.data;

      if (!payload.shouldAppendResults) {
        library.files.data = payload.data;
      } else {
        // Prevent duplicates files from being reappended to files array
        const mergedFiles = [...(library?.files?.data?.files || []), ...files];
        const guaranteedUniqueFiles = mergedFiles.filter(
          (file, index, self) => self.map(({ id }) => id).indexOf(file.id) === index
        );

        library.files.data = {
          ...library.files.data,
          files: guaranteedUniqueFiles,
          pagination,
          count,
        };
      }

      library.files.isLoading = false;
    },
    failedGetFiles: (library: MediaLibraryStateType, { payload: error }: PayloadAction<string>) => {
      library.files.isLoading = false;
      library.files.error = error;
    },
    // Search files
    requestSearchFiles: (
      library: MediaLibraryStateType,
      {
        payload,
      }: PayloadAction<{
        pagination?: Pagination;
        searchTerm?: string;
        shouldReset?: 'directories' | 'files' | 'both';
      }>
    ) => {
      const shouldReset =
        (payload.searchTerm && !payload.pagination?.offset) ||
        (payload?.shouldReset && ['both', 'files'].includes(payload.shouldReset));

      if (shouldReset) {
        library.files = {
          ...library.files,
          searchData: mediaLibraryInitialState.files.searchData,
        };
      }

      library.files.isLoading = true;
    },
    receiveSearchFiles: (
      library: MediaLibraryStateType,
      { payload }: PayloadAction<{ data: FilesResponseType; shouldAppendResults: boolean }>
    ) => {
      const { count, files, pagination } = payload.data;

      library.files.searchData = !payload.shouldAppendResults
        ? payload.data
        : {
            ...library.files.searchData,
            files: [...(library?.files?.searchData?.files || []), ...files],
            pagination,
            count,
          };
      library.files.isLoading = false;
    },
    failedSearchFiles: (
      library: MediaLibraryStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      library.files.isLoading = false;
      library.files.error = error;
    },

    // Delete file
    requestDeleteFile: (library: MediaLibraryStateType, action: PayloadAction<{ id: string }>) => {
      library.files.data = {
        ...(library.files.data as FilesResponseType),
        files: library.files.data?.files.map((file) => ({
          ...file,
          isLoading: file.id === action.payload.id,
        })) as MediaLibraryFileType[],
      };
    },
    receiveDeleteFile: (library: MediaLibraryStateType, action: PayloadAction<{ id: string }>) => {
      library.files.data = {
        ...(library.files.data as FilesResponseType),
        files: library.files.data?.files.filter(
          (file) => file.id !== action.payload.id
        ) as MediaLibraryFileType[],
      };
    },
    failedDeleteFile: (library: MediaLibraryStateType, action: PayloadAction<{ id: string }>) => {
      library.files.data = {
        ...(library.files.data as FilesResponseType),
        files: library.files.data?.files.map((file) => ({
          ...file,
          isLoading: file.id === action.payload.id ? false : file.isLoading,
        })) as MediaLibraryFileType[],
      };
    },
    requestCreateBulkFileUpload: (
      library: MediaLibraryStateType,
      action: PayloadAction<{ fileList: FileList }>
    ) => {
      library.files.data = {
        files: [
          ...[...Array(action.payload.fileList.length)].map(() => ({
            id: uuidv4(),
            data: [],
            name: '',
            size: 0,
            isLoading: true,
            createdAt: '',
            updatedAt: '',
          })),
          ...(library?.files?.data?.files || []),
        ],
        pagination: {
          offset: -1,
          pageSize: -1,
        },
        count: library.files.data?.count,
      };
    },
    createBulkUpload: (
      library: MediaLibraryStateType,
      action: PayloadAction<{ bulkUploadId: string; toastId: Id; totalNumberOfFiles: number }>
    ) => {
      const { bulkUploadId, toastId, totalNumberOfFiles } = action.payload;

      library.bulkUploads = {
        ...library.bulkUploads,
        [bulkUploadId]: {
          completedUploads: 0,
          successfulUploads: 0,
          toastId,
          totalNumberOfFiles,
          uploadProgress: 0,
        },
      };
    },
    updateBulkUploadProgress: (
      library: MediaLibraryStateType,
      action: PayloadAction<{
        bulkUploadId: string;
        completedUploads: number;
        uploadProgress: number;
      }>
    ) => {
      const { bulkUploadId, completedUploads, uploadProgress } = action.payload;

      library.bulkUploads = {
        ...library.bulkUploads,
        [bulkUploadId]: {
          ...(library.bulkUploads?.[bulkUploadId] as BulkUpload),
          completedUploads,
          uploadProgress,
        },
      };
    },
    deleteBulkUpload: (
      library: MediaLibraryStateType,
      action: PayloadAction<{ bulkUploadId: string }>
    ) => {
      const { [action.payload.bulkUploadId]: _, ...rest } = library.bulkUploads as BulkUploadsType;

      library.bulkUploads = rest;
    },

    // Upload file chunks
    requestUploadFileChunks: () => {},
    receiveUploadFileChunks: () => {},
    failedUploadFileChunks: () => {},

    incrementCompletedUploads: (
      library: MediaLibraryStateType,
      action: PayloadAction<{ bulkUploadId: string }>
    ) => {
      const { bulkUploadId } = action.payload;
      const bulkUpload = library.bulkUploads?.[bulkUploadId] as BulkUpload;

      if (bulkUpload) {
        const completedUploads = bulkUpload.completedUploads + 1;

        library.bulkUploads = {
          ...(library.bulkUploads as BulkUploadsType),
          [bulkUploadId]: {
            ...bulkUpload,
            completedUploads,
            uploadProgress: (completedUploads / bulkUpload.totalNumberOfFiles) * 100,
          },
        };
      }
    },

    incrementSuccessfulUploads: (
      library: MediaLibraryStateType,
      action: PayloadAction<{ bulkUploadId: string }>
    ) => {
      const { bulkUploadId } = action.payload;
      const bulkUpload = library.bulkUploads?.[bulkUploadId] as BulkUpload;

      if (bulkUpload) {
        const successfulUploads = bulkUpload.successfulUploads + 1;

        library.bulkUploads = {
          ...(library.bulkUploads as BulkUploadsType),
          [bulkUploadId]: {
            ...bulkUpload,
            successfulUploads,
          },
        };
      }
    },

    // Set directories
    setInitialDirectories: (
      library: MediaLibraryStateType,
      { payload }: PayloadAction<DirectoriesResponseType>
    ) => {
      library.directories.data = payload;
    },

    // Reset files and directories
    requestResetFilesAndDirectories: (library: MediaLibraryStateType) => {
      library.files.isLoading = true;
      library.directories.isLoading = true;
      library.directories.data = null;
      library.files.data = null;
    },
  },
});

const actions = mediaLibrarySlice.actions;
const mediaLibraryReducer = mediaLibrarySlice.reducer;

export { actions, mediaLibraryReducer, mediaLibraryInitialState };
