import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { CallEffect, PutEffect } from 'redux-saga/effects';

import api from 'src/services/graphicsApi';

import { NOTIFY } from '../reducers/notification';
import {
  actions,
  REQUEST_CREATE_PLAYLIST,
  REQUEST_DELETE_PLAYLIST,
  REQUEST_UPDATE_PLAYLIST,
  REQUEST_GET_PLAYLIST_BY_ID,
  REQUEST_GET_ALL_PLAYLISTS_BY_RUNDOWN_ID,
  PlaylistResponseType,
  PlaylistsResponseType,
  REQUEST_REORDER_PLAYLISTS,
  REQUEST_DUPLICATE_PLAYLIST,
  REQUEST_SET_SELECTED_PLAYLIST,
  REQUEST_START_AUTO_PLAY_PLAYLIST,
  REQUEST_STOP_AUTO_PLAY_PLAYLIST,
} from '../reducers/playlist';
import { REQUEST_SET_SELECTED_PLAYLIST_GRAPHIC } from '../reducers/playlistGraphic';

function* createPlaylist(
  action: PayloadAction<{ rundownId: string; name: string }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const response = yield call(api.post, `/playlists`, action.payload);
    const apiResponse = response as PlaylistApiResponse;

    if (!apiResponse?.data) return;
    yield put(actions.receiveCreatePlaylist(apiResponse.data));
  } catch (error) {
    yield put(actions.failedCreatePlaylist('Something went wrong. Could not create playlist'));
  }
}

function* deletePlaylist(
  action: PayloadAction<{ id: string }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { id } = action.payload;
    const response = yield call(api.delete, `/playlists/${id}`);
    const apiResponse = response as PlaylistApiResponse;

    if (apiResponse?.status !== 200) return;
    yield put(actions.receiveDeletePlaylist({ playlistId: id }));
  } catch (error) {
    const apiError = error as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(actions.failedDeletePlaylist('Something went wrong. Could not delete playlist'));
  }
}

function* duplicatePlaylist(
  action: PayloadAction<{ id: string; rundownId: string }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { id, rundownId } = action.payload;
    yield call(api.post, `/playlists/${id}/duplicate`, { rundownId });
  } catch (error) {
    yield put(
      actions.failedDuplicatePlaylist('Something went wrong. Could not duplicate playlist')
    );
  }
}

function* updatePlaylist(
  action: PayloadAction<{
    id: string;
    name?: string;
    rundownId?: string;
    autoplay?: AutoPlay;
  }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { id, name, rundownId, autoplay } = action.payload;
    const response = yield call(api.patch, `/playlists/${id}`, { name, rundownId, autoplay });
    const apiResponse = response as PlaylistApiResponse;

    if (!apiResponse?.data) return;
    yield put(actions.receiveUpdatePlaylist(apiResponse.data));
  } catch (error) {
    yield put(actions.failedUpdatePlaylist('Something went wrong. Could not update playlist'));
  }
}

function* updateOrderPlaylists(
  action: PayloadAction<{ rundownId: string; playlistIds: string[] }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { rundownId, playlistIds } = action.payload;
    yield put(actions.receiveReorderPlaylists(playlistIds));
    yield call(api.patch, `/playlists/reorder`, {
      rundownId,
      playlists: playlistIds,
    });
  } catch (error) {
    yield put(actions.failedReorderPlaylists('Something went wrong. Could not reorder playlist'));
  }
}

type PlaylistsApiResponse = void | { data: PlaylistsResponseType; status: number };
function* getAllPlaylistsByRundownId(
  action: PayloadAction<{ rundownId: string; firstSelected?: boolean; resetSelected?: boolean }>
): Generator<PutEffect | CallEffect<PlaylistsApiResponse>, void, PlaylistsApiResponse> {
  try {
    const { rundownId, firstSelected = false, resetSelected = true } = action.payload;
    const response = yield call(api.get, `/rundowns/${rundownId}/playlists`);
    const apiResponse = response as PlaylistsApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveGetAllPlaylistsByRundownId(apiResponse.data));

      if (firstSelected && apiResponse.data.length) {
        const { id } = apiResponse.data[0];
        yield put({ type: REQUEST_SET_SELECTED_PLAYLIST, payload: { id } });
      } else if (resetSelected) {
        yield put({ type: REQUEST_SET_SELECTED_PLAYLIST, payload: { id: null } });
      }
    }
  } catch (error) {
    yield put(
      actions.failedGetAllPlaylistsByRundownId((error as Error)?.message as string) ||
        'Something went wrong getting all playlists'
    );
  }
}

type PlaylistApiResponse = void | { data: PlaylistResponseType; status: number };
function* getPlaylistById(
  action: PayloadAction<{ id: string }>
): Generator<PutEffect | CallEffect<PlaylistApiResponse>, void, PlaylistApiResponse> {
  try {
    const response = yield call(api.get, `/playlists/${action.payload.id}`);
    const apiResponse = response as PlaylistApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveGetPlaylistById(apiResponse.data));
    }
  } catch (error) {
    yield put(
      actions.failedGetPlaylistById((error as Error)?.message as string) ||
        'Something went wrong getting playlist'
    );
  }
}

function* setSelectedPlaylist(
  action: PayloadAction<{ id: string | null }>
): Generator<PutEffect, void, void> {
  try {
    const { id } = action.payload;
    yield put(actions.receiveSetSelectedPlaylist(id));
    if (!id) {
      yield put({
        type: REQUEST_SET_SELECTED_PLAYLIST_GRAPHIC,
        payload: { playlistGraphic: null },
      });
    }
  } catch (error) {
    yield put(actions.failedSetSelectedPlaylist());
  }
}

function* startAutoPlayPlaylist(
  action: PayloadAction<{ playlistId: string }>
): Generator<PutEffect | CallEffect, void, PlaylistApiResponse> {
  try {
    yield call(api.post, `/playlists/${action.payload.playlistId}/start`);
  } catch (error) {
    const apiError = error as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(actions.failedDeletePlaylist('Something went wrong. Could not start auto playlist'));
  }
}

function* stopAutoPlayPlaylist(
  action: PayloadAction<{ playlistId: string }>
): Generator<PutEffect | CallEffect, void, PlaylistApiResponse> {
  try {
    yield call(api.post, `/playlists/${action.payload.playlistId}/stop`);
  } catch (error) {
    const apiError = error as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(actions.failedDeletePlaylist('Something went wrong. Could not stop auto playlist'));
  }
}

export default function* root() {
  yield takeLatest(REQUEST_CREATE_PLAYLIST, createPlaylist);
  yield takeLatest(REQUEST_DELETE_PLAYLIST, deletePlaylist);
  yield takeLatest(REQUEST_DUPLICATE_PLAYLIST, duplicatePlaylist);
  yield takeLatest(REQUEST_UPDATE_PLAYLIST, updatePlaylist);
  yield takeLatest(REQUEST_GET_PLAYLIST_BY_ID, getPlaylistById);
  yield takeLatest(REQUEST_REORDER_PLAYLISTS, updateOrderPlaylists);
  yield takeLatest(REQUEST_GET_ALL_PLAYLISTS_BY_RUNDOWN_ID, getAllPlaylistsByRundownId);
  yield takeLatest(REQUEST_SET_SELECTED_PLAYLIST, setSelectedPlaylist);
  yield takeLatest(REQUEST_START_AUTO_PLAY_PLAYLIST, startAutoPlayPlaylist);
  yield takeLatest(REQUEST_STOP_AUTO_PLAY_PLAYLIST, stopAutoPlayPlaylist);
}
