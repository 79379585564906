import classNames from 'classnames';

import LivePulse from '../LivePulse';

import styles from './ViewWindow.module.scss';

interface IViewWindow {
  name: string;
  liveMode: boolean;
  src?: string;
}

const ViewWindow = ({ name, liveMode, src }: IViewWindow) => (
  <div className={styles.WindowWrapper}>
    <div className={styles.Window}>
      <div className={styles.Wrapper}>
        {src ? (
          <iframe className={styles.Frame} src={`${src}&checkerboard`} />
        ) : (
          <div className={styles.Frame}>Could not find output</div>
        )}
        <div
          className={`relative w-full flex justify-center items-center bg-elevated-2 text-center gap-2 ${
            liveMode && name === 'Live'
              ? classNames(styles.Row, styles.Live)
              : !liveMode && name === 'Sandbox'
                ? classNames(styles.Row, styles.Sandbox)
                : ''
          }`}
        >
          <span className={styles.Name}>{name}</span>
          {liveMode && name === 'Live' && <LivePulse />}
        </div>
      </div>
    </div>
  </div>
);

export default ViewWindow;
