import { IconNames } from '@southfields-digital/mpxlive-components/build/components/Icon';
import React from 'react';
import {
  ImperativePanelHandle,
  PanelProps,
  Panel as ReactResizablePanel,
} from 'react-resizable-panels';

import PanelContent from '../PanelContent';

interface IPanel extends PanelProps {
  header?: React.ReactNode;
  onClick?: () => void;
  icon?: IconNames;
  overflow?: boolean;
  canEdit?: boolean;
  children: React.ReactNode;
  className?: string;
}

export default React.forwardRef(
  (
    { header, icon, canEdit, onClick, children, overflow, className, ...rest }: IPanel,
    ref: React.ForwardedRef<ImperativePanelHandle>
  ) => (
    <ReactResizablePanel ref={ref} {...rest}>
      <PanelContent
        header={header}
        icon={icon}
        canEdit={canEdit}
        onClick={onClick}
        overflow={overflow}
        className={className}
      >
        {children}
      </PanelContent>
    </ReactResizablePanel>
  )
);
