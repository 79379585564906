import { tailwindPreset, Icon } from '@southfields-digital/mpxlive-components';
import classNames from 'classnames';

import { MappedCanvas } from '../types';
import {
  getAvailableOutputs,
  getUnavailableOutputs,
  getCanvasesWithoutOutput,
  reduceCanvasList,
  getLiveOutputs,
} from '../utils';

import styles from './StatusDescription.module.scss';

type Props = {
  rundown: Rundown;
  mappedCanvases: MappedCanvas[];
  isLive?: boolean;
};

export default function StatusDescription({ rundown, mappedCanvases, isLive }: Props) {
  // Canvases available to go live
  const availableOutputs = getAvailableOutputs(mappedCanvases);
  // Canvases unavailable to go live (in use by other rundown)
  const unavailableOutputs = getUnavailableOutputs(mappedCanvases, rundown.id);
  // Canvases currently live (in use by this rundown)
  const liveOutputs = getLiveOutputs(mappedCanvases, rundown.id);
  // Canvases without output configuration
  const canvasWithoutOutput = getCanvasesWithoutOutput(mappedCanvases);

  if (isLive && !unavailableOutputs.length && !availableOutputs.length) {
    return null;
  }

  return (
    <div className={styles.ContainerList}>
      {!mappedCanvases.length && (
        <div className={classNames(styles.Container, styles.Error)}>
          <div>
            <Icon icon="x-circle" color={tailwindPreset.theme.extend.colors.danger} />
          </div>
          <div>No canvases are configured. Please configure canvases to go live.</div>
        </div>
      )}
      {liveOutputs.length > 0 && (
        <div className={classNames(styles.Container, styles.Info)}>
          <div>
            <Icon icon="info" color={tailwindPreset.theme.extend.colors.white} />
          </div>
          <div>
            {liveOutputs.length > 1 ? 'Outputs' : 'Output'} {reduceCanvasList(liveOutputs)}{' '}
            {liveOutputs.length > 1 ? 'are' : 'is'} currently live
          </div>
        </div>
      )}
      {availableOutputs.length > 0 && (
        <div className={classNames(styles.Container, styles.Ready)}>
          <div>
            <Icon icon="check-circle" color={tailwindPreset.theme.extend.colors.success} />
          </div>
          <div>
            {availableOutputs.length > 1 ? 'Outputs' : 'Output'}{' '}
            {reduceCanvasList(availableOutputs)} {availableOutputs.length > 1 ? 'are' : 'is'} ready
          </div>
        </div>
      )}
      {unavailableOutputs.length > 0 && (
        <div className={classNames(styles.Container, styles.Error)}>
          <div>
            <Icon icon="x-circle" color={tailwindPreset.theme.extend.colors.danger} />
          </div>
          <div>
            {unavailableOutputs.length > 1 ? 'Outputs' : 'Output'}{' '}
            {reduceCanvasList(unavailableOutputs)} {unavailableOutputs.length > 1 ? 'are' : 'is'} in
            use
          </div>
        </div>
      )}
      {canvasWithoutOutput.length > 0 && (
        <div className={classNames(styles.Container, styles.Warning)}>
          <div>
            <Icon icon="x-circle" color={tailwindPreset.theme.extend.colors.warning} />
          </div>
          <div>
            {canvasWithoutOutput.length > 1 ? 'Canvases' : 'Canvas'}{' '}
            {reduceCanvasList(canvasWithoutOutput)} {canvasWithoutOutput.length > 1 ? 'are' : 'is'}{' '}
            not configured
          </div>
        </div>
      )}
      {!isLive && rundown.isLiveRundownLimitReached && (
        <div className={classNames(styles.Container, styles.Error)}>
          <div>
            <Icon icon="x-circle" color={tailwindPreset.theme.extend.colors.danger} />
          </div>
          <div>
            You have reached the limit of concurrent live rundowns. To go live, another rundown must
            go offline.
          </div>
        </div>
      )}
    </div>
  );
}
