import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  delay,
  takeLatest,
  CallEffect,
  PutEffect,
  select,
  SelectEffect,
} from 'redux-saga/effects';

import api from 'src/services/graphicsApi';

import { StateType } from '../reducers';
import { REQUEST_SHOW_GRAPHIC } from '../reducers/control';
import { NOTIFY } from '../reducers/notification';
import {
  actions,
  REQUEST_CREATE_PLAYLIST_GRAPHIC,
  REQUEST_DELETE_PLAYLIST_GRAPHIC,
  REQUEST_DUPLICATE_PLAYLIST_GRAPHIC,
  REQUEST_UPDATE_PLAYLIST_GRAPHIC,
  REQUEST_GET_ALL_PLAYLIST_GRAPHICS,
  REQUEST_REORDER_PLAYLIST_GRAPHIC,
  REQUEST_SET_SELECTED_PLAYLIST_GRAPHIC,
  REQUEST_UPDATE_PLAYLIST_GRAPHICS,
  PlaylistGraphicResponseType,
  PlaylistGraphicsResponseType,
  REQUEST_UPDATE_STATUS_PLAYLIST_GRAPHICS,
  REQUEST_CONNECT_PLAYLIST_GRAPHIC,
  REQUEST_TOGGLE_CONCEPT_PLAYLIST_GRAPHIC,
  REQUEST_ON_QUICKIFY_TOGGLE,
} from '../reducers/playlistGraphic';

type PlaylistGraphicApiResponse = void | { data: PlaylistGraphicResponseType; status: number };
type PlaylistGraphicsApiResponse = void | { data: PlaylistGraphicsResponseType; status: number };

function* createPlaylistGraphic(
  action: PayloadAction<{ graphicId: string; playlistId: string; name?: string }>
): Generator<CallEffect<PlaylistGraphicApiResponse> | PutEffect, void, void> {
  try {
    const { graphicId, playlistId } = action.payload;
    const response = yield call(api.post, `/playlists/${playlistId}/graphics`, {
      graphicId,
    });

    const apiResponse = response as PlaylistGraphicApiResponse;
    if (apiResponse?.data) {
      yield put(actions.receiveUpdatePlaylistGraphics([apiResponse.data]));
    }
  } catch (error) {
    yield put(
      actions.failedCreatePlaylistGraphic('Something went wrong. Could not add graphic to playlist')
    );
  }
}

function* deletePlaylistGraphic(
  action: PayloadAction<{ playlistId: string; playlistGraphicId: string }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { playlistId, playlistGraphicId } = action.payload;
    yield call(api.delete, `/playlists/${playlistId}/graphics/${playlistGraphicId}`);
    yield put(actions.receiveDeletePlaylistGraphic(playlistGraphicId));
  } catch (error) {
    const apiError = error as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
    yield put(actions.failedDeletePlaylistGraphic(apiError.message));
  }
}

function* duplicatePlaylistGraphic(
  action: PayloadAction<{ playlistId: string; playlistGraphicId: string }>
): Generator<CallEffect | PutEffect, void, PlaylistGraphicsApiResponse> {
  try {
    const { playlistId, playlistGraphicId } = action.payload;
    const response = yield call(
      api.post,
      `/playlists/${playlistId}/graphics/${playlistGraphicId}/duplicate`
    );

    if (response) {
      yield put(actions.receiveDuplicatePlaylistGraphic());

      const rundownId = response.data?.[0].playlist.rundown.id;
      if (rundownId) {
        yield put({
          type: REQUEST_GET_ALL_PLAYLIST_GRAPHICS,
          payload: { rundownId },
        });
      }
    }
  } catch (error) {
    yield put(
      actions.failedDuplicatePlaylistGraphic(
        'Something went wrong. Could not duplicate graphic from playlist'
      )
    );
  }
}

function* connectPlaylistGraphic(
  action: PayloadAction<{ playlistId: string; playlistGraphicId: string; parentId: string }>
): Generator<CallEffect | PutEffect, void, PlaylistGraphicApiResponse> {
  try {
    const { playlistId, playlistGraphicId, parentId } = action.payload;
    const response = yield call(
      api.post,
      `/playlists/${playlistId}/graphics/${playlistGraphicId}/connect`,
      {
        parentId,
      }
    );

    if (response) {
      yield put(actions.receiveUpdatePlaylistGraphics([response.data]));
    }
  } catch (error) {}
}

function* toggleConceptPlaylistGrapic(
  action: PayloadAction<{ playlistId: string; playlistGraphicId: string }>
): Generator<CallEffect | PutEffect, void, PlaylistGraphicApiResponse> {
  try {
    const { playlistId, playlistGraphicId } = action.payload;
    const response = yield call(
      api.post,
      `/playlists/${playlistId}/graphics/${playlistGraphicId}/toggleConcept`
    );

    if (response) {
      yield put(actions.receiveUpdatePlaylistGraphics([response.data]));
    }
  } catch (error) {}
}

function* updatePlaylistGraphic({
  payload,
}: PayloadAction<{
  graphic: PlaylistGraphic;
  updateFromDatabase?: boolean;
  callbackAction?: AnyAction;
  requestDelay?: number;
}>): Generator<CallEffect | PutEffect | SelectEffect, void, void> {
  try {
    const { graphic, updateFromDatabase = true, requestDelay = 0 } = payload;
    const { id, playlist, name, propertiesValues, isQuick } = graphic;
    yield delay(requestDelay);

    if (!updateFromDatabase) {
      yield put(actions.receiveUpdatePlaylistGraphic(graphic));
    }

    const response = yield call(api.patch, `/playlists/${playlist.id}/graphics/${id}`, {
      name,
      propertiesValues,
      isQuick,
    });

    const apiResponse = response as PlaylistGraphicApiResponse;
    if (updateFromDatabase && apiResponse) {
      yield put(actions.receiveUpdatePlaylistGraphic(apiResponse.data));
      if (payload.callbackAction) {
        yield put(payload.callbackAction);
      }
    }
  } catch (error) {
    yield put(
      actions.failedUpdatePlaylistGraphic('Something went wrong. Could not update graphic')
    );
  }
}

function* updatePlaylistGraphics(
  action: PayloadAction<PlaylistGraphic[]>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    yield put(actions.receiveUpdatePlaylistGraphics(action.payload));
  } catch (error) {
    yield put(
      actions.failedUpdatePlaylistGraphics('Something went wrong. Could not update graphics')
    );
  }
}

function* updateStatusPlaylistGraphics(
  action: PayloadAction<PlaylistGraphic[]>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    yield put(actions.receiveUpdateStatusPlaylistGraphics(action.payload));
  } catch (error) {
    yield put(
      actions.failedUpdateStatusPlaylistGraphics('Something went wrong. Could not update graphics')
    );
  }
}

function* getRundownPlaylistGraphics(
  action: PayloadAction<{ rundownId: string }>
): Generator<CallEffect | PutEffect, void, PlaylistGraphicsApiResponse> {
  try {
    const { rundownId } = action.payload;
    const response = yield call(api.get, `/rundowns/${rundownId}/playlistGraphics`);

    if (response?.data) {
      yield put(actions.receiveGetPlaylistGraphics(response.data));
    }
  } catch (error) {
    yield put(actions.failedGetPlaylistGraphics('Something went wrong getting playlist graphics'));
  }
}

function* updateOrderPlaylistGraphics(
  action: PayloadAction<{ playlistId: string; playlistGraphicIds: string[] }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { playlistId, playlistGraphicIds } = action.payload;
    yield put(actions.receiveReorderPlaylistGraphics(playlistGraphicIds));
    yield call(api.patch, `/playlists/${playlistId}/graphics/reorder`, {
      graphics: playlistGraphicIds,
    });
  } catch (error) {
    yield put(
      actions.failedReorderPlaylistGraphics('Something went wrong. Could not reorder graphics')
    );
  }
}

function* setSelectedPlaylistGraphic(
  action: PayloadAction<{
    playlistGraphic: PlaylistGraphic | null;
  }>
): Generator<CallEffect | PutEffect | SelectEffect> {
  try {
    const state = yield select((state: StateType) => state.playlistGraphic.selectedPlaylistGraphic);
    const selectedPlaylistGraphic = state as PlaylistGraphic | null;

    const { playlistGraphic } = action.payload;
    yield put(actions.receiveSetSelectedPlaylistGraphic(playlistGraphic));

    if (selectedPlaylistGraphic?.id === playlistGraphic?.id) return;
    if (playlistGraphic) {
      yield put({
        type: REQUEST_SHOW_GRAPHIC,
        payload: { playlistGraphicId: playlistGraphic.id },
      });
    }
  } catch (error) {
    yield put(
      actions.failedSetSelectedPlaylistGraphic('Something went wrong. Could not set graphic')
    );
  }
}

function* quickifyToggle(
  action: PayloadAction<{ graphic: PlaylistGraphic }>
): Generator<PutEffect | CallEffect, void, Graphic> {
  try {
    const { graphic } = action.payload;

    yield put({
      type: REQUEST_UPDATE_PLAYLIST_GRAPHIC,
      payload: {
        graphic: { ...graphic, isQuick: !graphic.isQuick },
      },
    });
  } catch (error) {
    const apiError = error as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'error',
        title: apiError.name,
        description: apiError.message,
      },
    });
  }
}

export default function* root() {
  yield takeLatest(REQUEST_CREATE_PLAYLIST_GRAPHIC, createPlaylistGraphic);
  yield takeLatest(REQUEST_DELETE_PLAYLIST_GRAPHIC, deletePlaylistGraphic);
  yield takeLatest(REQUEST_DUPLICATE_PLAYLIST_GRAPHIC, duplicatePlaylistGraphic);
  yield takeLatest(REQUEST_UPDATE_PLAYLIST_GRAPHIC, updatePlaylistGraphic);
  yield takeLatest(REQUEST_REORDER_PLAYLIST_GRAPHIC, updateOrderPlaylistGraphics);
  yield takeLatest(REQUEST_GET_ALL_PLAYLIST_GRAPHICS, getRundownPlaylistGraphics);
  yield takeLatest(REQUEST_SET_SELECTED_PLAYLIST_GRAPHIC, setSelectedPlaylistGraphic);
  yield takeLatest(REQUEST_UPDATE_PLAYLIST_GRAPHICS, updatePlaylistGraphics);
  yield takeLatest(REQUEST_UPDATE_STATUS_PLAYLIST_GRAPHICS, updateStatusPlaylistGraphics);
  yield takeLatest(REQUEST_CONNECT_PLAYLIST_GRAPHIC, connectPlaylistGraphic);
  yield takeLatest(REQUEST_TOGGLE_CONCEPT_PLAYLIST_GRAPHIC, toggleConceptPlaylistGrapic);
  yield takeLatest(REQUEST_ON_QUICKIFY_TOGGLE, quickifyToggle);
}
