import { Navigate, type NavigateProps } from 'react-router-dom';

import type { RouteMap } from 'src/components/Routes';
import MediaLibrary from 'src/containers/MediaLibrary';
import RundownForm from 'src/containers/Rundowns/Form';
import OutputForm from 'src/containers/Settings/Output/Form';
import OutputOverview from 'src/containers/Settings/Output/Overview';
import RundownTemplateForm from 'src/containers/Settings/RundownTemplates/Form';
import Clips from 'src/pages/Clips/Clips';
import Data from 'src/pages/Data/Data';
import Home from 'src/pages/Home';
import NotFound from 'src/pages/NotFound/NotFound';
import RundownCalendar from 'src/pages/RundownCalendar/RundownCalendar';
import RundownDetail from 'src/pages/RundownDetail/RundownDetail';
import RundownOverview from 'src/pages/Rundowns/Rundown/Overview';
import RundownTemplateOverview from 'src/pages/Settings/RundownTemplates/Overview';

// Nice to have: use "subRoutes"
export const routeMap: RouteMap = [
  {
    path: '/',
    auth: 'private',
    element: Home,
  },
  {
    path: '/rundown',
    auth: 'private',
    element: RundownCalendar,
    icon: 'calendar',
    position: 'sidebar',
  },
  {
    path: '/rundown/:rundownId',
    auth: 'private',
    element: RundownDetail,
  },
  {
    path: '/rundowns',
    auth: 'private',
    icon: 'queue',
    elementProps: { to: '/rundowns/overview' } as NavigateProps,
    position: 'sidebar',
    element: Navigate,
  },
  {
    path: '/medialibrary',
    auth: 'private',
    element: MediaLibrary,
    icon: 'media',
    position: 'sidebar',
  },
  {
    path: '/rundowns/overview',
    auth: 'private',
    element: RundownOverview,
  },
  {
    path: '/rundowns/create',
    auth: 'private',
    element: RundownForm,
  },
  {
    path: '/rundowns/edit/:rundownId',
    auth: 'private',
    element: RundownForm,
  },
  {
    path: '/settings',
    auth: 'private',
    element: Navigate,
    elementProps: { to: '/settings/templates' } as NavigateProps,
    icon: 'cog',
    position: 'sidebar',
  },
  {
    path: '/settings/templates',
    auth: 'private',
    element: RundownTemplateOverview,
  },
  {
    path: '/settings/templates/create',
    auth: 'private',
    element: RundownTemplateForm,
  },
  {
    path: '/settings/templates/edit/:rundownTemplateId',
    auth: 'private',
    element: RundownTemplateForm,
  },
  {
    path: '/settings/workspace/outputs',
    auth: 'private',
    element: OutputOverview,
    roles: ['superadmin', 'admin'],
  },
  {
    path: '/settings/workspace/outputs/create',
    auth: 'private',
    element: OutputForm,
    roles: ['superadmin', 'admin'],
  },
  {
    path: '/data',
    auth: 'private',
    element: Data,
  },
  {
    path: '/clips',
    auth: 'private',
    element: Clips,
  },
  {
    path: '*',
    auth: 'private',
    element: NotFound,
  },
];
