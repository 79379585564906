import { FormGroup, Input } from '@southfields-digital/mpxlive-components';
import { ChangeEvent } from 'react';

type SportDataInputProps = {
  fixtureId: string;
  onChange: (value: string) => void;
};

const SportDataInput = ({ fixtureId, onChange }: SportDataInputProps) => (
  <FormGroup label="Match Id" htmlFor="matchId">
    <Input
      id="matchId"
      value={fixtureId}
      placeholder="Statsperform Match Id"
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
    />
  </FormGroup>
);

export default SportDataInput;
