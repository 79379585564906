import { call, put, takeLatest } from 'redux-saga/effects';
import type { CallEffect, PutEffect } from 'redux-saga/effects';

import api from 'src/services/graphicsApi';

import { actions, REQUEST_GET_CANVAS_BACKGROUNDS, REQUEST_GET_CANVASES } from '../reducers/canvas';

type CanvasesApiResponse = void | { data: Canvas[]; status: number };
function* getCanvases(): Generator<
  PutEffect | CallEffect<CanvasesApiResponse>,
  void,
  CanvasesApiResponse
> {
  try {
    const response = yield call(api.get, `/canvases`);
    const apiResponse = response as CanvasesApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveGetCanvases(apiResponse.data));
    }
  } catch (error) {
    yield put(
      actions.failedGetCanvases((error as Error)?.message as string) || 'Something went wrong'
    );
  }
}

type CanvasBackgroundsApiResponse = void | { data: CanvasBackground[]; status: number };
function* getCanvasBackgrounds(): Generator<
  PutEffect | CallEffect<CanvasBackgroundsApiResponse>,
  void,
  CanvasBackgroundsApiResponse
> {
  try {
    const response = yield call(api.get, `/canvasbackgrounds`);
    if (response?.data) {
      yield put(actions.receiveGetCanvasBackgrounds(response.data));
    } else {
      yield put(actions.failedGetCanvasBackgrounds('No data found'));
    }
  } catch (error) {
    yield put(
      actions.failedGetCanvasBackgrounds((error as Error)?.message as string) ||
        'Something went wrong'
    );
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_CANVASES, getCanvases);
  yield takeLatest(REQUEST_GET_CANVAS_BACKGROUNDS, getCanvasBackgrounds);
}
