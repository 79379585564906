export type RundownCardBaseProps = {
  className?: string;
  handleClickCard: () => void;
  rundown: Rundown;
  wrapperClassName?: string;
};

export type RundownCardCompactProps = {
  type: RundownCardType.FULL;
  date: Date;
  handleClickDelete: () => void;
};

export type RundownCardFullProps = {
  type: RundownCardType.COMPACT;
  date?: never;
  handleClickDelete?: never;
};

export type RundownCardProps = RundownCardBaseProps &
  (RundownCardCompactProps | RundownCardFullProps);

export enum RundownCardType {
  COMPACT = 'rundown-card-compact',
  FULL = 'rundown-card-full',
}
