import Bugsnag from '@bugsnag/js';
import { Loader, PublicRoute, PrivateRoute } from '@southfields-digital/mpxlive-components';
import React, { useEffect, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes as RoutesWrapper, Route, useLocation, useParams, Params } from 'react-router-dom';

import BaseLayout from '../../layouts/BaseLayout';
import type { StateType } from '../../redux/reducers';
import { REQUEST_USER_AUTH_STATUS, UserAuthType } from '../../redux/reducers/user';

import type { AppRoute, RouteMap, ElementType } from './types';

const InsertURLParams = ({
  mergeProps,
  children,
}: {
  mergeProps: { [key: string]: unknown };
  children: (props: { [key: string]: unknown }) => React.ReactNode;
}) => {
  const params = useParams<Params<string>>();

  return children({ ...mergeProps, params });
};

const getRoutesFromMap = ({
  routeMap,
  authenticated,
  userRole,
}: {
  routeMap: RouteMap;
  authenticated: boolean;
  userRole?: string;
}): React.ReactNode[] | undefined =>
  routeMap?.map(
    (
      {
        subRoutes,
        element,
        elementProps = {},
        ...route
      }: {
        subRoutes?: RouteMap;
        element?: ElementType;
        elementProps?: { [key: string]: unknown };
      },
      index: number
    ) => {
      const Element = element || Fragment;
      const authType = (route as Pick<AppRoute, 'auth'>)?.auth;
      const getChildren = () =>
        'undefined' !== typeof subRoutes
          ? {
              children: getRoutesFromMap({
                routeMap: subRoutes,
                authenticated,
                userRole,
              }),
            }
          : {};

      const getElement = () => {
        switch (authType) {
          case 'public':
            return {
              ...(Element
                ? {
                    element: (
                      <PublicRoute authenticated={authenticated} key={index}>
                        <InsertURLParams mergeProps={elementProps}>
                          {(props) => <Element {...props} />}
                        </InsertURLParams>
                      </PublicRoute>
                    ),
                  }
                : {}),
            };
          case 'private':
            return {
              ...(Element
                ? {
                    element: (
                      <PrivateRoute
                        authenticated={authenticated}
                        key={index}
                        roles={(route as Record<string, string[]>).roles}
                        userRole={userRole}
                      >
                        <InsertURLParams mergeProps={elementProps}>
                          {(props) => <Element {...props} />}
                        </InsertURLParams>
                      </PrivateRoute>
                    ),
                  }
                : {}),
            };
          default:
            return {
              ...(Element
                ? {
                    element: (
                      <InsertURLParams mergeProps={elementProps}>
                        {(props) => <Element {...props} />}
                      </InsertURLParams>
                    ),
                  }
                : {}),
            };
        }
      };

      return <Route key={index} {...getChildren()} {...getElement()} {...route} />;
    }
  );

export default function Routes({ routeMap }: { routeMap: RouteMap }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const previousRoute: React.MutableRefObject<null | string> = useRef(null);
  const auth: UserAuthType = useSelector((state: StateType) => state.user.auth);

  useEffect(() => {
    if (location.pathname === '/login') {
      window.location.href = `${import.meta.env.VITE_SERVICE_AUTH_FRONTEND_URL}/login${
        location.search
      }`;
    } else {
      // Prevent rerenders on same route
      if (previousRoute.current !== location.pathname) {
        dispatch({ type: REQUEST_USER_AUTH_STATUS });
      }

      previousRoute.current = location.pathname;
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (auth.authenticated && auth.checked && auth.id) {
      const name = auth.workspace?.name ? `${auth.name} (${auth.workspace.name})` : auth.name;
      Bugsnag.setUser(auth.id, auth.email, name);
    }
  }, [auth.id]);

  if (!auth.checked) {
    return (
      <BaseLayout>
        <Loader centeredFullscreen />
      </BaseLayout>
    );
  }

  const children = getRoutesFromMap({
    routeMap,
    authenticated: auth.authenticated,
    userRole: auth.roles?.[0],
  });

  return (
    <RoutesWrapper>
      <Route element={<BaseLayout auth={auth} />}>{children}</Route>
    </RoutesWrapper>
  );
}
