import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import type { CallEffect, PutEffect, SelectEffect } from 'redux-saga/effects';

import api from 'src/services/graphicsApi';

import { StateType } from '../reducers';
import {
  actions,
  REQUEST_SHOW_GRAPHIC,
  REQUEST_ANIMATE_IN_LIVE_GRAPHIC,
  REQUEST_ANIMATE_OUT_LIVE_GRAPHIC,
  REQUEST_UPDATE_LIVE_GRAPHIC,
  REQUEST_UPDATE_PREVIEW_GRAPHIC,
  REQUEST_ANIMATE_OUT_PREVIEW_GRAPHIC,
  REQUEST_ANIMATE_IN_PREVIEW_GRAPHIC,
  REQUEST_HIDE_ALL_GRAPHICS,
} from '../reducers/control';
import { NOTIFY } from '../reducers/notification';
import {
  REQUEST_UPDATE_PLAYLIST_GRAPHICS,
  actions as playlistGraphicActions,
  REQUEST_UPDATE_PLAYLIST_GRAPHIC,
} from '../reducers/playlistGraphic';

type PlaylistGraphicApiResponse = void | { data: PlaylistGraphic[]; status: number };
function* showGraphic(
  action: PayloadAction<{ playlistGraphicId: string }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { playlistGraphicId } = action.payload;
    const response = yield call(api.post, `/controls/graphic/sandbox`, { playlistGraphicId });
    const apiResponse = response as PlaylistGraphicApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveShowGraphic());
    }
  } catch (error) {
    yield put(actions.failedShowGraphic('Something went wrong. Could not show graphic'));
  }
}

function* animateInLiveGraphic(
  action: PayloadAction<{ playlistGraphicId: string }>
): Generator<CallEffect | PutEffect | SelectEffect, void, void> {
  try {
    const { playlistGraphicId } = action.payload;
    const response = yield call(api.post, `/controls/graphic/live/in`, { playlistGraphicId });
    const apiResponse = response as PlaylistGraphicApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveAnimateInLiveGraphic());
      for (const graphic of apiResponse.data) {
        yield put(playlistGraphicActions.receiveUpdatePlaylistGraphic(graphic));
      }
    }
  } catch (error) {
    const apiError = error as Error;
    yield put({
      type: NOTIFY,
      payload: {
        variant: 'info',
        description: apiError.message,
      },
    });
    yield put(
      actions.failedAnimateInLiveGraphic('Something went wrong. Could not animate-in live graphic')
    );
  }
}

function* animateOutLiveGraphic(
  action: PayloadAction<{ playlistGraphicId: string }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { playlistGraphicId } = action.payload;
    const response = yield call(api.post, `/controls/graphic/live/out`, { playlistGraphicId });
    const apiResponse = response as PlaylistGraphicApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveAnimateOutLiveGraphic());
      for (const graphic of apiResponse.data) {
        yield put(playlistGraphicActions.receiveUpdatePlaylistGraphic(graphic));
      }
    }
  } catch (error) {
    yield put(
      actions.failedAnimateOutLiveGraphic('Something went wrong. Could not animate-out graphic')
    );
  }
}

function* updateLiveGraphic(
  action: PayloadAction<{
    playlistGraphicId: string;
    payload: 'swap:cut' | 'swap:fade' | 'tick:next' | 'tick:previous';
  }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { playlistGraphicId, payload } = action.payload;
    const response = yield call(api.post, `/controls/graphic/live/update`, {
      playlistGraphicId,
      action: payload,
    });
    const apiResponse = response as PlaylistGraphicApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveUpdateLiveGraphic());
      for (const graphic of apiResponse.data) {
        yield put({
          type: REQUEST_UPDATE_PLAYLIST_GRAPHIC,
          payload: { graphic, updateFromDatabase: false },
        });
      }
    }
  } catch (error) {
    yield put(
      actions.failedUpdateLiveGraphic('Something went wrong. Could not update live graphic')
    );
  }
}

function* animateInPreviewGraphic(
  action: PayloadAction<{ playlistGraphicId: string }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { playlistGraphicId } = action.payload;
    const response = yield call(api.post, `/controls/graphic/preview/in`, { playlistGraphicId });
    const apiResponse = response as PlaylistGraphicApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveAnimateInPreviewGraphic());
      for (const graphic of apiResponse.data) {
        yield put(playlistGraphicActions.receiveUpdatePlaylistGraphic(graphic));
      }
    }
  } catch (error) {
    yield put(
      actions.failedAnimateInPreviewGraphic(
        'Something went wrong. Could not animate-in preview graphic'
      )
    );
  }
}

function* animateOutPreviewGraphic(
  action: PayloadAction<{ playlistGraphicId: string }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { playlistGraphicId } = action.payload;
    const response = yield call(api.post, `/controls/graphic/preview/out`, { playlistGraphicId });
    const apiResponse = response as PlaylistGraphicApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveAnimateOutPreviewGraphic());
      for (const graphic of apiResponse.data) {
        yield put(playlistGraphicActions.receiveUpdatePlaylistGraphic(graphic));
      }
    }
  } catch (error) {
    yield put(
      actions.failedAnimateOutPreviewGraphic(
        'Something went wrong. Could not animate-out preview graphic'
      )
    );
  }
}

function* updatePreviewGraphic(
  action: PayloadAction<{
    playlistGraphicId: string;
    payload: 'swap:cut' | 'swap:fade' | 'tick:next' | 'tick:previous';
  }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { playlistGraphicId, payload } = action.payload;
    const response = yield call(api.post, `/controls/graphic/preview/update`, {
      playlistGraphicId,
      action: payload,
    });
    const apiResponse = response as PlaylistGraphicApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveUpdatePreviewGraphic());
      for (const graphic of apiResponse.data) {
        yield put(playlistGraphicActions.receiveUpdatePlaylistGraphic(graphic));
      }
    }
  } catch (error) {
    yield put(
      actions.failedUpdatePreviewGraphic('Something went wrong. Could not update preview graphic')
    );
  }
}

type HideAllGraphicsResponse = void | {
  data: PlaylistGraphic[];
  status: number;
};
function* hideAllActiveGraphics(): Generator<CallEffect | PutEffect | SelectEffect> {
  try {
    const state = yield select((state: StateType) => state.rundown.detail.data);
    const rundown = state as Rundown;

    const response = yield call(api.post, `/controls/rundown/${rundown.id}/allOut`);
    const apiResponse = response as HideAllGraphicsResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveHideAllGraphics());
      // replace all playlist graphics in playlist graphics state
      yield put({ type: REQUEST_UPDATE_PLAYLIST_GRAPHICS, payload: apiResponse.data });
    }
  } catch (error) {}
}

export default function* root() {
  yield takeLatest(REQUEST_SHOW_GRAPHIC, showGraphic);

  yield takeLatest(REQUEST_ANIMATE_IN_LIVE_GRAPHIC, animateInLiveGraphic);
  yield takeLatest(REQUEST_ANIMATE_OUT_LIVE_GRAPHIC, animateOutLiveGraphic);
  yield takeLatest(REQUEST_UPDATE_LIVE_GRAPHIC, updateLiveGraphic);

  yield takeLatest(REQUEST_ANIMATE_IN_PREVIEW_GRAPHIC, animateInPreviewGraphic);
  yield takeLatest(REQUEST_ANIMATE_OUT_PREVIEW_GRAPHIC, animateOutPreviewGraphic);
  yield takeLatest(REQUEST_UPDATE_PREVIEW_GRAPHIC, updatePreviewGraphic);

  yield takeLatest(REQUEST_HIDE_ALL_GRAPHICS, hideAllActiveGraphics);
}
