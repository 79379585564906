import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StateType } from 'src/redux/reducers';
import { REQUEST_GET_SPORT_DATA_MATCH_STATS } from 'src/redux/reducers/sportData';

import SportDataInput from '../SportDataInput';

import styles from './MatchStats.module.scss';
import StatRow from './StatRow';
import { getCards, getMatchStatsFromStat2 } from './utils';

type Props = {
  handleImportData: (value: string) => void;
};

export default function MatchStats({ handleImportData }: Props) {
  const dispatch = useDispatch();
  const { matchData } = useSelector((state: StateType) => state.sportData);
  const [fixtureId, setFixtureId] = useState<string>(matchData.data?.fixtureId ?? '');

  useEffect(() => {
    if (!fixtureId) return;
    dispatch({
      type: REQUEST_GET_SPORT_DATA_MATCH_STATS,
      payload: { fx: fixtureId, detailed: 'yes' },
    });
  }, [fixtureId]);

  const handleClickImport = (value: string) => {
    handleImportData(value);
  };

  if (!matchData.data?.fixtureData?.liveData) {
    return <SportDataInput fixtureId={fixtureId} onChange={(value) => setFixtureId(value)} />;
  }

  const {
    matchInfo,
    liveData: { lineUp, card },
  } = matchData.data.fixtureData;

  const [homeTeam, awayTeam] = matchInfo.contestant;
  const [homeLineUp, awayLineUp] = lineUp || [];

  const [yellowCardsHome, redCardsHome] = getCards(card, homeTeam.id);
  const [yellowCardsAway, redCardsAway] = getCards(card, awayTeam.id);

  const homeStats = getMatchStatsFromStat2(homeLineUp?.stat);
  const awayStats = getMatchStatsFromStat2(awayLineUp?.stat);

  return (
    <div className={styles.Container}>
      <SportDataInput fixtureId={fixtureId} onChange={(value) => setFixtureId(value)} />
      <div className={styles.Content}>
        <StatRow label="" homeValue={homeTeam.officialName} awayValue={awayTeam.officialName} />
        <StatRow
          label="Goals"
          awayValue={awayStats.goals}
          homeValue={homeStats.goals}
          onClickImport={handleClickImport}
        />
        <StatRow
          label="Possession"
          suffix="%"
          homeValue={Math.round(Number(homeStats.possessionPercentage))}
          awayValue={Math.round(Number(awayStats.possessionPercentage))}
          onClickImport={handleClickImport}
        />
        <StatRow
          label="Attempts"
          homeValue={homeStats.totalScoringAtt}
          awayValue={awayStats.totalScoringAtt}
          onClickImport={handleClickImport}
        />
        <StatRow
          label="On goal"
          homeValue={homeStats.ontargetScoringAtt}
          awayValue={awayStats.ontargetScoringAtt}
          onClickImport={handleClickImport}
        />
        <StatRow
          label="Corners"
          homeValue={homeStats.cornerTaken}
          awayValue={awayStats.cornerTaken}
          onClickImport={handleClickImport}
        />
        <StatRow
          label="Yellow cards"
          homeValue={yellowCardsHome}
          awayValue={yellowCardsAway}
          onClickImport={handleClickImport}
        />

        <StatRow
          label="Red cards"
          homeValue={redCardsHome}
          awayValue={redCardsAway}
          onClickImport={handleClickImport}
        />
      </div>
    </div>
  );
}
