import { FormGroup, Input } from '@southfields-digital/mpxlive-components';
import React, { useEffect, useCallback, useState } from 'react';

import Select, { Option } from 'src/components/Select';
import { getISODateTimeString, toDatetimeLocal } from 'src/utils';

import styles from './RundownForm.module.scss';

interface IRundownFormProps {
  state: Partial<Rundown>;
  graphicsPackages: Option[];
  defaultDate?: string | null;
  stateUpdated: (state: Partial<Rundown>) => void;
}

export default function RundownForm({
  state,
  graphicsPackages,
  defaultDate,
  stateUpdated,
}: IRundownFormProps) {
  const { date: localDate, time: localTime } = state.start
    ? toDatetimeLocal(state.start)
    : { date: toDatetimeLocal(defaultDate ?? '').date, time: '' };

  const [date, setDate] = useState<string>(localDate);
  const [time, setTime] = useState<string>(localTime);

  useEffect(() => {
    const newDate = `${date}T${time}`;
    const start = Date.parse(newDate) ? getISODateTimeString(newDate) : undefined;
    stateUpdated({ start });
  }, [date, time]);

  useEffect(() => {
    if (state.isPersistent) {
      stateUpdated({ start: '1970-01-01T00:00:00Z' });
    }
  }, [state.isPersistent, stateUpdated]);

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      stateUpdated({ name: e.currentTarget.value });
    },
    [stateUpdated]
  );

  const handleGraphicsPackageChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      stateUpdated({
        canvases: [],
        graphicPackageId: e.currentTarget.value,
      });
    },
    [stateUpdated]
  );

  const graphicPackageNote = state.id
    ? 'The graphics package cannot be changed. Create a new rundown to select a different graphics package.'
    : 'After creating a rundown, the graphics package will be permanent and cannot be changed.';

  return (
    <div className={styles.RundownForm}>
      <FormGroup label="Rundown Name" htmlFor="rundownName">
        <Input id="rundownName" value={state.name || ''} onChange={handleNameChange} />
      </FormGroup>

      {!state.isPersistent && (
        <>
          <FormGroup label="Date" htmlFor="rundownDate">
            <Input
              id="rundownDate"
              type="date"
              value={date}
              onChange={(e: React.SyntheticEvent<HTMLInputElement>) =>
                setDate(e.currentTarget.value)
              }
            />
          </FormGroup>

          <FormGroup label="Start Time" htmlFor="rundownTime">
            <Input
              id="rundownTime"
              type="time"
              value={time}
              step={1}
              onChange={(e: React.SyntheticEvent<HTMLInputElement>) =>
                setTime(e.currentTarget.value)
              }
            />
          </FormGroup>
        </>
      )}

      <FormGroup label="Graphics Package" htmlFor="graphicsPackage">
        <Select
          id="graphicsPackage"
          disabled={Boolean(state.id)}
          value={state.graphicPackageId || ''}
          options={[{ label: '-', value: '' }, ...graphicsPackages]}
          placeholder="Pick a graphics package"
          onChange={handleGraphicsPackageChange}
        />
        <p className={styles.RundownGraphicPackageNote}>{graphicPackageNote}</p>
      </FormGroup>
    </div>
  );
}
