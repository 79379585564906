import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { CallEffect, PutEffect } from 'redux-saga/effects';

import api from 'src/services/graphicsApi';

import {
  actions,
  REQUEST_CREATE_GRAPHIC,
  REQUEST_DELETE_GRAPHIC,
  REQUEST_UPDATE_GRAPHIC,
  REQUEST_GET_GRAPHIC_BY_ID,
  REQUEST_GET_ALL_GRAPHICS_BY_GRAPHICS_PACKAGE_ID,
  REQUEST_GET_ALL_GRAPHICS_BY_RUNDOWN_ID,
  GraphicResponseType,
  GraphicsResponseType,
} from '../reducers/graphic';

function* createGraphic(
  action: PayloadAction<Graphic>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    yield call(api.post, `/graphics`, {
      ...action.payload,
    });
    yield put(actions.receiveCreateGraphic());
  } catch (error) {
    yield put(actions.failedCreateGraphic('Something went wrong. Could not create graphic'));
  }
}

function* deleteGraphic(
  action: PayloadAction<{ id: string }>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { id } = action.payload;
    yield call(api.delete, `/graphics/${id}`);
    yield put(actions.receiveDeleteGraphic());
  } catch (error) {
    yield put(actions.failedDeleteGraphic('Something went wrong. Could not delete graphic'));
  }
}

function* updateGraphic(
  action: PayloadAction<{ id: string } & Partial<Graphic>>
): Generator<CallEffect | PutEffect, void, void> {
  try {
    const { id, ...rest } = action.payload;
    yield call(api.patch, `/graphics/${id}`, { ...rest });
    yield put(actions.receiveUpdateGraphic());
  } catch (error) {
    yield put(actions.failedUpdateGraphic('Something went wrong. Could not update graphic'));
  }
}

type PlaylistApiResponse = void | { data: GraphicResponseType; status: number };
function* getGraphicById(
  action: PayloadAction<{ id: string }>
): Generator<PutEffect | CallEffect<PlaylistApiResponse>, void, PlaylistApiResponse> {
  try {
    const response = yield call(api.get, `/graphics/${action.payload.id}`);
    const apiResponse = response as PlaylistApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveGetGraphicById(apiResponse.data));
    }
  } catch (error) {
    yield put(
      actions.failedGetGraphicById((error as Error)?.message as string) ||
        'Something went wrong getting graphic'
    );
  }
}

type PlaylistsApiResponse = void | { data: GraphicsResponseType; status: number };
function* getAllGraphicsByGraphicPackageId({
  payload,
}: PayloadAction<{ graphicPackageId: string }>): Generator<
  PutEffect | CallEffect<PlaylistsApiResponse>,
  void,
  PlaylistsApiResponse
> {
  try {
    const { graphicPackageId } = payload;
    const response = yield call(api.get, `/graphicpackages/${graphicPackageId}/graphics`);
    const apiResponse = response as PlaylistsApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveGetAllGraphicsByGraphicPackageId(apiResponse.data));
    }
  } catch (error) {
    yield put(
      actions.failedGetAllGraphicsByGraphicPackageId((error as Error)?.message as string) ||
        'Something went wrong getting all graphics by graphic package'
    );
  }
}

function* getAllGraphicsByRundownId({
  payload,
}: PayloadAction<{ rundownId: string }>): Generator<
  PutEffect | CallEffect<PlaylistsApiResponse>,
  void,
  PlaylistsApiResponse
> {
  try {
    const { rundownId } = payload;
    const response = yield call(api.get, `/rundowns/${rundownId}/graphics?filter[canvas]=true`);
    const apiResponse = response as PlaylistsApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveGetAllGraphicsByRundownId(apiResponse.data));
    }
  } catch (error) {
    yield put(
      actions.failedGetAllGraphicsByRundownId((error as Error)?.message as string) ||
        'Something went wrong getting all graphics by rundown'
    );
  }
}

export default function* root() {
  yield takeLatest(REQUEST_CREATE_GRAPHIC, createGraphic);
  yield takeLatest(REQUEST_DELETE_GRAPHIC, deleteGraphic);
  yield takeLatest(REQUEST_UPDATE_GRAPHIC, updateGraphic);
  yield takeLatest(REQUEST_GET_GRAPHIC_BY_ID, getGraphicById);
  yield takeLatest(
    REQUEST_GET_ALL_GRAPHICS_BY_GRAPHICS_PACKAGE_ID,
    getAllGraphicsByGraphicPackageId
  );
  yield takeLatest(REQUEST_GET_ALL_GRAPHICS_BY_RUNDOWN_ID, getAllGraphicsByRundownId);
}
