import { FormGroup, Input } from '@southfields-digital/mpxlive-components';
import { useState } from 'react';

import Select from 'src/components/Select';
import { ClockCountDirection, Clock as ClockType } from 'src/types/matchControl/clock';

type Props = {
  clock: ClockType;
  handleChangeClock: (clock: ClockType) => void;
};

const MainClock = ({ clock, handleChangeClock }: Props) => {
  const { clockBaseTime, clockCountDirection } = clock;
  const timeMinutes = Math.floor(clockBaseTime / 60000);
  const timeSeconds = Math.floor((clockBaseTime % 60000) / 1000);

  const [localTimeMinutes, setLocalTimeMinutes] = useState(timeMinutes);
  const [localTimeSeconds, setLocalTimeSeconds] = useState(timeSeconds);

  const handleSecondsInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = parseInt(e.currentTarget.value.replace(/[^0-9.]/g, ''));
    setLocalTimeSeconds(time);

    const clockBaseTime = (time || 0) * 1000 + (timeMinutes || 0) * 60000;
    if (clockBaseTime < 0) return;

    handleChangeClock({
      ...clock,
      clockBaseTime,
    });
  };

  const handleMinutesInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = parseInt(e.currentTarget.value.replace(/[^0-9.]/g, ''));
    setLocalTimeMinutes(time);

    const clockBaseTime = (time || 0) * 60000 + (timeSeconds || 0) * 1000;
    if (clockBaseTime < 0) return;

    handleChangeClock({
      ...clock,
      clockBaseTime,
    });
  };

  return (
    <div className="grid grid-cols-3 gap-2">
      <FormGroup label="[min] Main clock" htmlFor="mainClockMin">
        <Input
          type="number"
          id="mainClockMin"
          value={localTimeMinutes}
          onChange={handleMinutesInputChange}
        />
      </FormGroup>
      <FormGroup label="[sec] Main clock" htmlFor="mainClockSec">
        <Input
          type="number"
          id="mainClockSec"
          maxLength={2}
          value={localTimeSeconds}
          onChange={handleSecondsInputChange}
        />
      </FormGroup>
      <FormGroup label="Clock direction" htmlFor="mainClockDir">
        <Select
          id="mainClockDir"
          value={clockCountDirection}
          options={
            [
              { value: 'down', label: 'Down' },
              { value: 'up', label: 'Up' },
            ] satisfies { value: ClockCountDirection; label: string }[]
          }
          onChange={(e) =>
            handleChangeClock({
              ...clock,
              clockCountDirection: e.currentTarget.value as ClockCountDirection,
            })
          }
        />
      </FormGroup>
    </div>
  );
};

const ShotClock = ({ clock, handleChangeClock }: Props) => {
  const { clockBaseTime, clockCountDirection } = clock;
  const timeMinutes = Math.floor(clockBaseTime / 60000);
  const timeSeconds = Math.floor((clockBaseTime % 60000) / 1000);

  const [localTimeMinutes, setLocalTimeMinutes] = useState(timeMinutes);
  const [localTimeSeconds, setLocalTimeSeconds] = useState(timeSeconds);

  const handleSecondsInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = parseInt(e.currentTarget.value.replace(/[^0-9.]/g, ''));
    setLocalTimeSeconds(time);

    const clockBaseTime = (time || 0) * 1000 + (timeMinutes || 0) * 60000;
    if (clockBaseTime < 0) return;

    handleChangeClock({
      ...clock,
      clockBaseTime,
    });
  };

  const handleMinutesInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = parseInt(e.currentTarget.value.replace(/[^0-9.]/g, ''));
    setLocalTimeMinutes(time);

    const clockBaseTime = (time || 0) * 60000 + (timeSeconds || 0) * 1000;
    if (clockBaseTime < 0) return;

    handleChangeClock({
      ...clock,
      clockBaseTime,
    });
  };

  return (
    <div className="grid grid-cols-3 gap-2">
      <FormGroup label="[min] Shot clock" htmlFor="shotClockMin">
        <Input
          type="number"
          id="shotClockMin"
          value={localTimeMinutes}
          onChange={handleMinutesInputChange}
        />
      </FormGroup>
      <FormGroup label="[sec] Shot clock" htmlFor="shotClockSec">
        <Input
          type="number"
          id="shotClockSec"
          maxLength={2}
          value={localTimeSeconds}
          onChange={handleSecondsInputChange}
        />
      </FormGroup>
      <FormGroup label="Clock direction" htmlFor="shotClockDir">
        <Select
          id="shotClockDir"
          value={clockCountDirection}
          options={
            [
              { value: 'down', label: 'Down' },
              { value: 'up', label: 'Up' },
            ] satisfies { value: ClockCountDirection; label: string }[]
          }
          onChange={(e) =>
            handleChangeClock({
              ...clock,
              clockCountDirection: e.currentTarget.value as ClockCountDirection,
            })
          }
        />
      </FormGroup>
    </div>
  );
};

const ShootoutClock = ({ clock, handleChangeClock }: Props) => {
  const { clockBaseTime, clockCountDirection } = clock;
  const timeMinutes = Math.floor(clockBaseTime / 60000);
  const timeSeconds = Math.floor((clockBaseTime % 60000) / 1000);

  const [localTimeMinutes, setLocalTimeMinutes] = useState(timeMinutes);
  const [localTimeSeconds, setLocalTimeSeconds] = useState(timeSeconds);

  const handleSecondsInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = parseInt(e.currentTarget.value.replace(/[^0-9.]/g, ''));
    setLocalTimeSeconds(time);

    const clockBaseTime = (time || 0) * 1000 + (timeMinutes || 0) * 60000;
    if (clockBaseTime < 0) return;

    handleChangeClock({
      ...clock,
      clockBaseTime,
    });
  };

  const handleMinutesInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = parseInt(e.currentTarget.value.replace(/[^0-9.]/g, ''));
    setLocalTimeMinutes(time);

    const clockBaseTime = (time || 0) * 60000 + (timeSeconds || 0) * 1000;
    if (clockBaseTime < 0) return;

    handleChangeClock({
      ...clock,
      clockBaseTime,
    });
  };

  return (
    <div className="grid grid-cols-3 gap-2">
      <FormGroup label="[min] Shootout clock" htmlFor="shootoutClockMin">
        <Input
          type="number"
          id="shootoutClockMin"
          value={localTimeMinutes}
          onChange={handleMinutesInputChange}
        />
      </FormGroup>
      <FormGroup label="[sec] Shootout clock" htmlFor="shootoutClockSec">
        <Input
          type="number"
          id="shootoutClockSec"
          maxLength={2}
          value={localTimeSeconds}
          onChange={handleSecondsInputChange}
        />
      </FormGroup>
      <FormGroup label="Clock direction" htmlFor="shootoutClockDir">
        <Select
          id="shootoutClockDir"
          value={clockCountDirection}
          options={
            [
              { value: 'down', label: 'Down' },
              { value: 'up', label: 'Up' },
            ] satisfies { value: ClockCountDirection; label: string }[]
          }
          onChange={(e) =>
            handleChangeClock({
              ...clock,
              clockCountDirection: e.currentTarget.value as ClockCountDirection,
            })
          }
        />
      </FormGroup>
    </div>
  );
};

const Clock = ({ clock, handleChangeClock }: Props) => {
  switch (clock.type) {
    case 'main':
      return <MainClock clock={clock} handleChangeClock={handleChangeClock} />;
    case 'shotclock':
      return <ShotClock clock={clock} handleChangeClock={handleChangeClock} />;
    case 'shootoutclock':
      return <ShootoutClock clock={clock} handleChangeClock={handleChangeClock} />;
    default:
      return null;
  }
};

export default Clock;
