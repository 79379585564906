import { PayloadAction } from '@reduxjs/toolkit';
import { ToastContent, ToastContentProps, toast } from '@southfields-digital/mpxlive-components';
import { Id, ToastOptions } from 'react-toastify';
import { CallEffect, PutEffect, call, put, takeLatest } from 'redux-saga/effects';

import { NOTIFY, SET_TOAST_ID, UPDATE_NOTIFY } from 'src/redux/reducers/notification';

const notificationTitle = {
  error: 'Error',
  info: 'Info',
  success: 'Success',
  warning: 'Warning',
};

const getToast = (
  { title, variant, ...props }: ToastContentProps,
  toastOptions?: ToastOptions
): Id => {
  switch (variant) {
    case 'error':
    case 'info':
    case 'success':
    case 'warning':
      const toastTitle = notificationTitle[variant];

      return toast(
        <ToastContent title={title ?? toastTitle} variant={variant} {...props} />,
        toastOptions
      );
    default:
      return toast(title, toastOptions);
  }
};

const updateToast = (
  id: string,
  { title, variant, ...props }: ToastContentProps,
  toastOptions?: ToastOptions
): void => {
  switch (variant) {
    case 'error':
    case 'info':
    case 'success':
    case 'warning':
      const toastTitle = notificationTitle[variant];

      toast.update(id, {
        render: <ToastContent title={title ?? toastTitle} variant={variant} {...props} />,
        ...(toastOptions || {}),
      });
      break;
    default:
      toast.update(id, {
        ...(title ? { render: title } : {}),
        ...toastOptions,
      });
      break;
  }
};

export function* notify(
  action: PayloadAction<ToastContentProps> & {
    getToastId: (id: Id) => void;
    setToastId: boolean;
    toastOptions?: ToastOptions;
  }
): Generator<CallEffect | PutEffect, undefined, Id> {
  try {
    const toastId: Id = yield call(getToast, action.payload, action?.toastOptions);

    // If you want set the toastId to the store
    if (action.setToastId) {
      yield put({ type: SET_TOAST_ID, payload: { toastId } });
    }

    // If you want to get the toastId so you can do stuff with it
    if ('function' === typeof action.getToastId) {
      action.getToastId(toastId);
    }
  } catch (error) {
    console.error(error);
    throw new Error('Could not create toast');
  }
}

function* update(
  action: PayloadAction<ToastContentProps> & { toastId: string; toastOptions?: ToastOptions }
): Generator<CallEffect, void, void> {
  try {
    yield call(updateToast, action.toastId, action.payload, action?.toastOptions);
  } catch (error) {
    console.error(error);
    throw new Error('Could not update toast');
  }
}

export default function* root() {
  yield takeLatest(NOTIFY, notify);
  yield takeLatest(UPDATE_NOTIFY, update);
}
