import {
  Button,
  Icon,
  IconNames,
  Input,
  PlayButton,
  Text,
} from '@southfields-digital/mpxlive-components';
import classnames from 'classnames';
import { ChangeEvent } from 'react';

import ActionButtons from 'src/components/ActionButtons';
import PlaylistDialog from 'src/components/PlaylistDialog';

import styles from './Playlist.module.scss';

export interface IPlaylistProps {
  id: string;
  name: string;
  active: boolean;
  isOpen: boolean;
  numberGraphics?: number;
  autoplay: AutoPlay;
  icon?: IconNames;
  onUpdatePlaylist: (autoplay: AutoPlay) => void;
  onClickAutoPlay: () => void;
  onClick: () => void;
  onEdit: () => void;
  onCopy: () => void;
  onDelete: () => void;
  onClose: () => void;
  onSubmit: (name: string) => void;
}

export default function Playlist({
  name,
  numberGraphics = 0,
  active = false,
  isOpen,
  autoplay,
  icon = 'sort',
  onClick,
  onEdit,
  onCopy,
  onDelete,
  onClose,
  onSubmit,
  onClickAutoPlay,
  onUpdatePlaylist,
}: IPlaylistProps) {
  const isActive = autoplay.isEnabled && active;
  return (
    <div className={classnames(styles.PlaylistContainer)}>
      <div className={classnames('flex flex-row', { [styles.Active]: active })}>
        <div className={classnames('p-3.5 text-[#5B5D70]')}>
          <Icon icon={icon} weight="bold" />
        </div>
        <div className={classnames(styles.Playlist)} onClick={onClick}>
          <div onClick={onClick} className={styles.Content}>
            <Text as="p" lineClamp="clamp-1">
              {name}
            </Text>
          </div>

          <div className={styles.Options}>
            <ActionButtons
              isActive={active}
              text={numberGraphics === 1 ? `${numberGraphics} item` : `${numberGraphics} items`}
              showAuto={autoplay.isEnabled}
              autoEnabled={autoplay.isRunning}
              onClickAutoPlay={onClickAutoPlay}
            >
              <Button
                iconProps={{ icon: 'arrow-clockwise' }}
                variant={autoplay.isEnabled ? 'primary' : 'secondary'}
                onClick={() => onUpdatePlaylist({ ...autoplay, isEnabled: !autoplay.isEnabled })}
              />
              <Button iconProps={{ icon: 'pencil' }} variant="secondary" onClick={onEdit} />
              <Button iconProps={{ icon: 'copy' }} variant="secondary" onClick={onCopy} />
              <Button iconProps={{ icon: 'trash' }} variant="danger" onClick={onDelete} />
            </ActionButtons>
          </div>
        </div>
      </div>

      <div className={classnames(styles.LoopContainer, { [styles.active]: isActive })}>
        <PlayButton
          variant="live"
          className={styles.PlayButton}
          onClick={onClickAutoPlay}
          isRunning={autoplay.isRunning}
          hasIcon
        />

        <div className={styles.LoopSetting}>
          <Text as="span" className={styles.LoopLabel}>
            Interval in seconds:
          </Text>

          <Input
            type="number"
            className={styles.LoopInput}
            value={autoplay.interval / 1000}
            disabled={autoplay.isRunning}
            min={1}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onUpdatePlaylist({ ...autoplay, interval: Number(e.currentTarget.value) * 1000 })
            }
          />
        </div>
      </div>

      <PlaylistDialog
        initialName={name}
        dialogTitle="Edit Playlist"
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </div>
  );
}
