import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CONTROL, ControlStateType } from './types';

const controlInitialState: ControlStateType = {
  sandbox: {
    submitting: false,
    error: null,
  },
  preview: {
    submitting: false,
    error: null,
  },
  live: {
    submitting: false,
    error: null,
  },
};

export const controlSlice = createSlice({
  name: CONTROL,
  initialState: controlInitialState,
  reducers: {
    // SandBox
    requestShowGraphic: (control: ControlStateType) => {
      control.sandbox.submitting = true;
    },
    receiveShowGraphic: (control: ControlStateType) => {
      control.sandbox.submitting = false;
    },
    failedShowGraphic: (control: ControlStateType, { payload: error }: PayloadAction<string>) => {
      control.sandbox.submitting = false;
      control.sandbox.error = error;
    },
    // Live
    requestAnimeInLiveGraphic: (control: ControlStateType) => {
      control.live.submitting = true;
    },
    receiveAnimateInLiveGraphic: (control: ControlStateType) => {
      control.live.submitting = false;
    },
    failedAnimateInLiveGraphic: (
      control: ControlStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      control.live.submitting = false;
      control.live.error = error;
    },

    requestAnimateOutLiveGraphic: (control: ControlStateType) => {
      control.live.submitting = true;
    },
    receiveAnimateOutLiveGraphic: (control: ControlStateType) => {
      control.live.submitting = false;
    },
    failedAnimateOutLiveGraphic: (
      control: ControlStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      control.live.submitting = false;
      control.live.error = error;
    },

    requestUpdateLiveGraphic: (control: ControlStateType) => {
      control.live.submitting = true;
    },
    receiveUpdateLiveGraphic: (control: ControlStateType) => {
      control.live.submitting = false;
    },
    failedUpdateLiveGraphic: (
      control: ControlStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      control.live.submitting = false;
      control.live.error = error;
    },

    // Preview
    requestAnimateInPreviewGraphic: (control: ControlStateType) => {
      control.preview.submitting = true;
    },
    receiveAnimateInPreviewGraphic: (control: ControlStateType) => {
      control.preview.submitting = false;
    },
    failedAnimateInPreviewGraphic: (
      control: ControlStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      control.preview.submitting = false;
      control.preview.error = error;
    },

    requestAnimateOutPreviewGraphic: (control: ControlStateType) => {
      control.preview.submitting = true;
    },
    receiveAnimateOutPreviewGraphic: (control: ControlStateType) => {
      control.preview.submitting = false;
    },
    failedAnimateOutPreviewGraphic: (
      control: ControlStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      control.preview.submitting = false;
      control.preview.error = error;
    },

    requestUpdatePreviewGraphic: (control: ControlStateType) => {
      control.preview.submitting = true;
    },
    receiveUpdatePreviewGraphic: (control: ControlStateType) => {
      control.preview.submitting = false;
    },
    failedUpdatePreviewGraphic: (
      control: ControlStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      control.preview.submitting = false;
      control.preview.error = error;
    },

    // Hide all
    requestHideAllGraphics: () => {},
    receiveHideAllGraphics: () => {},
    failedHideAllGraphics: () => {},
  },
});

const actions = controlSlice.actions;
const controlReducer = controlSlice.reducer;

export { actions, controlReducer };
