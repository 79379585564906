import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useEffect, useMemo, useState, useCallback } from 'react';

import { AddButton } from './buttons';
import { Id, Items, RepeaterSortProps } from './types';
import VerticalRepeaterRows from './VerticalRepeaterRows';

type VerticalRepeaterBaseProps = {
  handleChangeRepeater: (id: Id, rowIndex: number, rows: Items[]) => void;
  handleChangeValue: (id: Id, rowIndex: number, value: string) => void;
  handleClickAdd: () => void;
  handleClickRemove: (index: number) => void;
  buttonClassNames?: string;
  excludedIds?: Id[];
  isDisabled?: boolean;
  handleSelectMedia?: (
    properties: ImageProperties,
    handleSelectValue?: (value: string) => void
  ) => void;
};

type VerticalRepeaterProps = {
  items: Items[];
  handleChangeSort: (newItems: Items[]) => void;
} & VerticalRepeaterBaseProps;

const VerticalRepeaterHeader = ({
  items,
  buttonClassNames,
  excludedIds,
  isDisabled,
}: VerticalRepeaterProps) => {
  const headerCells = useMemo(() => {
    if (!items.length) return null;

    return Object.entries(items[0])
      .filter(([, { id }]) => !excludedIds?.includes(id))
      .map(([, { id, name }]) => <th key={id}>{name}</th>);
  }, [items, excludedIds]);

  if (!items.length) {
    return null;
  }

  return (
    <tr>
      <th className="w-6" />
      {headerCells}
      {!isDisabled && <th className={buttonClassNames} />}
    </tr>
  );
};

const VerticalRepeaterItem = ({
  items,
  buttonClassNames,
  excludedIds,
  isDisabled,
  handleChangeRepeater,
  handleChangeValue,
  handleClickAdd,
  handleClickRemove,
  handleSelectMedia,
}: VerticalRepeaterProps) => (
  <>
    <VerticalRepeaterRows
      items={items}
      excludedIds={excludedIds}
      isDisabled={isDisabled}
      buttonClassNames={buttonClassNames}
      handleClickRemove={handleClickRemove}
      handleChangeRepeater={handleChangeRepeater}
      handleChangeValue={handleChangeValue}
      handleSelectMedia={handleSelectMedia}
    />
    <tr>
      {Object.entries(items[0])
        .filter(([, { id }]) => !excludedIds?.includes(id))
        .map(([key]) => (
          <td key={key} />
        ))}

      <td />

      {!isDisabled && (
        <td className={buttonClassNames}>
          <AddButton onClick={handleClickAdd} />
        </td>
      )}
    </tr>
  </>
);

const VerticalRepeater = ({ items, ...props }: VerticalRepeaterProps) => {
  const [sortedItems, setSortedItems] = useState<RepeaterSortProps[]>([]);

  useEffect(() => {
    setSortedItems(
      items.map<RepeaterSortProps>((item, index) => ({
        id: `VerticalRepeater-${index}`,
        item,
      }))
    );
  }, [items]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!sortedItems || !over) {
        return;
      }

      if (active.id !== over.id) {
        const oldIndex = sortedItems.findIndex(({ id }) => id === active.id);
        const newIndex = sortedItems.findIndex(({ id }) => id === over.id);

        const localSortItems = arrayMove(sortedItems, oldIndex, newIndex);
        const apiSortItems = localSortItems.map<Items>(({ item }) => ({ ...item }));

        setSortedItems(localSortItems);
        props.handleChangeSort(apiSortItems);
      }
    },
    [sortedItems]
  );

  const sortedIndices = useMemo(() => sortedItems.map(({ id }) => id), [sortedItems]);

  if (!sortedIndices.length) {
    return null;
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={onDragEnd}
    >
      <SortableContext items={sortedIndices} strategy={verticalListSortingStrategy}>
        <table>
          <tbody>
            <VerticalRepeaterHeader {...props} items={items} />
            <VerticalRepeaterItem {...props} items={items} />
          </tbody>
        </table>
      </SortableContext>
    </DndContext>
  );
};

export default VerticalRepeater;
