import { Input } from '@southfields-digital/mpxlive-components';

import Select from 'src/components/Select';

import type { Id, Cell } from '.';

type CellProps = {
  item: Cell;
  rowIndex: number;
  handleChangeValue: (id: Id, rowIndex: number, value: string) => void;
  handleSelectMedia?: (
    properties: ImageProperties,
    handleSelectValue?: (value: string) => void
  ) => void;
};

const Cell = ({ item, rowIndex, handleChangeValue, handleSelectMedia }: CellProps) => {
  const itemId = item.id;
  const handleOnChange = (e: React.SyntheticEvent<HTMLInputElement | HTMLSelectElement>) => {
    handleChangeValue(itemId, rowIndex, e.currentTarget.value);
  };
  const handleClickMedia = () => {
    if (typeof item.id !== 'string') return;

    handleSelectMedia?.(
      { id: item.id, name: item.name, svgPath: item.svgPath || '', type: 'image' },
      (value) => handleChangeValue(itemId, rowIndex, value)
    );
  };

  switch (item.type) {
    case 'text':
      return (
        <Input
          type="text"
          value={item.value}
          onChange={handleOnChange}
          placeholder={item.name}
          maxLength={item.maxLength}
        />
      );
    case 'select':
      return (
        <Select
          value={item.value}
          options={item.options}
          placeholder={item.name}
          onChange={handleOnChange}
        />
      );
    case 'image':
      return (
        <Input
          type="selectableInput"
          buttonIcon="media"
          value={item.value}
          onChange={handleOnChange}
          onClick={handleClickMedia}
        />
      );
    default:
      return null;
  }
};

export default Cell;
