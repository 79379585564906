import { Card, Icon, Text } from '@southfields-digital/mpxlive-components';
import classNames from 'classnames';

import LivePulse from 'src/components/LivePulse';
import { toDatetimeLocal } from 'src/utils';

import styles from './RundownCard.module.scss';
import { RundownCardType, type RundownCardProps } from './RundownCard.types';

const RundownCard = ({
  className,
  date,
  handleClickDelete,
  handleClickCard,
  rundown,
  type = RundownCardType.FULL,
  wrapperClassName,
}: RundownCardProps) => {
  const actionItems =
    type === RundownCardType.FULL
      ? [
          {
            to: `/rundowns/edit/${rundown.id}`,
            onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
            },
            value: 'edit',
          },
          {
            as: 'button',
            onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              e.preventDefault();

              if (typeof handleClickDelete === 'function') {
                handleClickDelete();
              }
            },
            value: 'delete',
          },
        ]
      : undefined;

  return (
    <div
      onClick={handleClickCard}
      className={classNames(styles.RundownCardWrapper, wrapperClassName)}
    >
      <Card
        headerContent={
          <div className={styles.RundownCardName}>
            {type === RundownCardType.COMPACT && (
              <Icon
                icon={rundown.isPersistent ? 'persistent' : 'calendar'}
                size={16}
                weight="regular"
              />
            )}
            {type === RundownCardType.FULL ? (
              <Text as="h3" className={styles.Title}>
                {rundown.name}
              </Text>
            ) : (
              rundown.name
            )}
          </div>
        }
        className={classNames(styles.Card, className)}
        popoverPosition={!date ? undefined : date.getDay() === 0 ? 'right' : 'left'}
        actionItems={actionItems}
      >
        {type === RundownCardType.COMPACT && rundown.isLive && (
          <LivePulse className={styles.RundownCardCompactLivePulse} />
        )}

        <div className={styles.Content}>
          {rundown.isLocked && (
            <div className={styles.StatusRow}>
              <Text as="span">Rundown is in use</Text>
              <Icon icon="x-circle" />
            </div>
          )}

          <div className={styles.StatusRow}>
            {!rundown.isPersistent && (
              <Text as="span">{toDatetimeLocal(rundown.start).time.slice(0, 5)}</Text>
            )}
            {type === RundownCardType.FULL && rundown.isLive && <LivePulse />}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default RundownCard;
