import { FormGroup, Input } from '@southfields-digital/mpxlive-components';

import Select, { Option } from 'src/components/Select';

import styles from './RundownTemplateForm.module.scss';

interface IRundownTemplateFormProps {
  state: Partial<RundownTemplate>;
  graphicsPackages: Option[];
  stateUpdated: (state: Partial<RundownTemplate>) => void;
}

export default function RundownTemplateForm({
  state,
  graphicsPackages,
  stateUpdated,
}: IRundownTemplateFormProps) {
  const updateState = (newState: Partial<RundownTemplate>) => {
    if (!state) return;

    stateUpdated({ ...state, ...newState });
  };

  return (
    <div className={styles.RundownForm}>
      <Input
        label="Template name"
        value={state.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateState({ name: e.currentTarget.value })
        }
      />

      <FormGroup label="Select graphics package" htmlFor="graphicsPackage">
        <Select
          id="graphicsPackage"
          value={state.graphicPackageId}
          options={[{ label: ' - ', value: '' }, ...graphicsPackages]}
          onChange={(e) => {
            updateState({
              graphicPackageId: e.currentTarget.value,
              canvases: [],
            });
          }}
          placeholder="Pick a graphics package"
        />
      </FormGroup>
    </div>
  );
}
