import { format } from 'date-fns';
import { useState, useEffect } from 'react';

function LiveClock() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="text-gray-50 font-mono text-xl font-bold mx-2 text-center">
      <p>{format(currentTime, 'HH:mm:ss')}</p>
    </div>
  );
}

export default LiveClock;
