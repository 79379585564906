import { forwardRef } from 'react';

import styles from './Select.module.scss';
import { OptionProps, SelectProps } from './types';

const Option = ({ value, label, ...props }: OptionProps) => (
  <option className={styles.Option} value={value} {...props}>
    {label ?? value}
  </option>
);

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ value, options, onChange, className, ...selectProps }, ref) => (
    <select ref={ref} className={styles.Select} value={value} onChange={onChange} {...selectProps}>
      {options?.map((option) => <Option key={option.value} {...option} />)}
    </select>
  )
);

export default Select;
