import Tippy, { TippyProps } from '@tippyjs/react';
import classNames from 'classnames';
import React, { useRef } from 'react';

import styles from './Tooltip.module.scss';

type TooltipProps = {
  className?: string;
  children: React.ReactElement;
  overlay: React.ReactNode;
} & TippyProps;

const Tooltip = ({ className, children, overlay, ...props }: TooltipProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  return (
    <Tippy
      content={overlay}
      delay={[0, 0]}
      className={classNames(styles.Overlay, className)}
      ref={contentRef}
      {...props}
    >
      <div ref={contentRef}>{children}</div>
    </Tippy>
  );
};

export { Tooltip };
export type { TooltipProps };
