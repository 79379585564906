import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PLAYLIST, PlaylistStateType, PlaylistResponseType, PlaylistsResponseType } from './types';

const playlistInitialState: PlaylistStateType = {
  create: {
    data: null,
    submitting: false,
    error: null,
  },
  update: {
    data: null,
    submitting: false,
    error: null,
  },
  delete: {
    data: null,
    deleting: false,
    error: null,
  },
  get: {
    data: null,
    loading: true,
    error: null,
  },
  getAll: {
    data: null,
    selectedId: null,
    loading: true,
    error: null,
  },
  duplicate: {
    data: null,
    submitting: false,
    error: null,
  },
};

export const playlistSlice = createSlice({
  name: PLAYLIST,
  initialState: playlistInitialState,
  // Apply state changes
  reducers: {
    // CREATE
    requestCreatePlaylist: (playlist: PlaylistStateType) => {
      playlist.create.submitting = true;
    },
    receiveCreatePlaylist: (playlist: PlaylistStateType, { payload }: PayloadAction<Playlist>) => {
      playlist.create.submitting = false;
      playlist.create.error = null;

      if (!playlist.getAll.data) return;
      playlist.getAll.data.push(payload);
    },
    failedCreatePlaylist: (
      playlist: PlaylistStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      playlist.create.submitting = false;
      playlist.create.error = error;
    },
    // DUPLICATE
    requestDuplicatePlaylist: (playlist: PlaylistStateType) => {
      playlist.duplicate.submitting = true;
    },
    receiveDuplicatePlaylist: (
      playlist: PlaylistStateType,
      { payload }: PayloadAction<Playlist>
    ) => {
      playlist.duplicate.submitting = false;
      playlist.duplicate.error = null;

      if (!playlist.getAll.data) return;
      playlist.getAll.data.push(payload);
    },
    failedDuplicatePlaylist: (
      playlist: PlaylistStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      playlist.duplicate.submitting = false;
      playlist.duplicate.error = error;
    },
    // DELETE
    requestDeletePlaylist: (playlist: PlaylistStateType) => {
      playlist.delete.deleting = true;
    },
    receiveDeletePlaylist: (
      playlist: PlaylistStateType,
      { payload }: PayloadAction<{ playlistId: string }>
    ) => {
      playlist.delete.deleting = false;
      playlist.delete.error = null;

      if (!playlist.getAll.data) return;
      playlist.getAll.data = playlist.getAll.data.filter((p) => p.id !== payload.playlistId);
    },
    failedDeletePlaylist: (
      playlist: PlaylistStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      playlist.delete.deleting = false;
      playlist.delete.error = error;
    },
    // UPDATE
    requestUpdatePlaylist: (playlist: PlaylistStateType) => {
      playlist.update.submitting = true;
    },
    receiveUpdatePlaylist: (playlist: PlaylistStateType, { payload }: PayloadAction<Playlist>) => {
      playlist.update.submitting = false;
      playlist.update.error = null;
      if (!payload || !playlist.getAll.data) return;

      playlist.getAll.data = playlist.getAll.data.map((p) => {
        if (p.id === payload.id) return payload;
        return p;
      });
    },
    failedUpdatePlaylist: (
      playlist: PlaylistStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      playlist.update.submitting = false;
      playlist.update.error = error;
    },
    // GET
    requestGetPlaylistById: (playlist: PlaylistStateType) => {
      playlist.get.loading = true;
    },
    receiveGetPlaylistById: (
      playlist: PlaylistStateType,
      { payload }: PayloadAction<PlaylistResponseType>
    ) => {
      playlist.get.loading = false;
      playlist.get.error = null;
      playlist.get.data = payload;
    },
    failedGetPlaylistById: (
      playlist: PlaylistStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      playlist.get.loading = false;
      playlist.get.error = error;
    },
    // GET ALL
    requestGetAllPlaylistsByRundownId: (playlist: PlaylistStateType) => {
      playlist.getAll.loading = true;
    },
    receiveGetAllPlaylistsByRundownId: (
      playlist: PlaylistStateType,
      { payload }: PayloadAction<PlaylistsResponseType>
    ) => {
      playlist.getAll.loading = false;
      playlist.getAll.error = null;
      playlist.getAll.data = payload;
    },
    failedGetAllPlaylistsByRundownId: (
      playlist: PlaylistStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      playlist.getAll.loading = false;
      playlist.getAll.error = error;
    },
    // SELECTED
    requestSetSelectedPlaylist: () => {},
    receiveSetSelectedPlaylist: (
      playlist: PlaylistStateType,
      { payload: playlistId }: PayloadAction<string | null>
    ) => {
      playlist.getAll.selectedId = playlistId;
    },
    failedSetSelectedPlaylist: () => {},
    requestReorderPlaylists: (playlist: PlaylistStateType) => {
      playlist.getAll.error = null;
    },
    receiveReorderPlaylists: (
      playlist: PlaylistStateType,
      { payload: playlistIds }: PayloadAction<string[]>
    ) => {
      if (!playlist.getAll.data) return;
      playlist.getAll.data.sort((a, b) => {
        const indexA = playlistIds.indexOf(a.id);
        const indexB = playlistIds.indexOf(b.id);
        if (indexA < indexB) {
          return -1;
        }
        if (indexA > indexB) {
          return 1;
        }
        return 0;
      });
    },
    failedReorderPlaylists: (
      playlist: PlaylistStateType,
      { payload: error }: PayloadAction<string>
    ) => {
      playlist.getAll.error = error;
    },
  },
});

const actions = playlistSlice.actions;
const playlistReducer = playlistSlice.reducer;

export { actions, playlistReducer };
