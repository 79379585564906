import { call, put, takeLatest } from 'redux-saga/effects';
import type { CallEffect, PutEffect } from 'redux-saga/effects';

import api from 'src/services/graphicsApi';

import {
  actions,
  REQUEST_GET_DATA_PROVIDER_TRANSLATIONS,
  DataProviderTranslationResponseType,
} from '../reducers/dataProviderTranslation';

type DataProviderTranslationApiResponse = void | {
  data: DataProviderTranslationResponseType[];
  status: number;
};

function* getDataProviderTranslations(): Generator<
  PutEffect | CallEffect<DataProviderTranslationApiResponse>,
  void,
  DataProviderTranslationApiResponse
> {
  try {
    const response = yield call(api.get, `/dataProviderTranslations`);
    const apiResponse = response as DataProviderTranslationApiResponse;

    if (apiResponse?.data) {
      yield put(actions.receiveGetDataProviderTranslations(apiResponse.data));
    }
  } catch (error) {
    yield put(
      actions.failedGetDataProviderTranslations((error as Error)?.message as string) ||
        'Something went wrong'
    );
  }
}

export default function* root() {
  yield takeLatest(REQUEST_GET_DATA_PROVIDER_TRANSLATIONS, getDataProviderTranslations);
}
