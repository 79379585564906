import { Text } from '@southfields-digital/mpxlive-components';
import classNames from 'classnames';

import styles from '../MatchControl.module.scss';
import { MatchControlVariant } from '../types';

import MatchControlButton from './MatchControlButton';
import MatchControlIcon from './MatchControlIcon';

interface IProps {
  variant?: MatchControlVariant;
  goals: number;
  disabled?: boolean;
  inverted?: boolean;
  handleChangeScore: (goals: number) => void;
  children?: React.ReactNode;
}

const Score = ({
  goals,
  inverted = false,
  disabled = false,
  handleChangeScore,
  children,
  variant = 'default',
}: IProps) => {
  const handleAddScore = () => {
    handleChangeScore(goals + 1);
  };
  const handleSubtractScore = () => {
    if (goals > 0) {
      handleChangeScore(goals - 1);
    }
  };

  return (
    <div className={styles.ScoreWrapper}>
      <div
        className={classNames(styles.FlagWrapper, {
          [styles.HomeFlag]: !inverted,
          [styles.GuestsFlag]: inverted,
        })}
      >
        {children}
        <Text className={styles.ScoreFlagText} as="h1" color="light">
          {goals}
        </Text>
      </div>

      <div className={styles.ActionsWrapper}>
        <MatchControlButton
          variant={variant === 'default' ? 'icon' : 'default'}
          onClick={() => handleSubtractScore()}
          disabled={disabled}
        >
          {variant === 'default' ? (
            <MatchControlIcon iconProps={{ icon: 'minus' }} />
          ) : (
            'REMOVE GOAL'
          )}
        </MatchControlButton>

        <MatchControlButton
          variant={variant === 'default' ? 'icon' : 'default'}
          onClick={() => handleAddScore()}
          disabled={disabled}
        >
          {variant === 'default' ? <MatchControlIcon iconProps={{ icon: 'plus' }} /> : 'ADD GOAL'}
        </MatchControlButton>
      </div>
    </div>
  );
};

export default Score;
