import { Button, Dialog, Form, Input, Text } from '@southfields-digital/mpxlive-components';
import { useState } from 'react';

import styles from './PlaylistDialog.module.scss';

interface IPlaylistDialogProps {
  dialogTitle: string;
  initialName: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (initialName: string, playlistId?: string) => void;
}

export default function PlaylistDialog({
  dialogTitle,
  isOpen,
  initialName,
  onClose,
  onSubmit,
}: IPlaylistDialogProps) {
  const [playlistName, setPlaylistName] = useState(initialName);

  const handleSubmitForm = () => {
    onSubmit(playlistName);
    setPlaylistName('');
  };

  return (
    <Dialog backdrop open={isOpen} onClose={onClose}>
      <Dialog.Panel>
        <Dialog.Title>{dialogTitle}</Dialog.Title>
        <Dialog.Description>
          <Text>Enter name for the playlist</Text>
        </Dialog.Description>
        <Form onSubmit={handleSubmitForm}>
          <Input
            type="text"
            value={playlistName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPlaylistName(e.currentTarget.value)
            }
            className={styles.Input}
          />
          <Button variant="primary" className={styles.Button} type="submit">
            Submit
          </Button>
        </Form>
      </Dialog.Panel>
    </Dialog>
  );
}
