import { Button } from '@southfields-digital/mpxlive-components';

import styles from '../Repeater.module.scss';

type AddButtonProps = {
  onClick: () => void;
};

const AddButton = ({ onClick }: AddButtonProps) => (
  <Button
    buttonSize="small"
    onClick={onClick}
    variant={'iconButton'}
    iconProps={{ icon: 'plus-circle' }}
    className={styles.IconButtonAdd}
  />
);

export default AddButton;
